(function () {
  var ItemService,
    getItemTotal,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  getItemTotal = require("Quote/Calculator/Item");

  module.exports = ItemService = (function () {
    function ItemService(undoNotifier, sectionService, block, telemetry) {
      this.undoNotifier = undoNotifier;
      this.sectionService = sectionService;
      this.telemetry = telemetry;
      this.ensureBounds = bind(this.ensureBounds, this);
      this.moveToPreviousSection = bind(this.moveToPreviousSection, this);
      this.moveToNextSection = bind(this.moveToNextSection, this);
      this.moveItemDown = bind(this.moveItemDown, this);
      this.moveItemUp = bind(this.moveItemUp, this);
      this.getParentSection = bind(this.getParentSection, this);
      this.deleteItem = bind(this.deleteItem, this);
      this.quote = block;
    }

    ItemService.prototype.getCost = getItemTotal;

    /*
      Find the index of a given item.
      @param section [Object] the parent section of the item.
      @param item [Object] the item whose index you're looking to find.
     */

    ItemService.prototype.findItemIndex = function (section, item) {
      var index;
      index = _.findIndex(section.items, {
        $id: item.$id,
      });
      if (index === -1) {
        throw new Error("Could not find item index");
      }
      return index;
    };

    /*
      Removes an item from section
      @param section [Object] The section to delete the item from.
      @param item [Object] The item to be deleted
     */

    ItemService.prototype.deleteItem = function (section, item) {
      var index;
      index = this.findItemIndex(section, item);
      section.items.splice(index, 1);
      this.telemetry.trackOnly("Quote Block Line Item Deleted", {});
      return this.undoNotifier.show(
        "Undo delete item",
        (function (_this) {
          return function () {
            return _this.sectionService.addSavedItem(section, item, index);
          };
        })(this),
      );
    };

    /*
      Find the section which contains a given a item.
      @param item [Object] the item whose "parent" section you want to find.
     */

    ItemService.prototype.getParentSection = function (item) {
      var foundItem, i, len, ref, section;
      ref = this.quote.sections;
      for (i = 0, len = ref.length; i < len; i++) {
        section = ref[i];
        foundItem = _.findIndex(section.items, {
          $id: item.$id,
        });
        if (foundItem !== -1) {
          return section;
        }
      }
      throw new Error("Could not find parent section for item.");
    };

    /*
      Move an item up in the section list - potentially moving the item to a previous section's item list.
      @param item [Object] the item you want to move.
     */

    ItemService.prototype.moveItemUp = function (item) {
      var isFirstItem, itemIndex, section;
      section = this.getParentSection(item);
      itemIndex = this.findItemIndex(section, item);
      isFirstItem = itemIndex === 0;
      if (!isFirstItem) {
        section.items.splice(itemIndex, 1);
        section.items.splice(itemIndex - 1, 0, item);
      } else {
        this.moveToPreviousSection(item, section);
      }
      return this.telemetry.trackOnly("Quote Block Line Item Moved");
    };

    /*
      Move an item down in the section list - potentially moving the item to the next section's item list.
      @param item [Object] the item you want to move.
     */

    ItemService.prototype.moveItemDown = function (item) {
      var isLastItem, itemIndex, section;
      section = this.getParentSection(item);
      itemIndex = this.findItemIndex(section, item);
      isLastItem = itemIndex + 1 === section.items.length;
      if (!isLastItem) {
        section.items.splice(itemIndex, 1);
        section.items.splice(itemIndex + 1, 0, item);
      } else {
        this.moveToNextSection(item);
      }
      return this.telemetry.trackOnly("Quote Block Line Item Moved");
    };

    /*
      Move an item to the next section.
      @param item [Object] the item you want to move.
     */

    ItemService.prototype.moveToNextSection = function (item) {
      var currentSectionIndex, itemIndex, nextSection, section;
      section = this.getParentSection(item);
      currentSectionIndex = this.sectionService.findSectionIndex(section);
      nextSection = this.quote.sections[currentSectionIndex + 1];
      if (nextSection == null) {
        return;
      }
      itemIndex = _.findIndex(section.items, item);
      section.items.splice(itemIndex, 1);
      if (nextSection.items == null) {
        nextSection.items = [];
      }
      return nextSection.items.splice(0, 0, item);
    };

    /*
      Move an item to the previous section.
      @param item [Object] the item you want to move.
     */

    ItemService.prototype.moveToPreviousSection = function (item) {
      var currentSectionIndex, itemIndex, prevSection, section;
      section = this.getParentSection(item);
      currentSectionIndex = this.sectionService.findSectionIndex(section);
      if (currentSectionIndex === 0) {
        return;
      }
      prevSection = this.quote.sections[currentSectionIndex - 1];
      itemIndex = _.findIndex(section.items, item);
      section.items.splice(itemIndex, 1);
      if (prevSection.items == null) {
        prevSection.items = [];
      }
      return prevSection.items.push(item);
    };

    ItemService.prototype.isInteractive = function (item) {
      return item.interactive != null && item.interactive.type !== "none";
    };

    /*
      When a user sets min / max values on their interactive items
      we want to ensure the values they've set for the item respect
      these boundaries. If needed, we adjust the item units to
      reflect the min / max values.
     */

    ItemService.prototype.ensureBounds = function (item) {
      if (this.isInteractive(item)) {
        if (item.interactive.settings.min != null && item.units < item.interactive.settings.min) {
          item.units = item.interactive.settings.min;
        }
        if (item.interactive.settings.max != null && item.units > item.interactive.settings.max) {
          return (item.units = item.interactive.settings.max);
        }
      }
    };

    return ItemService;
  })();
}.call(this));

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectGatewayButton = void 0;
var react_1 = __importStar(require("react"));
var inversify_react_1 = require("inversify-react");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var event_tracker_types_1 = require("@qwilr/event-tracker-types");
var PromptTypes_1 = require("Prompt/PromptTypes");
var PaymentsTelemetry_1 = require("Library/PaymentsDashboard/PaymentsTelemetry");
var PaymentsOnboarding_1 = require("Library/PaymentsDashboard/PaymentsOnboarding");
var ConnectGatewayButton = function (props) {
    var _a = __read((0, react_1.useState)(false), 2), isOnboardingModalOpen = _a[0], setIsOnboardingModalOpen = _a[1];
    var telemetry = (0, PaymentsTelemetry_1.PaymentsTelemetry)();
    var inlinePromptsHistoryStore = (0, inversify_react_1.useInjection)("rootDataStore").userDataStore.useHistoryDataStore.inlinePromptsHistoryStore;
    var onboardingModalHandler = function (open, origin) {
        setIsOnboardingModalOpen(open);
        if (open) {
            telemetry.onboardingModalOpened(origin);
        }
        else {
            inlinePromptsHistoryStore.dismissedPrompt(PromptTypes_1.PromptId.QwilrPayOnboarding);
            telemetry.onboardingModalClosed(origin);
        }
    };
    return (react_1.default.createElement(react_1.default.Fragment, null, props.kind === "continue" ? (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(PaymentsOnboarding_1.PaymentsOnboarding, { closeModal: function () { return onboardingModalHandler(false, event_tracker_types_1.EventOrigin.PaymentsDashboard); }, kind: "continue", gatewayId: props.gatewayId, showModal: isOnboardingModalOpen, telemetry: telemetry }),
        react_1.default.createElement(kaleidoscope_1.Button, { onClick: function () { return onboardingModalHandler(true, event_tracker_types_1.EventOrigin.PaymentsDashboard); }, size: props.size || kaleidoscope_1.ButtonSize.Medium, type: props.type || kaleidoscope_1.ButtonType.Primary }, "Complete onboarding"))) : (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(PaymentsOnboarding_1.PaymentsOnboarding, { closeModal: function () { return onboardingModalHandler(false, event_tracker_types_1.EventOrigin.PaymentsDashboard); }, kind: "connect", gatewayId: null, showModal: isOnboardingModalOpen, telemetry: telemetry }),
        react_1.default.createElement(kaleidoscope_1.Button, { onClick: function () { return onboardingModalHandler(true, event_tracker_types_1.EventOrigin.PaymentsDashboard); }, type: kaleidoscope_1.ButtonType.Primary }, "Connect payment gateway")))));
};
exports.ConnectGatewayButton = ConnectGatewayButton;

(function () {
  var Taxes, TaxesSetting;

  Taxes = require("Common/Utils/ImageImports").Taxes;

  module.exports = TaxesSetting = (function () {
    function TaxesSetting(
      createUi,
      make,
      block,
      accountQuoteSettings,
      telemetry,
      integrationService,
      quickBooksTaxMapChecker,
    ) {
      var i, len, ref, tax, taxEditor, taxes, taxesMap;
      taxes = accountQuoteSettings.taxes;
      taxEditor = make(require("Common/TaxEditor/TaxEditor").TaxEditor);
      taxesMap = {};
      if (block.taxes == null) {
        block.taxes = [];
      }
      ref = block.taxes;
      for (i = 0, len = ref.length; i < len; i++) {
        tax = ref[i];
        taxesMap[tax.name] = true;
      }
      this.ui = createUi(require("./Taxes.html"), {
        taxes: taxes,
        taxesMap: taxesMap,
        icon: Taxes,
        setTaxes: (function (_this) {
          return function (name) {
            var active, ref1;
            block.taxes = [];
            ref1 = _this.ui.taxesMap;
            for (name in ref1) {
              active = ref1[name];
              if (!active) {
                continue;
              }
              tax = _.find(_this.ui.taxes, {
                name: name,
              });
              if (tax == null) {
                continue;
              }
              block.taxes.push(tax);
            }
            if (_this.ui.taxesMap[name]) {
              telemetry.trackOnly("Quote Block Tax Applied", {});
            } else {
              telemetry.trackOnly("Quote Block Tax Removed", {});
            }
            if (block.taxes.length) {
              block.settings.displayConfig.taxes = true;
              block.settings.displayConfig.taxTotal = true;
            }
            if (integrationService.isEnabled("quickbooks")) {
              quickBooksTaxMapChecker.checkTaxMaps();
            }
            return block.taxes;
          };
        })(this),
        openTaxEditor: (function (_this) {
          return function () {
            return taxEditor.show();
          };
        })(this),
      });
    }

    return TaxesSetting;
  })();
}.call(this));

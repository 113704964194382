/*
  UI for the style selector list.
 */

(function () {
  var StyleSelectorController, factoryStylesList;

  factoryStylesList = require("@CommonFrontendBackend/StyleServiceV2/TCDCConverter/V0FactoryStyle.js");

  module.exports = StyleSelectorController = (function () {
    function StyleSelectorController(
      createUi,
      make,
      block,
      viewState,
      account,
      user,
      telemetry,
      utils,
      $log,
      getFullName,
      showDeleteModal,
    ) {
      var i, j, len, openStyleEditor, ref, refreshSwatchStyles, style;
      if (block.settings.style == null || block.settings.style === "") {
        block.settings.style = "qwilr-standard";
      }
      ref = account.styles;
      for (i = j = 0, len = ref.length; j < len; i = ++j) {
        style = ref[i];
        if (style.blockType === "Content") {
          account.styles[i].blockType = "Text";
        }
      }

      /*
        Refreshes the custom swatch colors.
       */
      refreshSwatchStyles = function () {
        var blockElem;
        blockElem = block.$ctrl.ui.blockElement;
        return $(blockElem)
          .parent()
          .find(".swatch.is-custom")
          .each(function () {
            var classes, color, elem, k, len1, tag, tagElem;
            elem = $(this);
            classes = [".h1", ".h2", ".p"];
            for (k = 0, len1 = classes.length; k < len1; k++) {
              tag = classes[k];
              tagElem = elem.find(tag).get(0);
              if (tagElem == null) {
                return;
              }
              color = $(tagElem).css("color");
              $(tagElem).css("background-color", color);
            }
          });
      };

      /*
        Opens the style editor for a given style.
        @param style [Object] the style to edit.
       */
      openStyleEditor = (function (_this) {
        return function (style) {
          var styleEditor;
          viewState.displayMode = true;
          styleEditor = make(require("./StyleEditor_old/StyleEditor"), {
            style: style,
            refreshSwatchStyles: refreshSwatchStyles,
          });
          block.settings.style = style.className;
          _this.ui.styleEditor = styleEditor.ui;
          return styleEditor.show();
        };
      })(this);
      this.ui = createUi(require("./StyleSelector.html"), {
        block: block,
        viewState: viewState,
        actions: [
          {
            label: "Delete",
            icon: "cross",
            onClick: (function (_this) {
              return function (style) {
                return _this.ui.deleteStyle(style);
              };
            })(this),
          },
          {
            label: "Edit",
            icon: "pencil2",
            onClick: (function (_this) {
              return function (style) {
                return _this.ui.editStyle(style);
              };
            })(this),
          },
        ],

        /*
          Retrieve a list of factory styles and custom styles for this block type.
          @returns [Array] A list of styles
         */
        styles: (function (_this) {
          return function () {
            var blockTypeStyles, factoryStyles, k, len1;
            factoryStyles = _.filter(factoryStylesList, {
              blockType: block.type,
            });
            blockTypeStyles = _.filter(account.styles, {
              blockType: block.type,
            });
            for (k = 0, len1 = blockTypeStyles.length; k < len1; k++) {
              style = blockTypeStyles[k];
              style.$isCustomStyle = true;
            }
            return factoryStyles.concat(blockTypeStyles);
          };
        })(this),

        /*
          Determines if the given style is currently selected for this block.
          @param style [Object] the style to test.
         */
        isActive: (function (_this) {
          return function (style) {
            return _this.ui.block.settings.style === style.className;
          };
        })(this),

        /*
          Duplicate a given style.
          @param style [Object] the original style you want to duplicate.
         */
        cloneStyle: (function (_this) {
          return function (style) {
            var newStyle;
            newStyle = angular.copy(style);
            newStyle.name = "Cloned From: " + newStyle.name;
            delete newStyle._id;
            account.styles.push(newStyle);
            return openStyleEditor(_.last(account.styles));
          };
        })(this),

        /*
          Select this style for the block.
          @param style [Object] the style to select.
         */
        selectStyle: (function (_this) {
          return function (style) {
            _this.ui.block.settings.style = style.className;
            return telemetry.trackOnly("Block Styles Applied", {});
          };
        })(this),

        /*
          Delete a given custom style.
          @param style [Object] the customs style to delete.
         */
        deleteStyle: (function (_this) {
          return function (style) {
            return showDeleteModal({
              title: "Delete Style?",
              explainer: "Definitely want to delete this style?",
            }).then(function (confirmed) {
              if (confirmed) {
                if (style._id != null) {
                  _.remove(account.styles, {
                    _id: style._id,
                  });
                } else if (style.id != null) {
                  _.remove(account.styles, {
                    id: style.id,
                  });
                } else {
                  $log.error("Got a custom style with no id or _id field");
                }
                return telemetry.trackOnly("Block Styles Deleted", {});
              }
            });
          };
        })(this),
        editStyle: openStyleEditor,

        /*
          Add a new custom style.
         */
        addNewStyle: (function (_this) {
          return function () {
            var styleModel, styleModels;
            styleModels = require("./BlockStyleModels");
            styleModel = angular.copy(styleModels[block.type]);
            if (styleModel == null) {
              styleModel = angular.copy(styleModels["Text"]);
            }
            account.styles.push({
              $newTemporaryStyle: true,
              id: utils.uniqueId(22),
              createdBy: user._id,
              author: getFullName(user),
              blockType: block.type || "Text",
              styleModel: styleModel,
              className: "style-" + utils.uniqueId(8),
              name: "Untitled Style",
            });
            openStyleEditor(_.last(account.styles));
            return telemetry.trackOnly("Block Styles Created", {});
          };
        })(this),

        /*
          Update the swatch styles each time the styles panel is in view.
         */
        $onElementRendered: function () {
          return refreshSwatchStyles();
        },
      });
    }

    return StyleSelectorController;
  })();
}.call(this));

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockActionsMenu = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var styles = __importStar(require("./BlockActionsMenu.css"));
var utils_1 = require("@qwilr/kaleidoscope/utils");
var inversify_react_1 = require("inversify-react");
var TelemetryEnums_1 = require("@CommonFrontend/Telemetry/TelemetryEnums");
var BlockActionsMenu = function (_a) {
    var block = _a.block;
    var savedBlockService = (0, inversify_react_1.useInjection)("savedBlockService");
    var telemetry = (0, inversify_react_1.useInjection)("telemetry");
    var navigator = (0, inversify_react_1.useInjection)("navigator");
    var _b = __read((0, react_1.useState)("menu"), 2), screen = _b[0], setScreen = _b[1];
    var _c = __read((0, react_1.useState)(false), 2), isOpen = _c[0], setIsOpen = _c[1];
    var _d = __read((0, react_1.useState)(false), 2), lockEditing = _d[0], setLockEditing = _d[1];
    (0, react_1.useEffect)(function () {
        if (isOpen) {
            setScreen("menu");
        }
    }, [isOpen]);
    return (react_1.default.createElement("div", { className: styles.menuContainer },
        react_1.default.createElement(kaleidoscope_1.Popover, { autoStack: true, isOpen: isOpen, alignment: kaleidoscope_1.PopoverAlignment.Start, padding: "none", size: kaleidoscope_1.PopoverSize.Small, button: function (props) { return (react_1.default.createElement(kaleidoscope_1.IconButton, __assign({}, props, { icon: react_1.default.createElement(icons_1.LibraryAdd, null), isIconOnly: true, isRound: true, size: kaleidoscope_1.ButtonSize.Small, className: styles.actionsMenu, onClick: function () {
                    setIsOpen(!isOpen);
                }, tooltip: { content: "Save to library" } }))); } },
            react_1.default.createElement(kaleidoscope_1.Stack, { padding: "l", gap: "m" },
                screen === "menu" && (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(kaleidoscope_1.Stack, { gap: "xs" },
                        react_1.default.createElement(kaleidoscope_1.Label, null, "Save block"),
                        react_1.default.createElement(kaleidoscope_1.Text, { size: "s" }, "Save to reuse this block in any Qwilr Page.")),
                    react_1.default.createElement(kaleidoscope_1.FormComponent, { onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
                            var blockName, savedBlock, error_1;
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        blockName = "".concat((_a = values.blockName) !== null && _a !== void 0 ? _a : "Untitled");
                                        _b.label = 1;
                                    case 1:
                                        _b.trys.push([1, 5, , 6]);
                                        return [4, savedBlockService.save(block, blockName)];
                                    case 2:
                                        savedBlock = _b.sent();
                                        if (!lockEditing) return [3, 4];
                                        return [4, savedBlockService.setLockEditingSetting(savedBlock.id, true)];
                                    case 3:
                                        _b.sent();
                                        _b.label = 4;
                                    case 4:
                                        telemetry.trackOnly("Saved Block Created", {
                                            saved_block_origin: TelemetryEnums_1.SavedBlockOrigin.Editor,
                                            block_type: block.type,
                                        });
                                        setScreen("saveDone");
                                        return [3, 6];
                                    case 5:
                                        error_1 = _b.sent();
                                        return [2, (0, kaleidoscope_1.createFormSubmitErrorObject)("Something went wrong while saving the block. Please try again.")];
                                    case 6: return [2];
                                }
                            });
                        }); }, shakeOnError: false, fieldSize: "small" },
                        react_1.default.createElement(kaleidoscope_1.FormContext.Consumer, null, function (context) { return (react_1.default.createElement(kaleidoscope_1.Stack, { gap: "m" },
                            react_1.default.createElement(kaleidoscope_1.FormTextInput, { label: "Block name", name: "blockName", validate: function (value) {
                                    if (!value) {
                                        return "Please enter a name for your block";
                                    }
                                } }),
                            react_1.default.createElement(kaleidoscope_1.Toggle, { label: "Lock editing to admins", value: lockEditing, onChange: function () {
                                    setLockEditing(!lockEditing);
                                } }),
                            react_1.default.createElement(kaleidoscope_1.Expandable, { onEnter: utils_1.forceReflow, expanded: !!(context === null || context === void 0 ? void 0 : context.submitError) }, function (_a) {
                                var expandableElementRef = _a.expandableElementRef;
                                return (react_1.default.createElement("div", { ref: expandableElementRef },
                                    react_1.default.createElement(kaleidoscope_1.Alert, { heading: "Error", alertType: kaleidoscope_1.AlertType.Error }, context === null || context === void 0 ? void 0 : context.submitError)));
                            }),
                            react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: "s" },
                                react_1.default.createElement(kaleidoscope_1.Button, { type: kaleidoscope_1.ButtonType.Secondary, size: kaleidoscope_1.ButtonSize.Small, onClick: function () {
                                        setIsOpen(false);
                                    } }, "Cancel"),
                                react_1.default.createElement(kaleidoscope_1.FormSubmitButton, { type: kaleidoscope_1.ButtonType.Primary }, "Save")))); })))),
                screen === "saveDone" && (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(kaleidoscope_1.Stack, { gap: "m" },
                        react_1.default.createElement(kaleidoscope_1.Stack, { gap: "s", align: "center" },
                            react_1.default.createElement("div", { className: styles.successIcon },
                                react_1.default.createElement(icons_1.Tick, { "aria-hidden": true, color: "success" })),
                            react_1.default.createElement(kaleidoscope_1.Text, { size: "s" }, "Block saved to library")),
                        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: "s", justify: "center" },
                            react_1.default.createElement(kaleidoscope_1.Button, { type: kaleidoscope_1.ButtonType.Secondary, size: kaleidoscope_1.ButtonSize.Small, onClick: function () {
                                    navigator("dashboard", { nav: "blocks" });
                                } }, "Go to library"),
                            react_1.default.createElement(kaleidoscope_1.Button, { type: kaleidoscope_1.ButtonType.Primary, size: kaleidoscope_1.ButtonSize.Small, onClick: function () { return setIsOpen(false); } }, "Done")))))))));
};
exports.BlockActionsMenu = BlockActionsMenu;

import "Application/Library/PaymentsDashboard/PaymentsDashboard.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Library/PaymentsDashboard/PaymentsDashboard.css.ts.vanilla.css\",\"source\":\"Lm9jaDBnczAgewogIG1hcmdpbjogdmFyKC0tc3BhY2luZy14eHhsX18xYmw3amoxMXEpOwp9Ci5vY2gwZ3MxIHsKICB3aWR0aDogMTAwJTsKICBtYXgtd2lkdGg6IDEyMDBweDsKICBtYXJnaW46IGF1dG87CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwogIGdhcDogdmFyKC0tc3BhY2luZy1sX18xYmw3amoxMW4pOwogIG1hcmdpbi1ib3R0b206IHZhcigtLXNwYWNpbmcteHh4bF9fMWJsN2pqMTFxKTsKfQoub2NoMGdzMiB7CiAgbWluLXdpZHRoOiAyMjBweDsKfQoub2NoMGdzMyB7CiAgY29sb3I6IHZhcigtLWNvbG9yLXRleHRTdWNjZXNzX18xYmw3amoxNyk7Cn0KLm9jaDBnczQgewogIGNvbG9yOiB2YXIoLS1jb2xvci1pY29uU3VjY2Vzc19fMWJsN2pqMXQpOwp9Ci5vY2gwZ3M1IHsKICBjb2xvcjogdmFyKC0tY29sb3ItdGV4dENhdXRpb25fXzFibDdqajE4KTsKfQoub2NoMGdzNiB7CiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tY29sb3Itc3VyZmFjZUNhdXRpb25fXzFibDdqajFsKTsKICBib3JkZXItcmFkaXVzOiB2YXIoLS1ib3JkZXJSYWRpdXMtc3VyZmFjZV9fMWJsN2pqMTFoKTsKICBwYWRkaW5nOiB2YXIoLS1zcGFjaW5nLXhzX18xYmw3amoxMWspOwogIHBhZGRpbmctbGVmdDogdmFyKC0tc3BhY2luZy1zX18xYmw3amoxMWwpOwogIHBhZGRpbmctcmlnaHQ6IHZhcigtLXNwYWNpbmctc19fMWJsN2pqMTFsKTsKfQoub2NoMGdzNyB7CiAgY29sb3I6IHZhcigtLWNvbG9yLWljb25DYXV0aW9uX18xYmw3amoxdSk7Cn0KLm9jaDBnczggewogIGNvbG9yOiB2YXIoLS1jb2xvci10ZXh0U2Vjb25kYXJ5X18xYmw3amoxNSk7Cn0KLm9jaDBnczkgewogIGNvbG9yOiB2YXIoLS1jb2xvci1pY29uX18xYmw3amoxcCk7Cn0KLm9jaDBnc2EgewogIGZvbnQtd2VpZ2h0OiA1MDA7Cn0=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var wrapper = 'och0gs0';
export var content = 'och0gs1';
export var gatewaySelectorWrapper = 'och0gs2';
export var connected = 'och0gs3';
export var connectedIcon = 'och0gs4';
export var onboarding = 'och0gs5';
export var warningContainer = 'och0gs6';
export var onboardingIcon = 'och0gs7';
export var noGateway = 'och0gs8';
export var noGatewayIcon = 'och0gs9';
export var reducedStrength = 'och0gsa';
/*
  The Block Menu.

  @NOTE: We purposefully keep the settings action separate from the rest and inject
  it seperately. This is because the settings panel items change per block type, whereas the
  rest of the actions are relative to the context (i.e. Project Editor vs Saved Content Navigator).

  @param blockMenuActions [Array] list of block menu actions. An action has the following format:
    - `internal` [String] The internal name for the action.
    - `displayType` [String] Actions can be either "simple" or "complex"
        and display different UI for each kind.
    - `description` [String] Description of what the action does.
    - `event` [String] Analytics event when this action is clicked.
    - `icon` [String] Icon for the action.
    - `ctrl` [Object] (Optional) A UI controller to make for the action.
    - `onClick` [Function] Function to invoke when the action icon is clicked.
 */

(function () {
  var BlockMenu,
    StyleCodeEditor,
    runInAction,
    isUsingTCDC,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  isUsingTCDC = require("@CommonFrontendBackend/FeatureFlag").isUsingTCDC;
  runInAction = require("mobx").runInAction;
  StyleCodeEditor = require("Project/BlockNew/Menu/StyleSelector/StyleEditor_old/StyleEditor");

  module.exports = BlockMenu = (function () {
    function BlockMenu(
      di,
      settings,
      blockMenuActions,
      OnClickOutside,
      invoke,
      createUi,
      block,
      projectScrollTo,
      viewState,
      blockEvents,
      $timeout,
      make,
      projectEvents,
      usingFeature,
      hint,
      projectStore,
      blockList,
      blockViewStore,
    ) {
      var access, action, actions, deselectAllSuperpowers, i, len, onClickOutside, styleAction, superpowers, uiActions;
      this.projectScrollTo = projectScrollTo;
      this.$timeout = $timeout;
      this.getItem = bind(this.getItem, this);
      this.gotoSettings = bind(this.gotoSettings, this);
      this.toggleSettings = bind(this.toggleSettings, this);
      this.toggleCurrentTab = bind(this.toggleCurrentTab, this);
      this.viewState = viewState;
      this.viewState.menuExpanded = false;
      if (block.$userAction === "view") {
        access = "view";
      } else {
        access = "edit";
      }
      di.value(
        "closeMenu",
        (function (_this) {
          return function () {
            _this.viewState.currentTab.ui = null;
            return (_this.viewState.currentTab.name = "");
          };
        })(this),
      );
      di.value("toggleCurrentTab", this.toggleCurrentTab);
      actions = di.invoke(blockMenuActions, null, {
        settings: settings,
        access: access,
      });
      uiActions = [];
      styleAction = null;

      /*
        @NOTE: We make controllers for actions public, so that they
        can be accessed externally - i.e. During onboarding for example, we
        will want to toggle sub-components of an action, such as a settings panel.
       */
      this.actions = {};
      for (i = 0, len = actions.length; i < len; i++) {
        action = actions[i];
        if (action.ctrl) {
          this.actions[action.internal] = action.ctrl;
        }
        if (action.internal === "styles") {
          styleAction = action;
        } else {
          uiActions.push(action);
        }
      }
      blockEvents.on(
        "blockDeleteUndo",
        (function (_this) {
          return function () {
            return (_this.viewState.currentTab.ui = null);
          };
        })(this),
      );
      this.openStyleCodeEditor = (function (_this) {
        return function (style) {
          var styleCodeEditor;
          if (style == null) {
            return;
          }
          styleCodeEditor = make(StyleCodeEditor, {
            style: style,
            refreshSwatchStyles: function () {
              var blockElem;
              blockElem = block.$ctrl.ui.blockElement;
              return $(blockElem)
                .parent()
                .find(".swatch.is-custom")
                .each(function () {
                  var classes, color, elem, j, len1, tag, tagElem;
                  elem = $(this);
                  classes = [".h1", ".h2", ".p"];
                  for (j = 0, len1 = classes.length; j < len1; j++) {
                    tag = classes[j];
                    tagElem = elem.find(tag).get(0);
                    if (tagElem == null) {
                      return;
                    }
                    color = $(tagElem).css("color");
                    $(tagElem).css("background-color", color);
                  }
                });
            },
          });
          projectEvents.emit("bigComponentOpened");
          _this.ui.styleCodeEditor = styleCodeEditor.ui;
          return styleCodeEditor.show();
        };
      })(this);
      superpowers = settings.superpowers || [];
      if (access === "view") {
        superpowers = superpowers.filter(function (sp) {
          return sp.visibleOnViewOnlyBlocks;
        });
      }
      superpowers.forEach(function (superpower) {
        superpower.visible =
          superpower.visible ||
          function () {
            return true;
          };
        return (superpower.hint = hint.small({
          targetSelector: "#" + block.$id + " ." + superpower.ctrl.className,
          hint: superpower.name,
        }));
      });
      deselectAllSuperpowers = (function (_this) {
        return function () {
          var j, len1, ref, ref1, results, superpowerButton;
          ref = _this.ui.superpowerButtons;
          results = [];
          for (j = 0, len1 = ref.length; j < len1; j++) {
            superpowerButton = ref[j];
            if ((ref1 = superpowerButton.hint) != null) {
              ref1.hide();
            }
            if (superpowerButton.selected) {
              results.push(_this.ui.deselect(superpowerButton));
            } else {
              results.push(void 0);
            }
          }
          return results;
        };
      })(this);
      onClickOutside = make(OnClickOutside, {
        ignoreElems: [
          ".style-creator",
          "#" + block.$id + " .block-menu",
          ".q-tooltip",
          ".saved-style",
          ".style-shortcut",
          ".style-shortcut__input-container",
        ],
        onClickOutside: (function (_this) {
          return function () {
            deselectAllSuperpowers();
            _this.ui.closeMenu();
            return onClickOutside.stopListening();
          };
        })(this),
      });
      this.blockMenuElem = null;
      this.blockOptionsHint = hint.small({
        targetSelector: "#" + block.$id + " .expando-button",
        hint: "Block options",
      });
      this.ui = createUi(require("./Menu.html"), {
        actions: uiActions,
        block: block,
        viewState: this.viewState,
        styleAction: styleAction,
        superpowerButtons: superpowers,
        access: access,
        hasEditAccess: projectStore.isEditable,
        showOldMenuIcon: !isUsingTCDC(usingFeature) && access !== "view",
        select: (function (_this) {
          return function (superpower) {
            if (superpower.selectable) {
              superpower.selected = true;
            }
            if (superpower.select != null) {
              superpower.select();
              return onClickOutside.startListening();
            }
          };
        })(this),
        deselect: (function (_this) {
          return function (superpower) {
            superpower.selected = false;
            if (superpower.deselect != null) {
              return superpower.deselect();
            }
          };
        })(this),
        selectedTopButton: (function (_this) {
          return function (maybeSuperpower) {
            if (maybeSuperpower != null ? maybeSuperpower.selected : void 0) {
              _this.ui.deselect(maybeSuperpower);
              return;
            } else if (maybeSuperpower == null && _this.ui.viewState.menuExpanded) {
              _this.ui.toggleToolbar();
              return;
            }
            if (_this.ui.viewState.menuExpanded) {
              _this.ui.toggleToolbar();
            }
            deselectAllSuperpowers();
            if (maybeSuperpower == null) {
              return _this.ui.toggleToolbar();
            } else {
              return _this.ui.select(maybeSuperpower);
            }
          };
        })(this),
        $onElementRendered: (function (_this) {
          return function (element) {
            runInAction(() => {
              blockViewStore.superpowerMenuContainer = element[0];
            });
            return (_this.blockMenuElem = element);
          };
        })(this),
        isActive: (function (_this) {
          return function (item) {
            if (item == null) {
              return false;
            }
            return _this.viewState.currentTab.name === item.name;
          };
        })(this),
        toggleToolbar: (function (_this) {
          return function () {
            _this.viewState.menuExpanded = !_this.viewState.menuExpanded;
            if (_this.viewState.menuExpanded && settings.onMenuOpen) {
              settings.onMenuOpen();
            }
            if (_this.ui.viewState.menuExpanded === false) {
              return _this.ui.closeTab();
            } else {
              return _this.toggleSettings();
            }
          };
        })(this),
        openTab: (function (_this) {
          return function (item) {
            onClickOutside.startListening();
            _this.viewState.currentTab.ui = item.ui;
            _this.viewState.currentTab.name = item.name;
            return (_this.viewState.menuExpanded = true);
          };
        })(this),
        closeTab: (function (_this) {
          return function () {
            $(".project-content-wrapper").off("click.blockMenu");
            _this.viewState.currentTab.ui = null;
            return (_this.viewState.currentTab.name = "");
          };
        })(this),
        closeMenu: (function (_this) {
          return function () {
            _this.ui.closeTab();
            return (_this.viewState.menuExpanded = false);
          };
        })(this),
        isTabOpen: (function (_this) {
          return function () {
            return _this.viewState.currentTab.ui !== null;
          };
        })(this),
        toggleCurrentTab: this.toggleCurrentTab,
        toggleSettings: this.toggleSettings,
        isFirstBlock: (function (_this) {
          return function () {
            return blockList.getIndex(block) === 0;
          };
        })(this),
      });
    }

    BlockMenu.prototype.toggleCurrentTab = function (item) {
      if (this.viewState.currentTab.name === item.name) {
        return this.ui.closeTab();
      } else {
        return this.ui.openTab(item);
      }
    };

    BlockMenu.prototype.toggleSettings = function () {
      return this.$timeout(
        (function (_this) {
          return function () {
            var ref;
            if ((ref = _this.blockOptionsHint) != null) {
              ref.hide();
            }
            return _this.toggleCurrentTab(_this.getItem("Settings"));
          };
        })(this),
      );
    };

    BlockMenu.prototype.gotoSettings = function () {
      this.ui.openTab(this.getItem("Settings"));
      return this.projectScrollTo($(this.blockMenuElem));
    };

    BlockMenu.prototype.getItem = function (name) {
      return _.find(this.ui.actions, {
        name: "Settings",
      });
    };

    return BlockMenu;
  })();
}.call(this));

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PaymentSetupMenu = (function () {
    function PaymentSetupMenu(createUi, account, block, integrationService, payNowDisabler, telemetry) {
        var _this = this;
        if (!block.settings.payNow.partPaymentType) {
            block.settings.payNow.partPaymentType = "percentage";
        }
        if (!block.settings.payNow.partPaymentAmount) {
            block.settings.payNow.partPaymentAmount = 0;
        }
        if (block.settings.payNow.partPaymentPercentage) {
            block.settings.payNow.partPaymentAmount = block.settings.payNow.partPaymentPercentage;
        }
        block.settings.payNow.partPaymentPercentage = undefined;
        this.ui = createUi(require("./PaymentSetupMenu.html"), {
            settings: block.settings,
            payNowOnAccount: function () {
                var _a;
                return (_a = account.integrations.stripe) === null || _a === void 0 ? void 0 : _a.enabled;
            },
            onPayNowChange: function () {
                if (_this.ui.payNowOnAccount() && block.settings.payNow.enabled) {
                    return telemetry.trackOnly("Payment Enabled", {});
                }
            },
            setup: function () {
                return integrationService.openIntegrationSettings("stripe");
            },
            isPayNowDisabled: function () {
                return !payNowDisabler.isPayNowPossible();
            },
        });
    }
    return PaymentSetupMenu;
}());
exports.default = PaymentSetupMenu;

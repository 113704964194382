"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneralSettings = void 0;
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var react_1 = __importStar(require("react"));
var icons_1 = require("@qwilr/kaleidoscope/icons");
var styles = __importStar(require("./PaymentsSettings.css"));
var dynamic_1 = require("@vanilla-extract/dynamic");
var tokens_1 = require("@qwilr/kaleidoscope/tokens");
var TooltipToggle_1 = require("Library/PaymentsDashboard/PaymentSettings/TooltipToggle");
var GeneralSettings = function (_a) {
    var _b;
    var data = _a.data, onUpdate = _a.onUpdate;
    var _c = __read((0, react_1.useState)(false), 2), expanded = _c[0], setExpanded = _c[1];
    return (react_1.default.createElement(kaleidoscope_1.Stack, null,
        react_1.default.createElement(kaleidoscope_1.ButtonCard, { "aria-expanded": expanded, onClick: function () { return setExpanded(!expanded); }, "aria-controls": "payment-gateway-settings", className: styles.expandableGatewaySection },
            react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", justify: "spaceBetween", padding: "m", className: styles.fullWidth },
                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: "s", align: "center", className: styles.fullWidth },
                    react_1.default.createElement("div", { className: styles.generalSettingsIconStyle },
                        react_1.default.createElement(icons_1.Menu, { style: (0, dynamic_1.assignInlineVars)((_b = {},
                                _b[kaleidoscope_1.iconVars.color] = tokens_1.palette.blue500,
                                _b)) })),
                    react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", justify: "spaceBetween", className: styles.fullWidth },
                        react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsDescription },
                            react_1.default.createElement(kaleidoscope_1.Text, { size: "m", strong: true }, "Default options"),
                            react_1.default.createElement(kaleidoscope_1.Text, { secondary: true, size: "xs" }, "Choose the default payment settings for your pages. Changes are only applied to pages created after you change a setting.")),
                        expanded ? react_1.default.createElement(icons_1.ChevronDown, null) : react_1.default.createElement(icons_1.ChevronRight, null))))),
        react_1.default.createElement(kaleidoscope_1.Expandable, { element: kaleidoscope_1.Stack, expanded: expanded, timeout: kaleidoscope_1.AnimationDuration.Long }, function (_a) {
            var expandableElementRef = _a.expandableElementRef;
            return (react_1.default.createElement(kaleidoscope_1.Stack, { padding: "l", direction: "vertical", justify: "center", ref: expandableElementRef },
                react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsRow({ hasDivider: true }), direction: "horizontal", align: "start", justify: "spaceBetween" },
                    react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsDescription },
                        react_1.default.createElement(kaleidoscope_1.Text, { size: "s" }, "Enable payments on all pages"),
                        react_1.default.createElement(kaleidoscope_1.Text, { size: "xs", secondary: true }, "Payments will be turned on by default for all pages with a quote and accept block. Only applies to pages created after the toggle is turned on/off.")),
                    react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", justify: "end" },
                        react_1.default.createElement(kaleidoscope_1.Toggle, { labelHidden: true, disabled: false, key: "key", label: "label", value: data.pageDefaults.enabled, onChange: function (val) {
                                return onUpdate({
                                    pageDefaults: __assign(__assign({}, data.pageDefaults), { enabled: val }),
                                });
                            } }))),
                react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsRow({ hasDivider: true }), direction: "horizontal", align: "start", justify: "spaceBetween" },
                    react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsDescription },
                        react_1.default.createElement(kaleidoscope_1.Text, { size: "s" }, "Require payment on accept"),
                        react_1.default.createElement(kaleidoscope_1.Text, { size: "xs", secondary: true }, "Buyers will be required to make a payment to accept the page. Keep this off if you want to give buyers the option to accept now, and pay later.")),
                    react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", justify: "end" },
                        react_1.default.createElement(TooltipToggle_1.TooltippedToggle, { tooltipImage: data.pageDefaults.requireOnAccept
                                ? "Assets/Images/qwilrPay/tooltipGifs/required_payment.gif"
                                : "Assets/Images/qwilrPay/tooltipGifs/optional_payment.gif", disabled: false, label: "Enable optional payment", labelHidden: true, value: data.pageDefaults.requireOnAccept, onChange: function (val) {
                                return onUpdate({
                                    pageDefaults: __assign(__assign({}, data.pageDefaults), { requireOnAccept: val }),
                                });
                            } }))),
                react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsRow({ hasDivider: true }) },
                    react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "start", justify: "spaceBetween", gap: "xl" },
                        react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsDescription },
                            react_1.default.createElement(kaleidoscope_1.Text, { size: "s" }, "Part payments"),
                            react_1.default.createElement(kaleidoscope_1.Text, { size: "xs", secondary: true }, "Set a default percentage or amount for buyers to pay when a page is accepted. You choose whether creators can modify this in the permissions below.")),
                        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", justify: "end" },
                            react_1.default.createElement(TooltipToggle_1.TooltippedToggle, { tooltipImage: "Assets/Images/qwilrPay/tooltipGifs/part_payment.gif", disabled: false, key: "key", label: "Enable part payments", labelHidden: true, value: data.pageDefaults.partialPaymentConfig.enabled, onChange: function (val) {
                                    return onUpdate({
                                        pageDefaults: __assign(__assign({}, data.pageDefaults), { partialPaymentConfig: __assign(__assign({}, data.pageDefaults.partialPaymentConfig), { enabled: val }) }),
                                    });
                                } }))),
                    data.pageDefaults.partialPaymentConfig.enabled && (react_1.default.createElement(kaleidoscope_1.Stack, { gap: "xxs", paddingTop: "s" },
                        react_1.default.createElement(kaleidoscope_1.Text, { strong: true, size: "xs" }, "Choose percentage or fixed amount"),
                        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", className: styles.partPaymentInputGroup, justify: "spaceBetween", align: "center" },
                            react_1.default.createElement(kaleidoscope_1.TextInput, { label: "", labelHidden: true, type: kaleidoscope_1.TextInputType.Number, className: styles.paymentTypeInput, size: kaleidoscope_1.TextInputSize.Small, variant: kaleidoscope_1.TextInputVariant.Borderless, value: "".concat(data.pageDefaults.partialPaymentConfig.amount), onChange: function (value) {
                                    return onUpdate({
                                        pageDefaults: __assign(__assign({}, data.pageDefaults), { partialPaymentConfig: __assign(__assign({}, data.pageDefaults.partialPaymentConfig), { amount: Number(value) }) }),
                                    });
                                } }),
                            react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.partPaymentTypeToggle, direction: "horizontal" },
                                react_1.default.createElement(kaleidoscope_1.Button, { className: styles.toggleButton, size: kaleidoscope_1.ButtonSize.Small, onClick: function () {
                                        return onUpdate({
                                            pageDefaults: __assign(__assign({}, data.pageDefaults), { partialPaymentConfig: __assign(__assign({}, data.pageDefaults.partialPaymentConfig), { amount: data.pageDefaults.partialPaymentConfig.kind === "fixed"
                                                        ? 0
                                                        : data.pageDefaults.partialPaymentConfig.amount, kind: "percentage" }) }),
                                        });
                                    }, type: data.pageDefaults.partialPaymentConfig.kind === "percentage"
                                        ? kaleidoscope_1.ButtonType.Secondary
                                        : kaleidoscope_1.ButtonType.Tertiary, buttonState: kaleidoscope_1.ButtonState.Idle }, "%"),
                                react_1.default.createElement(kaleidoscope_1.Button, { className: styles.toggleButton, size: kaleidoscope_1.ButtonSize.Small, onClick: function () {
                                        return onUpdate({
                                            pageDefaults: __assign(__assign({}, data.pageDefaults), { partialPaymentConfig: __assign(__assign({}, data.pageDefaults.partialPaymentConfig), { amount: data.pageDefaults.partialPaymentConfig.kind === "percentage"
                                                        ? 0
                                                        : data.pageDefaults.partialPaymentConfig.amount, kind: "fixed" }) }),
                                        });
                                    }, type: data.pageDefaults.partialPaymentConfig.kind !== "percentage"
                                        ? kaleidoscope_1.ButtonType.Secondary
                                        : kaleidoscope_1.ButtonType.Tertiary, buttonState: kaleidoscope_1.ButtonState.Idle }, "$")))))),
                react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsRow({ hasDivider: false }), direction: "horizontal", align: "start", justify: "spaceBetween" },
                    react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsDescription },
                        react_1.default.createElement(kaleidoscope_1.Text, { size: "s" }, "Show buyers a payment alert on pages"),
                        react_1.default.createElement(kaleidoscope_1.Text, { size: "xs", secondary: true }, "This displays a page alert indicating that payment is available when buyers view the page. You can choose whether creators can modify this on pages in the permissions below.")),
                    react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", justify: "end" },
                        react_1.default.createElement(TooltipToggle_1.TooltippedToggle, { tooltipImage: "Assets/Images/qwilrPay/tooltipGifs/discoverable_payments.gif", disabled: false, key: "key", label: "Make payments more discoverable", labelHidden: true, value: data.pageDefaults.discoverable, onChange: function (val) {
                                return onUpdate({
                                    pageDefaults: __assign(__assign({}, data.pageDefaults), { discoverable: val }),
                                });
                            } })))));
        })));
};
exports.GeneralSettings = GeneralSettings;

(function () {
  var QuoteCustomLabels;

  module.exports = QuoteCustomLabels = (function () {
    function QuoteCustomLabels(createUi, make, block, telemetry, telemetryDebounceInterval) {
      var deregister;
      deregister = null;
      this.ui = createUi(require("./CustomLabels.html"), {
        settings: block.settings.customLabels,
        labels: [
          {
            title: "Total",
            internal: "total",
          },
          {
            title: "Subtotal",
            internal: "subtotal",
          },
          {
            title: "Excluding Tax",
            internal: "excludingTax",
          },
          {
            title: "Discount",
            internal: "discount",
          },
          {
            title: "Per (e.g 'Per Hour')",
            internal: "per",
          },
        ],
        $init: (function (_this) {
          return function (scope) {
            var listener, watchExpr;
            watchExpr = function (scope) {
              var e;
              try {
                return JSON.stringify(scope.ui.settings);
              } catch (error) {
                e = error;
                console.error(e);
                return "";
              }
            };
            listener = function (oldVal, newVal, scope) {
              if (oldVal !== newVal) {
                telemetry.trackOnly("Quote Block Custom Labels Changed", {});
                if (deregister != null) {
                  return deregister();
                }
              }
            };
            return (deregister = scope.$watch(watchExpr, listener));
          };
        })(this),
      });
    }

    return QuoteCustomLabels;
  })();
}.call(this));

(function () {
  var CustomButton;

  module.exports = CustomButton = (function () {
    function CustomButton(createUi, settings, $timeout, QuoteModel, telemetry, telemetryDebounceInterval) {
      var defaultButtonText, defaultSuccessMsg, triggerAcceptButtonTelemetry, triggerSuccessMessageTelemetry;
      defaultButtonText = QuoteModel.settings.accept.text;
      defaultSuccessMsg = QuoteModel.settings.accept.successMsg;
      triggerSuccessMessageTelemetry = _.debounce(
        (function (_this) {
          return function () {
            return telemetry.trackOnly("Accept Button Success Message Changed", {
              accept_button_success_message: _this.ui.successMsg,
            });
          };
        })(this),
        telemetryDebounceInterval,
      );
      triggerAcceptButtonTelemetry = _.debounce(
        (function (_this) {
          return function () {
            return telemetry.trackOnly("Accept Button Renamed", {});
          };
        })(this),
        telemetryDebounceInterval,
      );
      this.ui = createUi(require("Project/Modules/Quote/Accept/CustomButton/CustomButton.html"), {
        settings: settings,
        buttonText: settings.text || defaultButtonText,
        successMsg: settings.successMsg || defaultSuccessMsg,
        showSuccess: false,
        onTextUpdate: (function (_this) {
          return function () {
            triggerAcceptButtonTelemetry();
            return _this.ui.onUpdate();
          };
        })(this),
        onMessageUpdate: (function (_this) {
          return function () {
            triggerSuccessMessageTelemetry();
            return _this.ui.onUpdate();
          };
        })(this),
        onUpdate: (function (_this) {
          return function () {
            if (!_this.ui.buttonText.length) {
              settings.text = defaultButtonText;
            } else {
              settings.text = _this.ui.buttonText;
            }
            if (!_this.ui.successMsg.length) {
              return (settings.successMsg = defaultSuccessMsg);
            } else {
              return (settings.successMsg = _this.ui.successMsg);
            }
          };
        })(this),
      });
    }

    return CustomButton;
  })();
}.call(this));

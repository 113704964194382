(function () {
  var ProjectPermissionsHandler, TelemetryEventName;

  TelemetryEventName = require("@CommonFrontendBackend/Telemetry").TelemetryEventName;

  module.exports = ProjectPermissionsHandler = (function () {
    function ProjectPermissionsHandler($http, $q, userList, getFullName, make, apiBaseUrl, telemetry) {
      var selectUserModal;
      selectUserModal = make(require("SettingsAngular/Team/Users/SelectUserModal"));
      this.showSelectUserModal = function (projects, selectedUserAction) {
        var currentOwner, explainer;
        if (!_.isArray(projects)) {
          projects = [projects];
        }
        explainer = "Who do you want to make the new owner?";
        if (projects.length === 1) {
          currentOwner = _.find(userList, function (user) {
            return user._id === projects[0].user;
          });
          explainer = "This project is currently owned by " + getFullName(currentOwner) + ". " + explainer;
        }
        return selectUserModal.selectUser({
          excludeUsers: [],
          title: "Transfer Ownership",
          explainer: explainer,
          buttonLabel: "Let's do this",
          selectedUserAction: selectedUserAction,
        });
      };
      this.changeOwner = (function (_this) {
        return function (projects, projectList) {
          if (!_.isArray(projects)) {
            projects = [projects];
          }
          return _this.showSelectUserModal(projects, function (selectedUser) {
            var maybePlural, projectsToUpdate;
            projectsToUpdate = projects.filter(function (project) {
              return project.user !== selectedUser._id;
            });
            if (projectsToUpdate.length === 0 && projects.length > 0) {
              maybePlural = projects.length === 1 ? "this project" : "these projects";
              return $q.reject("User already owns " + maybePlural);
            }
            return $http
              .post(
                apiBaseUrl + "/projects/owner",
                projectsToUpdate.map(function (project) {
                  return {
                    oldOwnerId: project.user,
                    newOwnerId: selectedUser._id,
                    projectId: project._id,
                  };
                }),
              )
              .then(function (response) {
                telemetry.trackOnly(TelemetryEventName.ProjectOwnerChanged);
                if (projectList != null) {
                  return projectList.refresh();
                }
              });
          });
        };
      })(this);
    }

    return ProjectPermissionsHandler;
  })();
}.call(this));

(function () {
  var FormBuilder;

  module.exports = FormBuilder = (function () {
    function FormBuilder(createUi, account, make, overlayStack, utils, telemetry) {
      var Editor, formModelBase, makeFormEditor, setCurrentForm;
      if (account.forms == null) {
        account.forms = [];
      }
      formModelBase = function () {
        return {
          name: "Untitled Form",
          fields: [],
          id: utils.uniqueId(12),
        };
      };
      Editor = require("./FormEditor/FormEditor");
      makeFormEditor = (function (_this) {
        return function (formModel) {
          return make(Editor, {
            form: formModel,
            onDelete: function () {
              _this.ui.currentForm = null;
              return telemetry.trackOnly("Custom Form Deleted", {});
            },
            closeOverlay: overlayStack.closeCurrent,
          });
        };
      })(this);
      setCurrentForm = (function (_this) {
        return function (formModel) {
          var editorCtrl;
          editorCtrl = makeFormEditor(formModel);
          return (_this.ui.currentForm = editorCtrl.ui);
        };
      })(this);
      this.ui = createUi(require("./FormBuilder.html"), {
        currentForm: null,
        forms: account.forms,
        newForm: function () {
          account.forms.push(formModelBase());
          setCurrentForm(account.forms[account.forms.length - 1]);
          return telemetry.trackOnly("Custom Form Created");
        },
        selectForm: function (model) {
          return setCurrentForm(model);
        },
      });
      this.show = (function (_this) {
        return function () {
          return overlayStack.add(_this.ui);
        };
      })(this);
    }

    return FormBuilder;
  })();
}.call(this));

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChooseLandingPoint = void 0;
var react_1 = __importStar(require("react"));
var CensusCollector_1 = require("Onboarding/CensusCollector/CensusCollector");
var inversify_react_1 = require("inversify-react");
var FeatureFlag_1 = require("@CommonFrontendBackend/FeatureFlag");
var TailoredPages_1 = require("Onboarding/CensusCollector/ChooseLandingPoint/TailoredPages");
var Explorer_1 = require("Onboarding/CensusCollector/ChooseLandingPoint/Explorer");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var styles = __importStar(require("./ChooseLandingPoint.css"));
var DocUploader_1 = require("DocUploader/DocUploader");
var event_tracker_types_1 = require("@qwilr/event-tracker-types");
var ChooseLandingPoint = function (props) {
    var usingFeature = (0, inversify_react_1.useInjection)("usingFeature");
    var dataStore = (0, inversify_react_1.useInjection)("censusCollectorStore");
    var apiBaseUrl = (0, inversify_react_1.useInjection)("apiBaseUrl");
    var onboardingTelemetryService = (0, inversify_react_1.useInjection)("onboardingTelemetryService");
    var tailoredPagesEnabled = usingFeature(FeatureFlag_1.FeatureFlag.TailoredPages) && dataStore.shouldShowTailoredPages;
    var docUploaderEnabled = usingFeature(FeatureFlag_1.FeatureFlag.DocUploaderV1);
    var _a = __read((0, react_1.useState)(false), 2), showDocUploader = _a[0], setShowDocUploader = _a[1];
    if (showDocUploader && docUploaderEnabled) {
        return (react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.docUploaderContainer, justify: "center", align: "center" },
            react_1.default.createElement(DocUploader_1.DocUploader, { handleEndDocUpload: function (projectId) {
                    onboardingTelemetryService.onboardingStepCompleted(tailoredPagesEnabled ? event_tracker_types_1.OnboardingStepName.TailoredPages : event_tracker_types_1.OnboardingStepName.Explorer, tailoredPagesEnabled
                        ? { tailoredPagesOptionChosen: event_tracker_types_1.OnboardingTailoredPagesOption.Upload }
                        : { explorerOptionChosen: event_tracker_types_1.OnboardingExplorerOption.Upload });
                    dataStore.completionData = { landingPoint: CensusCollector_1.ChosenLandingPoint.Editor, pageId: projectId };
                    props.onComplete();
                }, handleSkipDocUpload: function () {
                    props.onComplete();
                }, apiBaseUrl: apiBaseUrl, eventOrigin: event_tracker_types_1.EventOrigin.QwilrOnboarding })));
    }
    if (tailoredPagesEnabled) {
        return (react_1.default.createElement(TailoredPages_1.TailoredPages, { onComplete: props.onComplete, onStartDocUploader: function () {
                setShowDocUploader(true);
            } }));
    }
    return (react_1.default.createElement(Explorer_1.Explorer, { onComplete: props.onComplete, onStartDocUploader: function () {
            setShowDocUploader(true);
        } }));
};
exports.ChooseLandingPoint = ChooseLandingPoint;

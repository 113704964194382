import "Application/Library/PaymentsDashboard/PaymentSettings/PaymentsSettings.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Library/PaymentsDashboard/PaymentSettings/PaymentsSettings.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81Wy26jMBTd9ys8i5GaBREm0ABdzg+Mph9QOdgQp8ZGxrR0RvPvIxuIzWNIqmbRRVUZ33t97vG5x9k+w6RqxYsP/twB8EaxOqYA+v73xzsAjoQWRzWs/95th+BtKTBiJqVErTekhaFftTqxQhhTXqSgXyvSKg8xWvAUMJIrtxi8fLJT8BXJe8+rK5RRXngte36GB7Y/nSAUGx14EBIT6ck+vWpBLRjFzlYmmJBDIbPwuh1byt+4AIObAhSvROZMvKUANUoYXCh7KaRoOF7Edt49V/o9grdbg7d/mFzIGJ6Djht0haQGRVPyFNQV4iBwqDsIpUR5I1pDg/s6YDYruuYyMK0rht5T0865LUXKiiFF+v7qFPjbXS6Bv93nch4lxZsJ8aNcAqgjLIoHg2Ku6qt1YEvtTal1DdSNzFFGnkgmOEby/VyLbj6kvbUxjDsc/fggTJt6WrC09crNZeX2qH9IqmiGLJgudyFBw5tFJ+MWd1ULYKRFbaEnriYif7yJOpeifHCpMOhGYrIcNLRLuvUiE93HX+ablwmupHBILhwHcoYEyOJwH8cAxiEIot1/mNNBwQ6CIIxAEEWbkY5zRgwoc3ceVaSsU5ARrogR7qmpFc3fDSLCld2yNBy+Bg0wiQFMHkAQJms8hJoHHwRReGsesrl/2E18JmmgIQpG/ukpUU2noradM1erZ7dcDbeHE3cAZ077AWPN3RbjaNRh4RySAi446e6q9eojwvpV0t++0bISUiGuFtnX/z1MJckUFTwFnZ+6xxxHx9gmPv5ezBQYVmsKOPtEf2/Or5MgnDgD/bzzLmOzT/yRYkxGzJzMoYbBQuoQ6EpGm/LVgnkxpRZA00zwpybLSG1z1SiVfRE3iLUT6FmHq66YgCCC+u/WblBOJPCZy7/OFS6+qSWSBeVLRjMJtV3wftrGQ/w46UxJxOsKScJH7764yhHcjKqTMGVsLrufkpYuU7UG+g/t0NMzZAwAAA==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var paymentsModal = '_19pxok0';
export var leftPanelWrapper = '_19pxok1';
export var rightPanelWrapper = '_19pxok2';
export var modalHeader = '_19pxok3';
export var modalContainer = '_19pxok4';
export var modalContent = '_19pxok5';
export var settingsCard = '_19pxok6';
export var removeGatewayCard = '_19pxok7';
export var dangerZonePill = '_19pxok8';
export var toggleButton = '_19pxok9';
export var generalSettingsIconStyle = '_19pxoka';
export var permissionsIconStyle = '_19pxokb';
export var fullWidth = '_19pxokc';
export var settingsRow = _7a468({defaultClassName:'_19pxokd',variantClassNames:{hasDivider:{true:'_19pxoke'}},defaultVariants:{},compoundVariants:[]});
export var settingsDescription = '_19pxokf';
export var expandableGatewaySection = '_19pxokg';
export var partPaymentInputGroup = '_19pxokh';
export var partPaymentTypeToggle = '_19pxoki';
export var paymentTypeInput = '_19pxokj';
export var connectedIcon = '_19pxokk';
export var paymentMethodsIconStyle = '_19pxokl';
export var manageInStripe = '_19pxokm';
export var permissionsWrapper = '_19pxokn';
export var onboardingButton = '_19pxoko';
export var learnMoreIcon = '_19pxokp';
import "Application/DocUploader/UploadScreen.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/DocUploader/UploadScreen.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VVXY/aMBB851f4pRJU8jUOF+5q/kX71peTiTfGnLHTtcOHqvvvVRwCDpBKlVrxRnYmOzNrs3lyz7VezDPya0LIGrRaB05Yln1aTgjZaxnW/ePH5KnjsrvclUMJyEleH4h3RksSUFhfCwQbWkJ81EE7y8lKlO8KXWMl2QmcUiobFC1E/dsbW5mXzYaxZnYCQXhtFfVBWClQXhh+ltjKo63UhhR+DZKgWk3ZgrBiTvIin13MUhRSN56fZLrit1ijpbMBnbloqe7Fs3FaOuOQx+75nJH8uSB5UaSO5tGR1L424siJdRYS9DmilYEDVej2nLAELIYzfs3qQ4Iuho0VapmgLxFti1QgCE7YF7ZM+a3msteWGqHsTgXdvi0Lo5WlOsDWcwJWtjVXi1KH49Dk1yh0hrIEEvccXrUuwQbAtrxpfNDVMQ4dbEiheGsqh1ve/TQiwI8py+pDOulVlOu6ezDVvebX9dsBpTf0lOrfXc8yWqxdL4BgRNA7uBY2UIW/UK2ED64J343ba3uhYSoth+cUs7YynOTDewU3B9oTiwGvelwWNZplaHE9GuUqs35cls1oFspeBybfR9PQ+YBoHpdmO55m6NGOh7mK7SJzK1BpS4Or+03t23etoodkP7vZ8mad/ue/dH0atm5XCoUd2OD7NT+yF3/eLKroOG7Zywf2/EX7/AeFj9+Xj0mnuwcAAA==\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var uploadContainer = 'o4pi630';
export var dropzone = _7a468({defaultClassName:'o4pi631',variantClassNames:{isDragging:{true:'o4pi632'}},defaultVariants:{},compoundVariants:[]});
export var fileInput = 'o4pi633';
export var instructionsContainer = 'o4pi634';
export var instructions = 'o4pi635';
export var imageContainer = 'o4pi636';
export var image = _7a468({defaultClassName:'o4pi637',variantClassNames:{show:{true:'o4pi638',false:'o4pi639'}},defaultVariants:{},compoundVariants:[]});
export var pageIconContainer = 'o4pi63a';
export var pageIconParts = _7a468({defaultClassName:'o4pi63b',variantClassNames:{},defaultVariants:{},compoundVariants:[]});
export var dragCloudLeft = _7a468({defaultClassName:'o4pi63c',variantClassNames:{show:{true:'o4pi63d',false:'o4pi63e'}},defaultVariants:{},compoundVariants:[]});
export var dropCloudLeft = _7a468({defaultClassName:'o4pi63f',variantClassNames:{show:{true:'o4pi63g',false:'o4pi63h'}},defaultVariants:{},compoundVariants:[]});
export var dragCloudRight = _7a468({defaultClassName:'o4pi63i',variantClassNames:{show:{true:'o4pi63j',false:'o4pi63k'}},defaultVariants:{},compoundVariants:[]});
export var dropCloudRight = _7a468({defaultClassName:'o4pi63l',variantClassNames:{show:{true:'o4pi63m',false:'o4pi63n'}},defaultVariants:{},compoundVariants:[]});
export var footer = _7a468({defaultClassName:'o4pi63o',variantClassNames:{isDragging:{true:'o4pi63p'}},defaultVariants:{},compoundVariants:[]});
export var disclaimer = 'o4pi63q';
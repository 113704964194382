"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentSidebarTelemetry = void 0;
var ContentSidebarTelemetry = (function () {
    function ContentSidebarTelemetry(telemetry, editorState) {
        var _this = this;
        this.telemetry = telemetry;
        this.editorState = editorState;
        this.sidebarAssetAdded = function (_a) {
            var assetType = _a.assetType, insertMethod = _a.insertMethod, _b = _a.source, source = _b === void 0 ? "your_library" : _b, secondarySource = _a.secondarySource;
            _this.telemetry.trackOnly("Sidebar Asset Added", {
                sidebar_asset_added_asset_type: assetType,
                sidebar_asset_added_method: insertMethod,
                sidebar_asset_added_sidebar_session_duration: _this.editorState.getSidebarSessionTime(),
                source_tab: source,
                source_secondary_tab: secondarySource,
            });
        };
    }
    return ContentSidebarTelemetry;
}());
exports.ContentSidebarTelemetry = ContentSidebarTelemetry;

import "Application/Project/BlockNew/Menu/Settings/StyleButton/BackgroundStyleButton/BackgroundAssetBucket.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/BlockNew/Menu/Settings/StyleButton/BackgroundStyleButton/BackgroundAssetBucket.css.ts.vanilla.css\",\"source\":\"Ll8xZGtvYzNlMCB7CiAgcG9zaXRpb246IHJlbGF0aXZlOwp9Ci5fMWRrb2MzZTEgewogIHBvc2l0aW9uOiBhYnNvbHV0ZTsKICByaWdodDogdmFyKC0tc3BhY2luZy14c19fMWJsN2pqMTFrKTsKICB0b3A6IHZhcigtLXNwYWNpbmcteHNfXzFibDdqajExayk7Cn0KLl8xZGtvYzNlMiB7CiAgcG9zaXRpb246IGFic29sdXRlOwogIHJpZ2h0OiB2YXIoLS1zcGFjaW5nLXhzX18xYmw3amoxMWspOwogIHRvcDogdmFyKC0tc3BhY2luZy14c19fMWJsN2pqMTFrKTsKfQouXzFka29jM2U1IHsKICAtLV8xZGtvYzNlMzogdmFyKC0tY29sb3Itc3VyZmFjZVRlcnRpYXJ5X18xYmw3amoxaik7CiAgLS1fMWRrb2MzZTQ6IHZhcigtLWNvbG9yLWJvcmRlcl9fMWJsN2pqMTEwKTsKICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1fMWRrb2MzZTMpOwogIGJvcmRlcjogMnB4IGRhc2hlZCB2YXIoLS1fMWRrb2MzZTQpOwogIGJvcmRlci1yYWRpdXM6IHZhcigtLWJvcmRlclJhZGl1cy1jb250cm9sX18xYmw3amoxMWcpOwogIGFzcGVjdC1yYXRpbzogMTYgLyA5Owp9Ci5fMWRrb2MzZTYgewogIC0tXzFka29jM2UzOiByZ2IoMjMxIDI0NSAyNTUpOwogIC0tXzFka29jM2U0OiByZ2IoMTYgMTUzIDI1Mik7Cn0KLl8xZGtvYzNlNS5fMWRrb2MzZTYgKiB7CiAgcG9pbnRlci1ldmVudHM6IG5vbmU7Cn0KLl8xZGtvYzNlNyB7CiAgYXNwZWN0LXJhdGlvOiAxNiAvIDk7Cn0KLl8xZGtvYzNlOCB7CiAgY29sb3I6IHJnYig3IDExMCAyMDgpOwp9Ci5fMWRrb2MzZTkgewogIGRpc3BsYXk6IG5vbmU7Cn0KLl8xZGtvYzNlYSB7CiAgbWFyZ2luLXJpZ2h0OiB2YXIoLS1zcGFjaW5nLXh4c19fMWJsN2pqMTFqKTsKfQouXzFka29jM2ViIHsKICBwb3NpdGlvbjogcmVsYXRpdmU7CiAgd2lkdGg6IDMwMHB4Owp9Ci5fMWRrb2MzZWMgewogIHdpZHRoOiAxNTBweDsKfQ==\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var bucket = '_1dkoc3e0';
export var close = '_1dkoc3e1';
export var imageLibraryButton = '_1dkoc3e2';
export var dropzone = _7a468({defaultClassName:'_1dkoc3e5',variantClassNames:{canDrop:{true:'_1dkoc3e6'}},defaultVariants:{},compoundVariants:[]});
export var uploadSpinnerContainer = '_1dkoc3e7';
export var dropPromptText = '_1dkoc3e8';
export var fileInput = '_1dkoc3e9';
export var embedButton = '_1dkoc3ea';
export var standardBucketDropContainer = '_1dkoc3eb';
export var miniBucketContainer = '_1dkoc3ec';
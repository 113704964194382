/*
  Common functions for setting the background of a splash block
 */

(function () {
  var BackgroundSetter;

  module.exports = BackgroundSetter = (function () {
    function BackgroundSetter(block, $timeout, telemetry, onAddedContent) {
      var setResourceUrl, setSplash, setType;
      this.setImage = function (imageUrl, source) {
        if (imageUrl != null) {
          telemetry.trackOnly("Splash Block Background Image Added", {
            image_source: source,
          });
        }
        return setSplash("image", imageUrl);
      };
      this.setEmbed = function (embedUrl) {
        return setSplash("embed", embedUrl);
      };
      this.setVideo = function (videoFileUrl) {
        telemetry.trackOnly("Splash Block Background Video Added", {});
        if (videoFileUrl.match(/^https?:\/\/((www\.)?youtube\.com|youtu\.be)/)) {
          return this.setEmbed(videoFileUrl);
        }
        setSplash("video", videoFileUrl);
        return $timeout(function () {
          return $("#" + block.$id + " video").attr("src", videoFileUrl);
        }, 250);
      };
      setType = (function (_this) {
        return function (type) {
          return (block.resourceType = type);
        };
      })(this);
      setResourceUrl = (function (_this) {
        return function (url) {
          return (block.resourceUrl = url);
        };
      })(this);
      setSplash = function (type, resourceUrl) {
        setType(type);
        setResourceUrl(resourceUrl);
        return typeof onAddedContent === "function" ? onAddedContent() : void 0;
      };
    }

    return BackgroundSetter;
  })();
}.call(this));

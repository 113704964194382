"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var BlockTypes_1 = require("@CommonFrontendBackend/BlockModels/BlockTypes");
var Types_1 = require("DataStores/Account/Types");
var StyleButtonStore = (function () {
    function StyleButtonStore(stylePermissionStore, blockType) {
        this.stylePermissionStore = stylePermissionStore;
        this.blockType = blockType;
    }
    Object.defineProperty(StyleButtonStore.prototype, "shouldShowBlockBackgroundStyleButton", {
        get: function () {
            if (![BlockTypes_1.BlockType.Text, BlockTypes_1.BlockType.Quote, BlockTypes_1.BlockType.Accept].includes(this.blockType)) {
                return false;
            }
            if (!this.stylePermissionStore.isStylePermissionEnabled) {
                return true;
            }
            return this.stylePermissionStore.allowStyle(Types_1.StyleAccessType.Block);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StyleButtonStore.prototype, "blockSupportsMediaBackgrounds", {
        get: function () {
            return this.blockType === BlockTypes_1.BlockType.Text;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], StyleButtonStore.prototype, "shouldShowBlockBackgroundStyleButton", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], StyleButtonStore.prototype, "blockSupportsMediaBackgrounds", null);
    return StyleButtonStore;
}());
exports.default = StyleButtonStore;

import "Application/Onboarding/CensusCollector/ChooseLandingPoint/Buttons.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Onboarding/CensusCollector/ChooseLandingPoint/Buttons.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81WwXLbIBC95yuYnOQDiZBlJyaf0UvTSwaJlYyLQbPCdtJO/70joJIly5m005n25DW8Zd++XS3cnZZr5vKUfL8hRLVWC6es4dGEp5sfN3cBwjyktNoiJ1gXyWZNNhlhD2xxhso8aq8MPSnptpxkj2nz+hTXtqDqreMky/3i4MV5AZVFiDGMA+M4ub3tHBvbqkBKFK3Vh44VIc42nKSdVVjn7D7+0VC5aGKI5e1vVBkJr5xQ5rkIrJXhhAVqhSi/1mgPRlK1FzVwchSYUFqjkAqMowUKI19eWKEfdjvGNouJF0IDwnFibDQDL5SAtDvj0HLCYjCHwvQJaR1DyQN65el+CHNcxE0QrTI1rUTr7MF90vakzADDkf58a4+AIz1/ZUuXI9GX01LlaSzVlPl67Dc6fIrdjLC5x0jVNlq8cVKjkl2AGJA95iP0yqM7EBUIghNG7okvmNCqNrQFXXFSgnGA3eru0DpVvV2se4kri3sezK6TvyQsbV7PpVrPc5sLfy3QPK2PhX+4kmwPePQA24hSubfYx+fNE3f+TgO9y2QzZnK+Ja6W7Jxqr8iUrB5YnH6H7JzEn5N1J/Gw8Jywldd8XCgw8npN+8QKn9h8nDyfj9M7l/9GlY+34/NFO8p38s0udJ04w0W+9zPfTRQ+jCMaxzPL4vUQVv1YZ3EOzfR+4NaWQkOS3q0WH5iof6Zmn1s1FQatEw6STSqhXkQqbPF07Qupr2gz05JRg3CHLdcjYX7dcpTlUZwjYKXtiZOjalWh4b9RbHsxLmaojF4NajyKKw0+xe6XSoVQBqpoT4NyysG+DVjq9etP2/nThmcDQveqOcJl89FVOityFp4nPwGYY7fKHQkAAA==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var root = 'w361t40';
export var headingTextOverride = 'w361t41';
export var card = _7a468({defaultClassName:'w361t42',variantClassNames:{wide:{true:'w361t43'}},defaultVariants:{},compoundVariants:[]});
export var svgContainer = 'w361t44';
export var uploadSvgPage = 'w361t45';
export var uploadSvgLinesContainer = 'w361t46';
export var uploadSvgLines = 'w361t47';
export var uploadSvgLinesHover = _7a468({defaultClassName:'w361t48',variantClassNames:{hovering:{true:'w361t49'}},defaultVariants:{},compoundVariants:[]});
export var uploadSvgCloudRight = _7a468({defaultClassName:'w361t4a',variantClassNames:{hovering:{true:'w361t4b'}},defaultVariants:{},compoundVariants:[]});
export var uploadSvgCloudLeft = _7a468({defaultClassName:'w361t4c',variantClassNames:{hovering:{true:'w361t4d'}},defaultVariants:{},compoundVariants:[]});
export var uploadSvgStar1 = _7a468({defaultClassName:'w361t4e',variantClassNames:{hovering:{true:'w361t4f'}},defaultVariants:{},compoundVariants:[]});
export var uploadSvgStar2 = _7a468({defaultClassName:'w361t4g',variantClassNames:{hovering:{true:'w361t4h'}},defaultVariants:{},compoundVariants:[]});
export var betaTagContainer = 'w361t4i';
export var betaTag = 'w361t4j';
"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchBar = void 0;
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var SidebarExplore_1 = require("./SidebarExplore");
var inversify_react_1 = require("inversify-react");
var SearchBar = function (_a) {
    var componentRef = _a.componentRef, setSearchTerm = _a.setSearchTerm, tab = _a.tab;
    var telemetry = (0, inversify_react_1.useInjection)("telemetry");
    var _b = __read((0, react_1.useState)(""), 2), value = _b[0], setValue = _b[1];
    var handleSearch = function (str, tab) {
        setSearchTerm(str);
        if (str) {
            telemetry.debouncedTrack("Sidebar Assets Searched", {
                source_tab: "explore",
                source_secondary_tab: tab,
            }, {}, 500);
        }
    };
    var debouncedSearch = (0, react_1.useCallback)((0, lodash_1.debounce)(handleSearch, 500), []);
    return (react_1.default.createElement(kaleidoscope_1.Stack, { paddingLeft: "l", paddingRight: "l", paddingBottom: "s", ref: componentRef },
        react_1.default.createElement(kaleidoscope_1.TextInput, { label: "Search explore content", labelHidden: true, placeholder: tab === SidebarExplore_1.SidebarExploreTab.All ? "What do you want to create?" : "Search ".concat(tab), value: value, onChange: function (value) {
                setValue(value);
                debouncedSearch(value, tab);
            }, startElement: react_1.default.createElement(icons_1.Search, null), endElement: !!value ? (react_1.default.createElement(kaleidoscope_1.IconButton, { icon: react_1.default.createElement(icons_1.Close, null), isIconOnly: true, "aria-label": "clear search", size: kaleidoscope_1.ButtonSize.XSmall, onClick: function () {
                    setValue("");
                    handleSearch("", tab);
                } })) : undefined })));
};
exports.SearchBar = SearchBar;

(function () {
  module.exports = function (
    createUi,
    item,
    itemService,
    currency,
    closeActionsPanel,
    telemetry,
    telemetryDebounceInterval,
  ) {
    var base, base1, configProperties, debouncedDiscountChange, interactiveOptions;
    debouncedDiscountChange = _.debounce(function () {
      return telemetry.trackOnly("Quote Block Line Item Discounted");
    }, telemetryDebounceInterval);
    if (item.interactive == null) {
      item.interactive = {};
    }
    if ((base = item.interactive).type == null) {
      base.type = "none";
    }
    if ((base1 = item.interactive).settings == null) {
      base1.settings = {};
    }
    if (item.type === "text") {
      configProperties = {
        discount: false,
        taxExempt: false,
        unitName: false,
        interactive: true,
      };
      interactiveOptions = [
        {
          label: "None",
          value: "none",
        },
        {
          label: "Optional (i.e. yes / no)",
          value: "optional",
        },
      ];
    } else {
      configProperties = {
        discount: true,
        taxExempt: true,
        unitName: true,
        interactive: true,
      };
      interactiveOptions = [
        {
          label: "None",
          value: "none",
        },
        {
          label: "Optional (i.e. yes / no)",
          value: "optional",
        },
        {
          label: "Can Choose Quantity",
          value: "quantity",
        },
      ];
    }
    return (this.ui = createUi(require("./Actions.html"), {
      item: item,
      configProperties: configProperties,
      interactiveOptions: interactiveOptions,
      currency: currency,
      close: closeActionsPanel,
      moveItemUp: function () {
        return itemService.moveItemUp(item);
      },
      moveItemDown: function () {
        return itemService.moveItemDown(item);
      },
      showInteractivityOptions: function () {
        return item.interactive.type !== "none";
      },
      selectedMode: function (item, type) {
        var base2;
        if (type === "optional") {
          if ((base2 = item.interactive).settings == null) {
            base2.settings = {};
          }
          return (item.interactive.settings.enabled = true);
        }
      },
      ensureBounds: itemService.ensureBounds,
      changeTaxExemption: (function (_this) {
        return function (exemption) {
          if (_this.ui.item.taxExempt) {
            return telemetry.trackOnly("Quote Block Line Item Tax Removed");
          } else {
            return telemetry.trackOnly("Quote Block Line Item Tax Applied", {});
          }
        };
      })(this),
      changeDiscount: (function (_this) {
        return function () {
          if (_this.ui.item.discount != null && _this.ui.item.discount !== 0) {
            return debouncedDiscountChange();
          }
        };
      })(this),
    }));
  };
}.call(this));

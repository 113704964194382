"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FeatureFlag_1 = require("@CommonFrontendBackend/FeatureFlag");
function default_1(make, viewState, StyleButton, BlockControls, usingFeature) {
    var styleButton = make(StyleButton);
    var blockControls = make(BlockControls);
    var superpowers = [];
    if ((0, FeatureFlag_1.isUsingTCDC)(usingFeature)) {
        superpowers.push({
            name: "Style library",
            ctrl: styleButton,
            selectable: false,
            select: styleButton.onClick,
            deselect: styleButton.onClose,
            overflow: true,
            visible: function () {
                return viewState.displayMode;
            },
            doNotWrapControl: true,
        });
    }
    else {
        superpowers.push({
            name: "Style library",
            ctrl: styleButton,
            selectable: false,
            select: styleButton.onClick,
            overflow: true,
            doNotWrapControl: true,
        });
    }
    superpowers.push({
        name: "Block Controls",
        ctrl: blockControls,
        doNotWrapControl: true,
        visibleOnViewOnlyBlocks: true,
    });
    return {
        settings: {
            useItems: ["foldable", "navigationMenu"],
            superpowers: superpowers,
        },
    };
}
exports.default = default_1;

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyleableAction = void 0;
var BehaviourBuilder_1 = require("../BehaviourBuilder");
var EditorTypes_1 = require("../../EditorTypes");
var StyleableActions = __importStar(require("./StyleableActions"));
var TelemetryEventName_1 = require("@CommonFrontendBackend/Telemetry/TelemetryEventName");
var StyleableAction;
(function (StyleableAction) {
    StyleableAction["ClearElementStyles"] = "clearElementStyles";
    StyleableAction["UpdateElementStyles"] = "updateElementStyles";
    StyleableAction["PropagateElementStyle"] = "propagateElementStyle";
    StyleableAction["RemoveElementStyle"] = "removeElementStyle";
    StyleableAction["CopyElementStyle"] = "copyElementStyle";
    StyleableAction["PasteElementStyle"] = "pasteElementStyle";
})(StyleableAction || (exports.StyleableAction = StyleableAction = {}));
function copyStyleToClipboard(snapshot) {
    return __awaiter(this, void 0, void 0, function () {
        var mimeType, clipboardItemWithSupports, data, item;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    mimeType = "web application/x-qwilr-widget-style";
                    clipboardItemWithSupports = ClipboardItem;
                    if (!clipboardItemWithSupports.supports || !clipboardItemWithSupports.supports(mimeType)) {
                        mimeType = "text/plain";
                    }
                    data = JSON.stringify(snapshot);
                    item = new ClipboardItem((_a = {}, _a[mimeType] = data, _a));
                    return [4, navigator.clipboard.write([item])];
                case 1:
                    _b.sent();
                    return [2];
            }
        });
    });
}
exports.default = (function (di) {
    var blockStore = di.get("blockStore");
    var telemetry = di.get("telemetry");
    return [
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("clearElementStyles")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "styleable", action: StyleableAction.ClearElementStyles })
            .doAction(function (event, editor) {
            StyleableActions.clearElementStyles(editor, event.data.path);
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("updateElementStyles")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "styleable", action: StyleableAction.UpdateElementStyles })
            .doAction(function (event, editor) {
            var _a = event.data, path = _a.path, style = _a.style;
            StyleableActions.updateWidgetStyles(editor, path, style);
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("propagateElementStyle")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "styleable", action: StyleableAction.PropagateElementStyle })
            .doAction(function (event, editor) {
            StyleableActions.clearWidgetOverrides(editor, event.data.type);
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("removeElementStyle")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "styleable", action: StyleableAction.RemoveElementStyle })
            .doAction(function (event, editor) {
            StyleableActions.removeElementStyle(editor, event.data.path, event.data.stylePath);
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("copyElementStyle")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "styleable", action: StyleableAction.CopyElementStyle })
            .doAction(function (event, editor) {
            var _a;
            var snapshot = StyleableActions.copyElementStyle(editor, event.data.path, event.data.colorProvider, blockStore);
            copyStyleToClipboard(snapshot);
            telemetry.trackOnly(TelemetryEventName_1.TelemetryEventName.ElementStyleCopied, (_a = {},
                _a["".concat(TelemetryEventName_1.TelemetryEventName.ElementStyleCopied, "_widget_type")] = snapshot.widgetType,
                _a));
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("pasteElementStyle")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "styleable", action: StyleableAction.PasteElementStyle })
            .doAction(function (event, editor) {
            StyleableActions.getPastableStyle()
                .then(function (payload) {
                if (!payload)
                    return;
                return StyleableActions.pasteElementStyle(editor, event.data.path, payload);
            })
                .then(function (targetWidgetType) {
                var _a;
                telemetry.trackOnly(TelemetryEventName_1.TelemetryEventName.ElementStylePasted, (_a = {},
                    _a["".concat(TelemetryEventName_1.TelemetryEventName.ElementStylePasted, "_widget_type")] = targetWidgetType,
                    _a));
            });
            return true;
        }),
    ];
});

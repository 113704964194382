"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var ColorUtils_1 = require("Common/Utils/ColorUtils/ColorUtils");
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
var StyleServiceV2_1 = require("@CommonFrontendBackend/StyleServiceV2/StyleServiceV2");
exports.default = (function (style, blockType, clonedFromId, savedStyles) {
    var clonedFrom = savedStyles.find(function (savedStyle) {
        return savedStyle._id === clonedFromId;
    });
    try {
        if (clonedFrom) {
            return __assign(__assign(__assign(__assign({}, getCombinedPayload(style, clonedFrom, blockType)), (blockType === "Splash" ? getSplashPayload(style, clonedFrom) : {})), (blockType === "Accept" ? getAcceptPayload(style, clonedFrom) : {})), (blockType === "QuoteV2" ? getQuotePayload(style, clonedFrom) : {}));
        }
        else {
            return {};
        }
    }
    catch (e) {
        return {};
    }
});
var getCombinedPayload = function (style, clonedFrom, blockType) {
    var payload = {};
    if (blockType !== "Splash" && style.background.themeIndex !== clonedFrom.background.themeIndex) {
        payload.block_styles_saved_block_color = (0, ColorUtils_1.getTelemetryColor)(style.background);
    }
    if (style.blockWidth !== clonedFrom.blockWidth) {
        if (typeof style.blockWidth === "object") {
            payload["block_styles_saved_block_width_custom"] = style.blockWidth.width;
        }
        else {
            payload["block_styles_saved_block_width"] = style.blockWidth;
        }
    }
    if (style.blockSpacing !== clonedFrom.blockSpacing) {
        if (typeof style.blockSpacing === "object") {
            payload["block_styles_saved_block_spacing_before"] = style.blockSpacing.before;
            payload["block_styles_saved_block_spacing_after"] = style.blockSpacing.after;
        }
        else {
            payload["block_styles_saved_block_spacing"] = style.blockSpacing;
        }
    }
    var combinedGroupSize = (0, StyleServiceV2_1.getCombinedGroupedSize)(style);
    var hasCombinedGroupSizeChanged = combinedGroupSize !== (0, StyleServiceV2_1.getCombinedGroupedSize)(clonedFrom);
    if (hasCombinedGroupSizeChanged) {
        payload.block_styles_saved_text_font_size = combinedGroupSize;
    }
    var combinedColor = (0, StyleServiceV2_1.getCombinedColor)(style);
    var hasCombinedColorChanged = combinedColor !== (0, StyleServiceV2_1.getCombinedColor)(clonedFrom);
    if (hasCombinedColorChanged) {
        payload.block_styles_saved_text_color = combinedColor;
    }
    var combinedAlignment = (0, StyleServiceV2_1.getCombinedAlignment)(style);
    var hasCombinedAlignmentChanged = combinedAlignment !== (0, StyleServiceV2_1.getCombinedAlignment)(clonedFrom);
    if (hasCombinedAlignmentChanged) {
        payload.block_styles_saved_text_alignment = combinedAlignment;
    }
    elementTypes.forEach(function (type) {
        if (!hasCombinedGroupSizeChanged && style[type].size.abs !== clonedFrom[type].size.abs) {
            payload["block_styles_saved_".concat(type, "_text_size")] = style[type].size.abs;
        }
        if (!hasCombinedColorChanged && style[type].color.themeIndex !== clonedFrom[type].color.themeIndex) {
            payload["block_styles_saved_".concat(type, "_text_color")] = (0, ColorUtils_1.getTelemetryColor)(style[type].color);
        }
        if (!hasCombinedAlignmentChanged && style[type].alignment !== clonedFrom[type].alignment) {
            payload["block_styles_saved_".concat(type, "_text_alignment")] = style[type].alignment;
        }
    });
    if (style.animation && style.animation.animationOption) {
        var oldAnimation = clonedFrom.animation || {};
        var newAnimation = style.animation;
        if (newAnimation.animationOption !== oldAnimation.animationOption) {
            payload.block_styles_saved_animation_option = newAnimation.animationOption;
        }
        if (newAnimation.animationType !== oldAnimation.animationType) {
            payload.block_styles_saved_animation_type = newAnimation.animationType;
        }
        if (newAnimation.direction !== oldAnimation.direction) {
            payload.block_styles_saved_animation_direction = newAnimation.direction;
        }
        if (newAnimation.speed !== oldAnimation.speed) {
            payload.block_styles_saved_animation_speed = newAnimation.speed;
        }
    }
    return payload;
};
var getSplashPayload = function (style, clonedFrom) {
    var payload = {};
    if (style.background.themeIndex !== clonedFrom.background.themeIndex) {
        payload.block_styles_saved_tint_color = (0, ColorUtils_1.getTelemetryColor)(style.background);
    }
    if (style.tintBlendMode !== clonedFrom.tintBlendMode) {
        payload.block_styles_saved_tint_type = style.tintBlendMode === "normal" ? "normal" : "blend";
    }
    if (style.background.opacity !== clonedFrom.background.opacity) {
        payload.block_styles_saved_opacity = 100 * style.background.opacity;
    }
    if (style.backgroundCard) {
        if (style.backgroundCard.on !== clonedFrom.backgroundCard.on) {
            payload.block_styles_saved_background_card = style.backgroundCard.on;
        }
        if (style.backgroundCard.on) {
            if (style.backgroundCard.color.themeIndex !== clonedFrom.backgroundCard.themeIndex) {
                payload.block_styles_saved_background_card_color = (0, ColorUtils_1.getTelemetryColor)(style.backgroundCard.color);
            }
            if (style.backgroundCard.color.opacity !== clonedFrom.backgroundCard.opacity) {
                payload.block_styles_saved_background_card_opacity = 100 * style.backgroundCard.color.opacity;
            }
        }
    }
    return payload;
};
var getAcceptPayload = function (style, clonedFrom) {
    var payload = {};
    if (style.acceptButton && clonedFrom.acceptButton) {
        if (style.acceptButton.color.themeIndex !== clonedFrom.acceptButton.color.themeIndex) {
            payload.block_styles_saved_accept_button_color = (0, ColorUtils_1.getTelemetryColor)(style.acceptButton.color);
        }
        if (style.acceptButton.alignment !== clonedFrom.acceptButton.alignment) {
            payload.block_styles_saved_accept_button_alignment = style.acceptButton.alignment;
        }
    }
    return payload;
};
var getQuotePayload = function (style, clonedFrom) {
    var payload = {};
    if (style.headerSection && clonedFrom.headerSection) {
        if (style.headerSection.on !== clonedFrom.headerSection.on) {
            payload.block_styles_saved_quote_section_card = style.headerSection.on;
        }
        if (style.headerSection.color.themeIndex !== clonedFrom.headerSection.color.themeIndex) {
            payload.block_styles_saved_quote_section_card_color = (0, ColorUtils_1.getTelemetryColor)(style.headerSection.color);
        }
        if (style.uiHighlightColor.themeIndex !== clonedFrom.uiHighlightColor.themeIndex) {
            payload.block_styles_saved_quote_highlight_color = (0, ColorUtils_1.getTelemetryColor)(style.uiHighlightColor);
        }
    }
    return payload;
};
var elementTypes = [commonTypes_1.TextElementType.H1, commonTypes_1.TextElementType.H2, commonTypes_1.TextElementType.P];

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Permissions = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var styles = __importStar(require("./PaymentsSettings.css"));
var icons_1 = require("@qwilr/kaleidoscope/icons");
var dynamic_1 = require("@vanilla-extract/dynamic");
var tokens_1 = require("@qwilr/kaleidoscope/tokens");
var usePaymentPermissions_1 = require("./usePaymentPermissions");
var Permissions = function () {
    var _a;
    var _b = (0, usePaymentPermissions_1.usePaymentPermission)(), permissions = _b.permissions, updater = _b.updater;
    var _c = __read((0, react_1.useState)(false), 2), expanded = _c[0], setExpanded = _c[1];
    return (react_1.default.createElement(kaleidoscope_1.Card, null,
        react_1.default.createElement(kaleidoscope_1.ButtonCard, { "aria-expanded": expanded, onClick: function () { return setExpanded(!expanded); }, "aria-controls": "payment-permissions", className: styles.permissionsWrapper },
            react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", justify: "spaceBetween", padding: "l" },
                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: "s", align: "center", className: styles.fullWidth },
                    react_1.default.createElement("div", { className: styles.permissionsIconStyle },
                        react_1.default.createElement(icons_1.Team, { style: (0, dynamic_1.assignInlineVars)((_a = {},
                                _a[kaleidoscope_1.iconVars.color] = tokens_1.palette.purple600,
                                _a)) })),
                    react_1.default.createElement(kaleidoscope_1.Stack, null,
                        react_1.default.createElement(kaleidoscope_1.Text, { size: "m", strong: true }, "Permissions"),
                        react_1.default.createElement(kaleidoscope_1.Text, { secondary: true, size: "xs" }, "Choose which defaults can be modified on pages by your team."))),
                expanded ? react_1.default.createElement(icons_1.ChevronDown, null) : react_1.default.createElement(icons_1.ChevronRight, null))),
        react_1.default.createElement(kaleidoscope_1.Expandable, { element: kaleidoscope_1.Stack, expanded: expanded, timeout: kaleidoscope_1.AnimationDuration.Long }, function (_a) {
            var expandableElementRef = _a.expandableElementRef;
            return (react_1.default.createElement(kaleidoscope_1.Stack, { padding: "l", direction: "vertical", justify: "center", ref: expandableElementRef },
                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "vertical", justify: "center" },
                    react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsRow(), direction: "horizontal", align: "center", justify: "spaceBetween", gap: "l" },
                        react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsDescription },
                            react_1.default.createElement(kaleidoscope_1.Text, { element: "div", size: "s" }, "Allow creators to edit payments on pages"),
                            react_1.default.createElement(kaleidoscope_1.Text, { element: "div", size: "xs", secondary: true }, "This allows creators to turn payments on and off for their pages.")),
                        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", justify: "end" },
                            react_1.default.createElement(kaleidoscope_1.Toggle, { labelHidden: true, disabled: false, key: "key", label: "label", value: permissions.enable, onChange: function (val) { return updater({ enable: val }); } }))),
                    react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsRow(), direction: "horizontal", align: "center", justify: "spaceBetween", gap: "l" },
                        react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsDescription },
                            react_1.default.createElement(kaleidoscope_1.Text, { element: "div", size: "s" }, "Allow creators to manage when the payment is required"),
                            react_1.default.createElement(kaleidoscope_1.Text, { element: "div", size: "xs", secondary: true }, "This allows creators to make payments required or optional for buyers to accept the page.")),
                        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", justify: "end" },
                            react_1.default.createElement(kaleidoscope_1.Toggle, { labelHidden: true, disabled: false, key: "key", label: "label", value: permissions.requireOnAccept, onChange: function (val) { return updater({ requireOnAccept: val }); } }))),
                    react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsRow(), direction: "horizontal", align: "center", justify: "spaceBetween", gap: "l" },
                        react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsDescription },
                            react_1.default.createElement(kaleidoscope_1.Text, { element: "div", size: "s" }, "Allow creators to manage part payments"),
                            react_1.default.createElement(kaleidoscope_1.Text, { element: "div", size: "xs", secondary: true }, "This allows creators to turn payments on and off partial payments and choose default values.")),
                        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", justify: "end" },
                            react_1.default.createElement(kaleidoscope_1.Toggle, { labelHidden: true, disabled: false, key: "key", label: "label", value: permissions.partialPayments, onChange: function (val) { return updater({ partialPayments: val }); } }))),
                    react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsRow(), direction: "horizontal", align: "center", justify: "spaceBetween", gap: "l" },
                        react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsDescription },
                            react_1.default.createElement(kaleidoscope_1.Text, { element: "div", size: "s" }, "Allow creators to manage the payment alert on pages"),
                            react_1.default.createElement(kaleidoscope_1.Text, { element: "div", size: "xs", secondary: true }, "This allows creators to turn on and off a page alert indicating that payment is available when buyers view the page.")),
                        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", justify: "end" },
                            react_1.default.createElement(kaleidoscope_1.Toggle, { labelHidden: true, disabled: false, key: "key", label: "label", value: permissions.alert, onChange: function (val) { return updater({ alert: val }); } }))))));
        })));
};
exports.Permissions = Permissions;

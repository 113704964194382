import "Application/Project/QwilrPay/QwilrPay.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrPay/QwilrPay.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VWUW/aMBB+76/wNFUq0oKSQKC4j13zPK0/oHLiS2Jw7MgxkHbqf5+cjHBAoHTTHnjw5e67z3ef7xhva54swCe/bghJtOFgKAmqhtRaCk4eequXaqkNJRtm7rzu4HVfXl6CRM6XyyDwR8jfMC7WNSXTqnFWJkWuPGGhrClJQVkwzlwxzoXKKQmd2/vNuOMTdHxYusqNXis+lL1em4yl8AypVpyZ156HuEBDb8BkUm8pKQTnoFDOsM25FdwWlET+AZ9J+22AhIXGfhc1SyTwngAfodApGa+kJ1kCsgXJtLLeFkReWEoC30euEWYQ+P6tY1zsXdvzaVFMntwtZmQRkmAe4NQRTSDTBs4U8zCOfBFlpY1lyiKIWcdZSNn5h1FE/vxwpnnnJqHxcuOqG6DmehIyuytbXbHU2eq9bCRGum+RellcDDppcufdGX+2Ni/Vyhot94E5zrZos5Ws8XZVv/c7oXyM/Ed/e+QCI7OhXl57rZKZXCjP6urYtWmQ8xI7J9paXX7o3xNMLkn6hxElflIzHJke6YkSKRQw4+WuWqDsXTCNOOTfyNc4jCfxEwkW40V4686PcRBPySwcT+ftOY4XTzEJ/Ml4Mr0dYdEMXh71sTrwPnf7k4B/7Sv/r5c/YjfDWkRz+fNjuecP59ouUq36mAqHZJeUcjp+o9HZed9D5pcgH9naCkTmHpMprt8MxzjXjoxBAZx9vPiBrTBTcanSx9zWOHKJZ8cHo2K3Hj43KKNuHulkCan1MmEpSd1qHFTbFTt/A8aKlEmvbTslpeBcAppOZojl+eG0aivARV1J9kqJUO6Roe9oldbiDXbAzvAs3qCV0QF8uMXwZRuu19bBUqK0Anzz/bnx6oJxt9B2tl4Fh38QSlq4+v0d7vDqLQkXm8M6uAX7sFu0XBhInYgoMXrrzMt1bUX22u48UJYSV2jwErBbcH91jvbR+28VF3wz/QkAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var partPaymentInputGroup = 'wsdb9e0';
export var partPaymentTypeToggle = 'wsdb9e1';
export var toggleButton = 'wsdb9e2';
export var disabled = 'wsdb9e3';
export var paymentOptionToggle = 'wsdb9e4';
export var popoverIconButton = 'wsdb9e5';
export var qwilrPayIcon = 'wsdb9e6';
export var paymentTypeInput = 'wsdb9e7';
export var previewTooltips = 'wsdb9e8';
export var tooltipImage = 'wsdb9e9';
export var qPayVideoPlaceholder = 'wsdb9ea';
export var activateHeading = 'wsdb9eb';
export var integrationPrompt = 'wsdb9ec';
export var previewWrapper = 'wsdb9ed';
export var previewIcon = 'wsdb9ee';
export var previewTextWrapper = 'wsdb9ef';
export var onboarding = 'wsdb9eg';
export var warningContainer = 'wsdb9eh';
export var onboardingIcon = 'wsdb9ei';
export var currencyFlag = 'wsdb9ej';
export var currencyInfo = 'wsdb9ek';
export var currencyInfoText = 'wsdb9el';
export var advancedOptionsButton = 'wsdb9em';
"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mountSelectionBox = exports.SelectionBox = void 0;
var react_1 = __importStar(require("react"));
var react_drag_to_select_1 = require("@air/react-drag-to-select");
var inversify_react_1 = require("inversify-react");
var styles = __importStar(require("./SelectionBox.css"));
var slate_react_1 = require("slate-react");
var slate_1 = require("slate");
var SelectionBox = function (_a) {
    var container = _a.container;
    var selectionBoxStore = (0, inversify_react_1.useInjection)("selectionBoxStore");
    var editorBlockIdStore = (0, inversify_react_1.useInjection)("editorBlockIdStore");
    var _b = __read((0, react_1.useState)(false), 2), inDrag = _b[0], setInDrag = _b[1];
    var DragSelection = (0, react_drag_to_select_1.useSelectionContainer)({
        shouldStartSelecting: function (target) {
            if (target === null)
                return false;
            if (!(target instanceof Element))
                return false;
            var inBannedElement = false;
            var curr = target;
            while (curr && curr !== container) {
                if (curr.classList.contains("widget-toolbar-host")) {
                    inBannedElement = true;
                    break;
                }
                if (curr.tagName === "INPUT" || curr.tagName === "TEXTAREA" || curr.tagName === "BUTTON") {
                    inBannedElement = true;
                    break;
                }
                curr = curr.parentElement;
            }
            if (inBannedElement)
                return false;
            var inBounds = container.contains(target);
            var editor = editorBlockIdStore.getAllEditors().find(function (e) { return slate_react_1.ReactEditor.hasDOMNode(e, target); });
            var inInteractiveElement = editor && !slate_1.Editor.isEditor(slate_react_1.ReactEditor.toSlateNode(editor, target));
            var inBlockMenu = Boolean(target.closest(".block-menu"));
            return inBounds && !inInteractiveElement && !inBlockMenu;
        },
        onSelectionChange: function (_a) {
            var width = _a.width, height = _a.height, top = _a.top, left = _a.left;
            selectionBoxStore.notifyIntersecting(new DOMRect(left, top, width, height));
        },
        onSelectionStart: function () {
            var e_1, _a;
            try {
                for (var _b = __values(editorBlockIdStore.getAllEditors()), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var editor = _c.value;
                    slate_react_1.ReactEditor.deselect(editor);
                    slate_react_1.ReactEditor.blur(editor);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            container.style.pointerEvents = "none";
            setInDrag(true);
        },
        onSelectionEnd: function () {
            container.style.pointerEvents = "all";
            setInDrag(false);
        },
        selectionProps: {
            className: styles.selectionBoxStyles,
        },
    }).DragSelection;
    return (react_1.default.createElement("div", { className: styles.wrapperStyles({ shown: inDrag }) },
        react_1.default.createElement(DragSelection, null)));
};
exports.SelectionBox = SelectionBox;
function mountSelectionBox(mountReactComponent, container) {
    return mountReactComponent(react_1.default.createElement(exports.SelectionBox, { container: container }));
}
exports.mountSelectionBox = mountSelectionBox;

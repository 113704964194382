"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpandableSection = void 0;
var react_1 = __importStar(require("react"));
var icons_1 = require("@qwilr/kaleidoscope/icons");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var styles = __importStar(require("./ExpandableSection.css"));
var ExpandableSection = function (_a) {
    var icon = _a.icon, displayName = _a.displayName, children = _a.children;
    var _b = __read((0, react_1.useState)(false), 2), expanded = _b[0], setExpanded = _b[1];
    return (react_1.default.createElement("div", null,
        react_1.default.createElement(kaleidoscope_1.Separator, null),
        react_1.default.createElement(kaleidoscope_1.Stack, { padding: "m" },
            react_1.default.createElement(kaleidoscope_1.Button, { className: styles.expandButton, onClick: function () { return setExpanded(!expanded); }, type: kaleidoscope_1.ButtonType.Tertiary, width: "full" },
                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: "s" },
                    icon,
                    react_1.default.createElement(kaleidoscope_1.Text, { strong: true, size: "m" }, displayName)),
                expanded ? react_1.default.createElement(icons_1.ChevronUp, null) : react_1.default.createElement(icons_1.ChevronDown, null)),
            react_1.default.createElement(kaleidoscope_1.Expandable, { expanded: expanded }, function (_a) {
                var expandableElementRef = _a.expandableElementRef;
                return react_1.default.createElement("div", { ref: expandableElementRef }, children);
            }))));
};
exports.ExpandableSection = ExpandableSection;

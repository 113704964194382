"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EditorTypes_1 = require("./../EditorTypes");
var BehaviourBuilder_1 = require("./BehaviourBuilder");
var ImageActions_1 = require("./ImageActions");
var WidgetActions_1 = require("./WidgetActions");
var DragStore_1 = require("Project/Stores/DragStore/DragStore");
var TelemetryEnums_1 = require("Telemetry/TelemetryEnums");
var Types_1 = require("@CommonFrontendBackend/AssetLibrary/Types");
var DragActions_1 = require("./Dragging/DragActions");
exports.default = (function (di) {
    var dragStore = di.get("dragStore");
    var dropLocationStore = di.get("dropLocationStore");
    var mediaUsageReporter = di.get("mediaUsageReporter");
    var editorTelemetry = di.get("editorTelemetry");
    var blockStore = di.get("blockStore");
    var contentSidebarTelemetry = di.get("contentSidebarTelemetry");
    return {
        onEnter: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("imageOnEnter")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Enter)
            .selectionIsCollapsed()
            .inBlockType("image")
            .doAction(function (event, editor) {
            event.preventDefault();
            var didOpenGallery = (0, ImageActions_1.maybeOpenImageGallery)(editor, di);
            if (!didOpenGallery) {
                (0, WidgetActions_1.newParagraphBelowWidget)(editor);
            }
            return true;
        }),
        onLeftKeyInEmptyImage: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("onLeftKeyInEmptyImage")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.ArrowLeft)
            .inBlockType("image")
            .selectionIsCollapsed()
            .doAction(function (event, editor) {
            var madeMove = (0, ImageActions_1.maybeMoveBackwardsInImage)(editor);
            if (madeMove) {
                event.preventDefault();
            }
            return madeMove;
        }),
        onUpKeyInEmptyImage: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("onUpKeyInEmptyImage")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.ArrowUp)
            .inBlockType("image")
            .selectionIsCollapsed()
            .doAction(function (event, editor) {
            var madeMove = (0, ImageActions_1.maybeMoveBackwardsInImage)(editor);
            if (madeMove) {
                event.preventDefault();
            }
            return madeMove;
        }),
        onRightKeyInEmptyImage: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("onRightKeyInEmptyImage")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.ArrowRight)
            .inBlockType("image")
            .selectionIsCollapsed()
            .doAction(function (event, editor) {
            var madeMove = (0, ImageActions_1.maybeMoveForwardsInImage)(editor);
            if (madeMove) {
                event.preventDefault();
            }
            return madeMove;
        }),
        onDownKeyInEmptyImage: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("onDownKeyInEmptyImage")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.ArrowDown)
            .inBlockType("image")
            .selectionIsCollapsed()
            .doAction(function (event, editor) {
            var madeMove = (0, ImageActions_1.maybeMoveForwardsInImage)(editor);
            if (madeMove) {
                event.preventDefault();
            }
            return madeMove;
        }),
        onDragImageWidget: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("Drag image widget")
            .isEvent(EditorTypes_1.EventType.DragStart)
            .selectionIsCollapsed()
            .doAction(function (event, editor) {
            return (0, ImageActions_1.maybeSetDragImageWidget)(editor, event, dragStore);
        }),
        onDropImageWidget: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("Drop image widget")
            .isEvent(EditorTypes_1.EventType.Drop)
            .doAction(function (_event, editor) {
            return (0, ImageActions_1.maybeDropImageWidget)(dragStore, dropLocationStore, editor);
        }),
        onDragEndImageWidget: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("Drag end image widget")
            .isEvent(EditorTypes_1.EventType.DragEnd)
            .doAction(function () {
            if (dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.ImageWidgetFromEditor)) {
                dragStore.stopDrag();
                dropLocationStore.clearDropLocation();
                return true;
            }
        }),
        onDragOverHoldingImage: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("onDragImageFromSidebar")
            .isEvent(EditorTypes_1.EventType.DragOver)
            .doAction(function (event, editor) {
            var isSidebarImage = dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.SidebarImage);
            var allAreNativeImages = Array.from(event.dataTransfer.items).every(function (item) {
                return item === null || item === void 0 ? void 0 : item.type.startsWith("image/");
            });
            if (!isSidebarImage && !allAreNativeImages)
                return;
            var handled = (0, DragActions_1.maybeDragWidgets)({
                editor: editor,
                event: event,
                dragStore: dragStore,
                dropLocationStore: dropLocationStore,
                providedDragItems: [
                    {
                        type: DragStore_1.DragType.Widget,
                        sourceNode: { type: "image" },
                    },
                ],
            });
            if (handled) {
                event.preventDefault();
                event.stopPropagation();
            }
            return handled;
        }),
        onDropImageFromSidebar: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("onDropImageFromSidebar")
            .isEvent(EditorTypes_1.EventType.Drop)
            .doAction(function (event, editor) {
            var _a, _b;
            var insertedImage = (0, ImageActions_1.maybeInsertImageFromSidebar)(editor, dragStore, dropLocationStore, mediaUsageReporter, blockStore.blockType, editorTelemetry);
            var mediaSource = ((_a = dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.SidebarImage)) === null || _a === void 0 ? void 0 : _a.mediaSource) === Types_1.MediaSource.Explore
                ? "explore"
                : "your_library";
            var tab = (_b = dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.SidebarImage)) === null || _b === void 0 ? void 0 : _b.telemetryData.tab;
            if (insertedImage) {
                event.preventDefault();
                contentSidebarTelemetry.sidebarAssetAdded({
                    assetType: TelemetryEnums_1.SidebarAssetAddedType.Image,
                    insertMethod: TelemetryEnums_1.SidebarAssetAddedInsertMethod.Drag,
                    source: mediaSource,
                    secondarySource: tab,
                });
            }
            return insertedImage;
        }),
    };
});

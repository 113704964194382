"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FontUtils_1 = require("@CommonFrontendBackend/Utils/FontUtils");
function generateCSS(fontSelections) {
    return (0, FontUtils_1.generateCSSForFontSelections)(fontSelections, function (textWidgetSelector) { return [
        ".project-block content-area ".concat(textWidgetSelector),
        ".project-block .new-editor ".concat(textWidgetSelector),
        ".style-editor .project-block ".concat(textWidgetSelector),
        ".census-collector__project-preview .project-block ".concat(textWidgetSelector),
    ]; }, function (textWidgetSelector) { return [
        ".project-block content-area ".concat(textWidgetSelector, ":not(.icon)"),
        ".project-block .new-editor ".concat(textWidgetSelector, ":not(.icon)"),
        ".style-editor .project-block ".concat(textWidgetSelector, ":not(.icon)"),
        ".user-font ".concat(textWidgetSelector),
    ]; }, [".user-font", "project-block .widget-adder__placeholder p"]);
}
exports.default = generateCSS;

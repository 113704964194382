(function () {
  module.exports = function (
    blockEvents,
    projectEvents,
    blockList,
    telemetry,
    projectTelemetry,
    project,
    currenciesUiDelegate,
    dropzonesStore,
  ) {
    return function (block) {
      var blockIndex, ref;
      blockEvents.emit("deleting");
      blockIndex = blockList.getIndex(block);
      if (blockList.getLength() === 1) {
        dropzonesStore.clearPersistent(false);
        dropzonesStore.clearActiveDropzone();
      } else if (
        dropzonesStore.isPersistent &&
        dropzonesStore.currentDropzone &&
        dropzonesStore.currentDropzone.index >= blockIndex
      ) {
        dropzonesStore.setCurrentDropzone(dropzonesStore.currentDropzone.index - 1);
      }
      blockList.removeBlock(block);
      blockEvents.emit("deleted");
      telemetry.trackOnly("Block Deleted", {});
      projectTelemetry.projectEdited(project);
      if ((ref = block.type) === "Accept" || ref === "QuoteV2" || ref === "Quote") {
        projectEvents.emit("Paid block deleted", {
          type: block.type,
        });
      }
      if (block.type === "QuoteV2") {
        return currenciesUiDelegate.currenciesChanged();
      }
    };
  };
}.call(this));

/*
Creditcard service
Wraps API calls to stripe via server
 */

(function () {
  let PaymentService;

  module.exports = PaymentService = (function () {
    function PaymentService($q, $http, QwilrStripe, telemetry, apiBaseUrl, $log, $timeout) {
      /*
        Small wrapper functions to pass on any error objects received from the server
        The server is expected to always return a well-formed error object (due to the api
        HandlerWrapper). If it doesn't, it's because something is seriously broken, and we
        return an "Unknown server error" instead
       */
      let httpGet, httpPost, wrapServerError;
      wrapServerError = function (err) {
        let errorMessage, ref, ref1;
        errorMessage = (ref = err.data) != null ? ((ref1 = ref.error) != null ? ref1.message : void 0) : void 0;
        if (errorMessage == null) {
          errorMessage = "Unknown server error";
          $log.error(`Unknown server error: ${err}`);
        }
        return $q.reject(new Error(errorMessage));
      };
      httpGet = function (path) {
        return $http.get(path)["catch"](function (err) {
          return wrapServerError(err);
        });
      };
      httpPost = function (path, params) {
        if (params == null) {
          params = {};
        }
        return $http.post(path, params)["catch"](function (err) {
          return wrapServerError(err);
        });
      };

      /*
        Submits a credit card to stripe and obtaining a credit card token
        @param [object] stripeElements - the StripeElements form to get the card details from
        @return [Promise] - the Stripe token
       */
      this.submitCard = (function (_this) {
        return function (stripeElements) {
          return stripeElements
            .createCardInfo()
            .then(function (cardInfo) {
              telemetry.trackOnly("Card Details Submitted", {
                stripe_card_brand: cardInfo.brand,
                stripe_card_expiry: `${cardInfo.expiryMonth}/${cardInfo.expiryYear}`,
              });
              return cardInfo.token;
            })
            ["catch"](function (err) {
              if ((err != null ? err.type : void 0) === "card_error") {
                return $q.reject(new Error("Stripe error: could not process your card"));
              } else {
                return $q.reject(new Error("Your credit card could not be processed. Please try again."));
              }
            });
        };
      })(this);

      /*
        Checks whether a coupon code is valid.
        @param [string] couponCode
        @return [Promise] - Coupon associated with the code or false if invalid
       */
      this.checkCoupon = (function (_this) {
        return function (couponCode) {
          if (!(couponCode != null ? couponCode.length : void 0)) {
            return $q.reject(new Error("No coupon code provided"));
          }
          return httpGet(`${apiBaseUrl}/payment/coupon/${couponCode}`).then(function (result) {
            if (result.data == null) {
              return $q.reject(new Error("Could not check coupon code"));
            }
            return result.data;
          });
        };
      })(this);

      /*
        Saves the stripe credit card token to the qwilr account
        @param [string] accountId
        @param [string] token - credit card token
        @return [Promise] - Stripe customer token for the user
       */
      this.addCreditCard = (function (_this) {
        return function (accountId, token) {
          if (accountId == null) {
            return $q.reject(new Error("No account ID provided"));
          }
          if (token == null) {
            return $q.reject(new Error("No card token provided"));
          }
          return httpPost(`${apiBaseUrl}/accounts/${accountId}/payment/card`, {
            token: token,
          }).then(function (result) {
            let ref;
            if (!(((ref = result.data) != null ? ref.customerToken : void 0) != null)) {
              return $q.reject(new Error("Could not add credit card"));
            }
            return result.data.customerToken;
          });
        };
      })(this);

      /*
        Updates an existing credit card token on a qwilr account
        @param [string] accountId
        @param [string] token - credit card token
        @return [Promise] - Object containing the old and new card details
       */
      this.updateCreditCard = (function (_this) {
        return function (accountId, token) {
          if (accountId == null) {
            return $q.reject(new Error("No account ID provided"));
          }
          if (token == null) {
            return $q.reject(new Error("No card token provided"));
          }
          return httpPost(`${apiBaseUrl}/accounts/${accountId}/payment/change-card`, {
            token: token,
          }).then(function (response) {
            let newCard, oldCard, ref, ref1, ref2;
            if (
              !(
                ((ref = response.data) != null ? ref.oldCard : void 0) != null ||
                ((ref1 = response.data) != null ? ref1.newCard : void 0) != null
              )
            ) {
              return $q.reject(new Error("Could not update credit card"));
            }
            (ref2 = response.data), (oldCard = ref2.oldCard), (newCard = ref2.newCard);
            telemetry.trackOnly("Card Details Changed", {
              stripe_card_brand: newCard.brand,
              stripe_card_brand_old: oldCard.brand,
              stripe_card_expiry: `${newCard["exp_month"]}/${newCard["exp_year"]}`,
              stripe_card_expiry_old: `${oldCard["exp_month"]}/${oldCard["exp_year"]}`,
            });
            return response.data;
          });
        };
      })(this);

      /*
        Gets the card details from an existing account (or false)
        @param [string] accountId
        @return [Promise] - Object containing the current card for the account
       */
      this.getCardDetails = (function (_this) {
        return function (accountId) {
          if (accountId == null) {
            return $q.reject(new Error("No account ID provided"));
          }
          return httpGet(`${apiBaseUrl}/accounts/${accountId}/payment/card`).then(function (response) {
            if (!(response.data != null)) {
              return $q.reject(new Error("Could not get credit card details"));
            }
            return response.data;
          });
        };
      })(this);
      this.getPaymentDetails = (function (_this) {
        return function (accountId) {
          if (accountId == null) {
            return $q.reject(new Error("No account ID provided"));
          }
          return httpGet(`${apiBaseUrl}/accounts/${accountId}/payment`).then(function (response) {
            return response.data;
          });
        };
      })(this);
      this.updateBillingContact = (function (_this) {
        return function (accountId, details) {
          if (accountId == null) {
            return $q.reject(new Error("No account ID provided"));
          }
          return $http
            .post(`${apiBaseUrl}/accounts/${accountId}/payment/billing-contact`, details)
            ["catch"](function (err) {
              let errorMessage;
              errorMessage =
                err.status === 400
                  ? "That email doesn't look right. Please ensure you use a valid email."
                  : "Something went wrong. Please try again.";
              return $q.reject(new Error(errorMessage));
            });
        };
      })(this);

      /*
        Gets card details based on a stripe card token
        @param [string] accountId
        @return [Promise] - Object containing the current card for the token
       */
      this.getCardDetailsToken = (function (_this) {
        return function (cardToken) {
          if (cardToken == null) {
            return $q.reject(new Error("No card token provided"));
          }
          return httpGet(`${apiBaseUrl}/payment/card/${cardToken}`).then(function (response) {
            if (!(response.data != null)) {
              return $q.reject(new Error("Could not get credit card details"));
            }
            return response.data;
          });
        };
      })(this);

      /*
        Gets a preview of invoices ahead of an ugprade to a new plan
        @param [string] newPlan - the id for a the plan being upgraded onto
        @param [number] totalSeats - the total seats in the account
        @return [Promise] - An array of the next stripe invoice object if the upgrade is enacted
       */
      this.getInvoicePreview = (function (_this) {
        return function (newPlan, totalSeats) {
          if (newPlan == null) {
            return $q.reject(new Error("No new plan provided"));
          }
          return httpPost(`${apiBaseUrl}/payment/upgrade`, {
            plan: newPlan,
            totalSeats: totalSeats,
          }).then(function (resp) {
            let ref;
            if (!(((ref = resp.data) != null ? ref.invoice : void 0) != null)) {
              return $q.reject(new Error("Could not get invoice preview"));
            }
            return resp.data.invoice;
          });
        };
      })(this);

      /*
        Updates an existing subscription to a new plan
        @param [string] accountId
        @param [object] updateDetails
          {
            plan: the id of the plan being changed to
            coupon: optional - id of any coupon being applied to the payment
            prorationDate: optional -  the time (ms since epoch) at which the update shall be applied
          }
        @return [object] - Promise that resolves to the next invoice if the upgrade is enacted
          {
            plan: id of the new plan set to the account
            stripe: object containing stripe data for an account
          }
       */
      this.updateSubscription = (function (_this) {
        return function (accountId, updateDetails) {
          if (updateDetails == null) {
            return $q.reject(new Error("No update details provided"));
          }
          if (updateDetails.plan == null) {
            return $q.reject(new Error("No updated plan provided"));
          }
          return httpPost(`${apiBaseUrl}/accounts/${accountId}/payment/subscription/update`, updateDetails).then(
            function (response) {
              let ref;
              if (!(((ref = response.data) != null ? ref.message : void 0) != null)) {
                return $q.reject(new Error("Could not update subscription"));
              }
              return response.data.message;
            },
          );
        };
      })(this);

      /*
        Function for starting a subscription on a plan
        @param [string] accountId
        @param [object] subDetails
          {
            plan: the id of the plan being changed to
            token: optional - the credit card token used for the payment
            coupon: optional - id of any coupon being applied to the payment
          }
        @return [object] -
          {
            plan: id of the new plan set to the account
            stripe: object containing stripe data for an account
          }
       */
      this.startSubscription = (function (_this) {
        return function (accountId, subDetails) {
          if (subDetails == null) {
            return $q.reject(new Error("No subscription details provided"));
          }
          if (subDetails.plan == null) {
            return $q.reject(new Error("No plan provided"));
          }
          return httpPost(`${apiBaseUrl}/accounts/${accountId}/payment/subscription/start`, subDetails).then(function (
            response,
          ) {
            let ref;
            if (!(((ref = response.data) != null ? ref.message : void 0) != null)) {
              return $q.reject(new Error("Could not start subscription"));
            }
            return response.data.message;
          });
        };
      })(this);

      /*
        Function for notifying the server that authentication for a recent payment
        has been successfully verified
        @param [string] accountId
        @param [object] data
          {
            paymentIntentId: paymentIntent's id of the payment
          }
        @return [object] -
          {
            plan: id of the new plan set to the account
            stripe: object containing stripe data for an account
          }
       */
      this.paymentSuccessfullyVerified = (function (_this) {
        return function (accountId, data) {
          if (data == null) {
            return $q.reject(new Error("Not enough information provided to process post-verification"));
          }
          if (data.paymentIntentId == null) {
            return $q.reject(new Error("No paymentIntent's id provided to process post-verification"));
          }
          return httpPost(`${apiBaseUrl}/accounts/${accountId}/payment/subscription/verified`, data).then(function (
            response,
          ) {
            let ref;
            if (!(((ref = response.data) != null ? ref.message : void 0) != null)) {
              return $q.reject(new Error("Could not process post-verification"));
            }
            return response.data.message;
          });
        };
      })(this);

      /*
        Function for starting business trial on an account
        @param [string] accountId - ID of the account to be changed
        @return [promise] plan - the business trial db plan object
       */
      this.startBusinessTrial = (function (_this) {
        return function (accountId) {
          return httpPost(`${apiBaseUrl}/accounts/${accountId}/plan/start-trial`).then(function (response) {
            let ref;
            if (!(((ref = response.data) != null ? ref.message : void 0) != null)) {
              return $q.reject(new Error("Could not change to business trial"));
            }
            $timeout(function () {
              return window.location.reload();
            });
            return response.data.message;
          });
        };
      })(this);

      /*
        Function for changing an account to the starter plan (non subscribers only)
        @param [string] accountId - ID of the account to be changed
        @param [promise] plan - the starter db plan object
      
        Note: in order to change a subscribed account to starter, use the cancellation flow
       */
      this.changeToStarter = (function (_this) {
        return function (accountId) {
          return httpPost(`${apiBaseUrl}/accounts/${accountId}/plan/change-to-starter`).then(function (response) {
            let ref;
            if (!(((ref = response.data) != null ? ref.message : void 0) != null)) {
              return $q.reject(new Error("Could not change to starter plan"));
            }
            return response.data.message;
          });
        };
      })(this);
    }

    return PaymentService;
  })();
}.call(this));

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PipedriveButton = require("./Controls/Integrations/PipedriveButton");
var AcceptPreviewEvent = require("./Controls/AcceptPreviewEventEmitter").AcceptPreviewEvent;
var _a = require("@CommonFrontendBackend/FeatureFlag"), isUsingTCDC = _a.isUsingTCDC, FeatureFlag = _a.FeatureFlag;
function default_1(make, StyleButton, BlockControls, integrationService, usingFeature, acceptPreviewEventEmitter, deleteBlock, di) {
    var description;
    var onDeleteAccept = function (block) {
        acceptPreviewEventEmitter.removeListeners(AcceptPreviewEvent.ClosePreview);
        return deleteBlock(block);
    };
    var blockStore = di.get("blockStore");
    var usingTCDC = isUsingTCDC(usingFeature);
    var styleButton = make(StyleButton);
    var blockControls = make(BlockControls, {
        deleteBlock: onDeleteAccept,
    });
    var esignButton = make(require("./Controls/ESignature/ESignatureButton"));
    var paymentSetupButton = make(require("./Controls/PaymentSetup/PaymentSetupButton")["default"]);
    var qwilrPayButton = make(require("Project/QwilrPay/QwilrPayButton.tsx")["default"]);
    var agreementViewButton = make(require("./Controls/Agreement/AgreementButton")["default"]);
    var showLabel = !usingTCDC;
    var usingEsignVerificationFlow = usingFeature(FeatureFlag.EsignAcceptVerification) && !usingFeature(FeatureFlag.OptOutNewEditor);
    if (showLabel) {
        description = "Change the text in the button and success message";
    }
    else {
        description = "Change the text your client sees after accepting this page";
    }
    var acceptText = {
        name: "Accept Text",
        description: description,
        ctrl: make(require("./Controls/AcceptText/AcceptText"), {
            showLabel: !usingTCDC,
        }),
    };
    var acceptEmail = {
        name: "Accept Email",
        description: "Show or hide the page total in the Accept email (useful for pages with multiple quote blocks).",
        ctrl: make(require("./Controls/AcceptEmail/AcceptEmail")),
    };
    var customForm = {
        name: "Custom Form",
        description: "Create a custom form to collect more details during acceptance",
        ctrl: make(require("./Controls/CustomForm/CustomForm")),
    };
    var superpowers = [];
    if (usingTCDC) {
        superpowers.push({
            name: "Style library",
            ctrl: styleButton,
            selectable: false,
            select: styleButton.onClick,
            deselect: styleButton.onClose,
            overflow: true,
            doNotWrapControl: true,
        });
    }
    superpowers.push({
        name: "Block Controls",
        ctrl: blockControls,
        doNotWrapControl: true,
        visibleOnViewOnlyBlocks: true,
    });
    superpowers.push({
        name: "E-signature",
        ctrl: esignButton,
        selectable: true,
        select: function () {
            esignButton.onClick();
            return acceptPreviewEventEmitter.trigger(AcceptPreviewEvent.ClosePreview);
        },
        deselect: esignButton.onClose,
    });
    if (usingFeature(FeatureFlag.QwilrPay)) {
        if (!blockStore.isSavedBlock)
            superpowers.push({
                name: "Qwilr Pay",
                ctrl: qwilrPayButton,
                selectable: false,
            });
    }
    else {
        superpowers.push({
            name: "Payment",
            ctrl: paymentSetupButton,
            selectable: true,
            select: function () {
                paymentSetupButton.onClick();
                return acceptPreviewEventEmitter.trigger(AcceptPreviewEvent.ClosePreview);
            },
            deselect: paymentSetupButton.onClose,
        });
    }
    if (integrationService.isEnabled("pipedrive")) {
        var pipedriveButton = make(PipedriveButton);
        superpowers.push({
            name: "Pipedrive",
            ctrl: pipedriveButton,
            selectable: true,
            select: pipedriveButton.onClick,
            deselect: pipedriveButton.onClose,
        });
    }
    if (usingFeature(FeatureFlag.AgreementViewBasic)) {
        superpowers.push({
            name: "Agreement",
            ctrl: agreementViewButton,
            selectable: false,
        });
    }
    var items = [acceptEmail, acceptText, customForm];
    if (usingFeature(FeatureFlag.PostAcceptExperience)) {
        items.push({
            name: "Redirect",
            description: "After the accept flow has been completed, redirect to this link",
            ctrl: make(require("./Controls/PostAccept/PostAccept").default),
        });
    }
    return {
        settings: {
            useItems: ["foldable", "navigationMenu"],
            items: items,
            superpowers: superpowers,
            onMenuOpen: function () {
                return acceptPreviewEventEmitter.trigger(AcceptPreviewEvent.ClosePreview);
            },
        },
    };
}
exports.default = default_1;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TelemetryEnums_1 = require("@CommonFrontend/Telemetry/TelemetryEnums");
var lodash_1 = require("lodash");
var SaveBlockController;
module.exports = SaveBlockController = (function () {
    function SaveBlockController(createUi, block, savedBlockService, permissionsService, notifyUser, $timeout, keyboard, closeMenu, usingFeature, rolesService, user, telemetry) {
        var _this = this;
        var persistAdminOnly = function (savedBlockId, isAdminEditOnly) {
            return savedBlockService.setLockEditingSetting(savedBlockId, isAdminEditOnly);
        };
        this.ui = createUi(require("./SaveBlock.html"), {
            blockName: "",
            error: "",
            label: "Save",
            saving: false,
            saved: false,
            savedBlock: undefined,
            userIsAdmin: function () {
                return rolesService.isAdmin(user);
            },
            onKey: function (e) {
                if (keyboard.isKey(e, "ENTER")) {
                    return debouncedSave();
                }
            },
            clearError: function () {
                return (_this.ui.error = "");
            },
            save: function () {
                if (!_this.ui.blockName.length) {
                    return (_this.ui.error = "Please enter a name for your block");
                }
                return (savedBlockService
                    .save(block, _this.ui.blockName)
                    .then(function (savedBlock) {
                    telemetry.trackOnly("Saved Block Created", {
                        saved_block_origin: TelemetryEnums_1.SavedBlockOrigin.Editor,
                        block_type: block.type,
                    });
                    return (_this.ui.savedBlock = savedBlock);
                })
                    .then(function () {
                    if (_this.ui.adminEditOnly) {
                        persistAdminOnly(_this.ui.savedBlock.id, _this.ui.adminEditOnly);
                        return (_this.ui.savedBlock.$permissions = [
                            {
                                action: "edit",
                                owner: "role/admin",
                            },
                        ]);
                    }
                })
                    .then(function () {
                    return onSaved();
                })
                    .catch(function (err) {
                    return (_this.ui.error = err);
                }));
            },
            cancel: closeMenu,
            usingFeature: function (featureName) {
                return usingFeature(featureName);
            },
            adminEditOnly: false,
            toggleAdminEdit: function () {
                if (_this.ui.savedBlock != null) {
                    return persistAdminOnly(_this.ui.savedBlock.id, _this.ui.adminEditOnly);
                }
            },
        });
        var debouncedSave = (0, lodash_1.debounce)(this.ui.save, 1000, { leading: true });
        var onDone = function () {
            _this.ui.saved = false;
            _this.ui.saving = false;
            return (_this.ui.label = "Save");
        };
        var onSaved = function () {
            _this.ui.saved = true;
            _this.ui.saving = false;
            _this.ui.label = "Saved";
            return $timeout(onDone, 2750);
        };
    }
    return SaveBlockController;
}());

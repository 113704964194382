"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(make, StyleButton, BlockControls) {
    var styleButton = make(StyleButton);
    var blockControls = make(BlockControls);
    var superpowers = [
        {
            name: "Style library",
            ctrl: styleButton,
            selectable: false,
            select: styleButton.onClick,
            deselect: styleButton.onClose,
            overflow: true,
            doNotWrapControl: true,
        },
        {
            name: "Block Controls",
            ctrl: blockControls,
            doNotWrapControl: true,
            visibleOnViewOnlyBlocks: true,
        },
    ];
    return {
        settings: {
            useItems: ["foldable", "navigationMenu"],
            superpowers: superpowers,
        },
    };
}
exports.default = default_1;

"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InlinePromptsHistoryStore = void 0;
var mobx_1 = require("mobx");
var PromptTypes_1 = require("Prompt/PromptTypes");
var luxon_1 = require("luxon");
var InlinePromptsHistoryStore = (function () {
    function InlinePromptsHistoryStore(_useHistory, useHistoryService) {
        var _this = this;
        this.useHistoryService = useHistoryService;
        this.hasDismissed = function (promptId) {
            var promptObject = _this.getPromptObject(promptId);
            if (!promptObject) {
                return false;
            }
            if (!promptObject.hasDismissed)
                return false;
            if (promptObject.repeatable) {
                var duration = luxon_1.Duration.fromISO(promptObject.interval);
                return luxon_1.DateTime.now() < luxon_1.DateTime.fromISO(promptObject.lastDismissed).plus(duration);
            }
            return promptObject.hasDismissed;
        };
        this.getPromptObject = function (promptId) {
            return _this.useHistory.inlinePrompts[promptId];
        };
        this.getCompletedActions = function (promptId) {
            var _a;
            var inlinePromptObject = _this.getPromptObject(promptId);
            return (_a = inlinePromptObject === null || inlinePromptObject === void 0 ? void 0 : inlinePromptObject.completedActions) !== null && _a !== void 0 ? _a : [];
        };
        this.setActionCompleted = function (promptId, action) {
            var _a;
            var inlinePromptObject = _this.getPromptObject(promptId);
            if (!inlinePromptObject) {
                return;
            }
            var currentCompletedActions = (_a = inlinePromptObject.completedActions) !== null && _a !== void 0 ? _a : [];
            if (currentCompletedActions.includes(action)) {
                return;
            }
            currentCompletedActions.push(action);
            inlinePromptObject.completedActions = currentCompletedActions;
            _this.useHistoryService.updateInlinePrompts(_this.useHistory);
        };
        this.useHistory = _useHistory;
        this.useHistory.inlinePrompts = this.useHistory.inlinePrompts || {
            archivedPagesExplanation: emptyInlinePrompt(),
            collaboratorLink: emptyInlinePrompt(),
            pagesDashboardOnboardingBanner: emptyInlinePrompt(),
            discoverSidebar: emptyInlinePrompt(),
            archivedTemplatesExplanation: emptyInlinePrompt(),
            templatesWelcome: emptyInlinePrompt(),
            integrationsHubspotOnboarding: emptyInlinePrompt(),
            analyticsReportUpsell: emptyRepeatablePrompt(),
            enableAutomationExplanation: emptyInlinePrompt(),
            automationsOverview: emptyInlinePrompt(),
            analyticsTimeZone: emptyInlinePrompt(),
            qwilrPayQuoteBlockTooltip: emptyInlinePrompt(),
            qwilrPayOnboarding: emptyInlinePrompt(),
        };
    }
    InlinePromptsHistoryStore.prototype.dismissedPrompt = function (promptId) {
        if (this.hasDismissed(promptId))
            return;
        var inlinePromptObject = this.getPromptObject(promptId);
        if (!inlinePromptObject) {
            return;
        }
        inlinePromptObject.hasDismissed = true;
        if (inlinePromptObject.repeatable) {
            inlinePromptObject.lastDismissed = luxon_1.DateTime.now().toISO();
        }
        this.useHistoryService.updateInlinePrompts(this.useHistory);
    };
    var _a;
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_a = typeof PromptTypes_1.PromptId !== "undefined" && PromptTypes_1.PromptId) === "function" ? _a : Object]),
        __metadata("design:returntype", void 0)
    ], InlinePromptsHistoryStore.prototype, "dismissedPrompt", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Object)
    ], InlinePromptsHistoryStore.prototype, "setActionCompleted", void 0);
    return InlinePromptsHistoryStore;
}());
exports.InlinePromptsHistoryStore = InlinePromptsHistoryStore;
var emptyInlinePrompt = function () {
    return {
        hasDismissed: false,
    };
};
var emptyRepeatablePrompt = function () {
    return {
        hasDismissed: false,
        repeatable: false,
        interval: "P0D",
        lastDismissed: "",
    };
};
exports.default = InlinePromptsHistoryStore;

(function () {
  var MenuTemplate, SelectionTypeMenu;

  MenuTemplate = require("./SelectionTypeMenu.html");

  module.exports = SelectionTypeMenu = (function () {
    function SelectionTypeMenu(createUi, quoteAdapter, quoteContent, telemetry) {
      var selectionTypes;
      selectionTypes = [
        {
          description: "One combined package",
          modelName: "combined",
        },
        {
          description: "Multiple choice",
          modelName: "multi",
        },
        {
          description: "Single select",
          modelName: "single",
        },
      ];
      this.ui = createUi(MenuTemplate, {
        selectionTypes: selectionTypes,
        requireAChoice: quoteAdapter.getSelectionRequired(),
        radioValue: quoteAdapter.getSelectionType(),
        selected: (function (_this) {
          return function () {
            var telemetrySelectionType;
            quoteAdapter.setSelectionType(_this.ui.radioValue);
            quoteContent.assignSectionViewIds();
            telemetrySelectionType = _this.ui.radioValue === "multi" ? "multiple" : _this.ui.radioValue;
            return telemetry.trackOnly("Quote Block Section Type Selected", {
              quote_block_section_selection_type: telemetrySelectionType,
            });
          };
        })(this),
        requireAChoiceClicked: (function (_this) {
          return function () {
            return quoteAdapter.setSelectionRequired(_this.ui.requireAChoice);
          };
        })(this),
        disableRequireAChoice: (function (_this) {
          return function () {
            return _this.ui.radioValue === "combined";
          };
        })(this),
      });
    }

    return SelectionTypeMenu;
  })();
}.call(this));

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectionBoxStore = void 0;
var slate_1 = require("slate");
var Box_1 = require("@CommonFrontend/Utils/Box");
var SelectionBoxStore = (function () {
    function SelectionBoxStore(syntheticSelectionStore) {
        this.syntheticSelectionStore = syntheticSelectionStore;
        this.registry = new Map();
    }
    SelectionBoxStore.prototype.onScreenSelectableElements = function () {
        return Array.from(this.registry.values()).filter(function (_a) {
            var rect = _a.rect;
            if (!rect)
                return false;
            var screen = {
                x: 0,
                y: 0,
                width: window.innerWidth,
                height: window.innerHeight,
            };
            return (0, Box_1.isIntersecting)(screen, rect);
        });
    };
    SelectionBoxStore.prototype.register = function (descriptor) {
        this.registry.set(descriptor.id, __assign(__assign({}, descriptor), { rect: null }));
    };
    SelectionBoxStore.prototype.updateRect = function (id, rect) {
        var descriptor = this.registry.get(id);
        if (!descriptor) {
            return;
        }
        this.registry.set(id, __assign(__assign({}, descriptor), { rect: rect }));
    };
    SelectionBoxStore.prototype.remove = function (id) {
        this.registry.delete(id);
    };
    SelectionBoxStore.prototype.notifyIntersecting = function (rect) {
        var e_1, _a, e_2, _b, e_3, _c;
        var _this = this;
        var selections = this.syntheticSelectionStore.selection
            .filter(function (e) { return e.source !== "selection-box"; })
            .map(function (s) { return ({ entry: s, overlap: -1 }); });
        try {
            for (var _d = __values(this.onScreenSelectableElements()), _e = _d.next(); !_e.done; _e = _d.next()) {
                var descriptor = _e.value;
                if (!descriptor.rect)
                    continue;
                if ((0, Box_1.isIntersecting)(descriptor.rect, rect)) {
                    selections.push({
                        entry: __assign(__assign({}, descriptor.selectionEntry), { source: "selection-box" }),
                        overlap: (0, Box_1.getOverlap)(rect, descriptor.rect),
                    });
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (this.syntheticSelectionStore.currentSelectionEditor()) {
            selections = selections.filter(function (s) { return s.entry.editor === _this.syntheticSelectionStore.currentSelectionEditor(); });
        }
        var toRemove = [];
        try {
            for (var selections_1 = __values(selections), selections_1_1 = selections_1.next(); !selections_1_1.done; selections_1_1 = selections_1.next()) {
                var selectionA = selections_1_1.value;
                var _loop_1 = function (selectionB) {
                    if (selectionA === selectionB)
                        return "continue";
                    var selectionAIsParent = slate_1.Path.isAncestor(selectionA.entry.path, selectionB.entry.path);
                    var selectionBIsParent = slate_1.Path.isAncestor(selectionB.entry.path, selectionA.entry.path);
                    if (!selectionAIsParent && !selectionBIsParent)
                        return "continue";
                    var parent = selectionAIsParent ? selectionA : selectionB;
                    var child = selectionAIsParent ? selectionB : selectionA;
                    var parentHasSiblings = selections.some(function (s) { return parent.entry.path !== s.entry.path && !slate_1.Path.isAncestor(parent.entry.path, s.entry.path); });
                    if (parent.overlap === 1) {
                        toRemove.push(child);
                    }
                    else if (parentHasSiblings) {
                        toRemove.push(child);
                    }
                    else {
                        toRemove.push(parent);
                    }
                };
                try {
                    for (var selections_2 = (e_3 = void 0, __values(selections)), selections_2_1 = selections_2.next(); !selections_2_1.done; selections_2_1 = selections_2.next()) {
                        var selectionB = selections_2_1.value;
                        _loop_1(selectionB);
                    }
                }
                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                finally {
                    try {
                        if (selections_2_1 && !selections_2_1.done && (_c = selections_2.return)) _c.call(selections_2);
                    }
                    finally { if (e_3) throw e_3.error; }
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (selections_1_1 && !selections_1_1.done && (_b = selections_1.return)) _b.call(selections_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        this.syntheticSelectionStore.setSelection(selections.filter(function (s) { return !toRemove.includes(s); }).map(function (s) { return s.entry; }));
    };
    return SelectionBoxStore;
}());
exports.SelectionBoxStore = SelectionBoxStore;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QwilrPayMenu = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var styles = __importStar(require("./QwilrPay.css"));
var mobx_react_1 = require("mobx-react");
var QwilrPayIntegrationPrompt_1 = require("./QwilrPayIntegrationPrompt");
var GatewaySelector_1 = require("Project/QwilrPay/GatewaySelector");
var usePage_1 = require("Project/usePage");
var event_tracker_types_1 = require("@qwilr/event-tracker-types");
var inversify_react_1 = require("inversify-react");
var usePaymentPermissions_1 = require("Library/PaymentsDashboard/PaymentSettings/usePaymentPermissions");
var lodash_1 = require("lodash");
var ConnectGatewayButton_1 = require("QwilrPay/ConnectGatewayButton");
exports.QwilrPayMenu = (0, mobx_react_1.observer)(function (_a) {
    var gateways = _a.gateways, pageId = _a.pageId, setIsOnboardingModalOpen = _a.setIsOnboardingModalOpen, showPreviewCallback = _a.showPreviewCallback, telemetry = _a.telemetry;
    var state = (0, inversify_react_1.useInjection)("state");
    var _b = __read(react_1.default.useState(false), 2), advancedOptionsExpaned = _b[0], setAdvancedOptionsExpaned = _b[1];
    var discoverStore = (0, inversify_react_1.useInjection)("discoverStore");
    var _c = (0, usePage_1.usePage)(pageId), page = _c.page, updater = _c.updater;
    var updaterDebounced = (0, react_1.useCallback)((0, lodash_1.debounce)(updater, 500), []);
    var savedPaymentSettings = page === null || page === void 0 ? void 0 : page.paymentSettings;
    var getPaymentGateway = function (gatewayId) {
        return gateways.find(function (x) { return x.id === gatewayId; });
    };
    var getPaymentGatewayByPlatformId = function (platformId) {
        return gateways.find(function (x) { return x.platform.id === platformId; });
    };
    var NULL_PAYMENT_SETTINGS = {
        discoverable: false,
        enabled: false,
        gatewayId: null,
        gatewayCountryCode: null,
        partialPaymentConfig: {
            enabled: false,
            amount: 0,
            kind: "fixed",
        },
        requireOnAccept: true,
    };
    var _d = __read((0, react_1.useState)(savedPaymentSettings !== null && savedPaymentSettings !== void 0 ? savedPaymentSettings : NULL_PAYMENT_SETTINGS), 2), paymentSettings = _d[0], setPaymentSettings = _d[1];
    var isPaymentEnabled = paymentSettings.enabled && paymentSettings.gatewayId;
    var selectedGateway = (paymentSettings.gatewayId && getPaymentGatewayByPlatformId(paymentSettings.gatewayId)) || null;
    var isAdmin = (0, inversify_react_1.useInjection)("rootDataStore").userDataStore.isAdmin;
    var permissions = (0, usePaymentPermissions_1.usePaymentPermission)().permissions;
    var canEditPayments = isAdmin || permissions.enable;
    var canEditPartPayments = isPaymentEnabled && (isAdmin || permissions.partialPayments);
    var canEditRequireOnAccept = isPaymentEnabled && (isAdmin || permissions.requireOnAccept);
    var canEditDiscoverability = isPaymentEnabled && (isAdmin || permissions.alert);
    var currenciesList = (0, inversify_react_1.useInjection)("currenciesList");
    var currencyMetadata = currenciesList.get((page === null || page === void 0 ? void 0 : page.currency) || "AUD");
    (0, react_1.useEffect)(function () {
        if (savedPaymentSettings) {
            setPaymentSettings(savedPaymentSettings);
        }
    }, [savedPaymentSettings]);
    var updatePaymentSettings = function (updates, options) { return __awaiter(void 0, void 0, void 0, function () {
        var pageUpdater, prevSettings, newSettings, _1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    pageUpdater = (options === null || options === void 0 ? void 0 : options.debounce) ? updaterDebounced : updater;
                    prevSettings = paymentSettings;
                    newSettings = __assign(__assign({}, paymentSettings), updates);
                    setPaymentSettings(newSettings);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4, pageUpdater({ paymentSettings: updates })];
                case 2:
                    _a.sent();
                    if (updates.gatewayId && updates.gatewayCountryCode) {
                        telemetry.currentGatewayChanged(event_tracker_types_1.EventOrigin.QwilrPayWidget, gateways.length, updates.gatewayCountryCode);
                    }
                    return [3, 4];
                case 3:
                    _1 = _a.sent();
                    setPaymentSettings(prevSettings);
                    return [3, 4];
                case 4: return [2];
            }
        });
    }); };
    var updateGateway = function (gatewayId) { return __awaiter(void 0, void 0, void 0, function () {
        var gateway, updates;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (gatewayId === (selectedGateway === null || selectedGateway === void 0 ? void 0 : selectedGateway.id)) {
                        return [2];
                    }
                    gateway = getPaymentGateway(gatewayId);
                    updates = gateway
                        ? {
                            gatewayId: gateway.platform.id,
                            gatewayCountryCode: gateway.countryCode,
                        }
                        : {
                            enabled: false,
                        };
                    return [4, updatePaymentSettings(updates)];
                case 1:
                    _a.sent();
                    return [2];
            }
        });
    }); };
    var goToPaymentsLibrary = function () {
        state.go("dashboard", { nav: "payments" });
    };
    var TooltippedToggle = function (_a) {
        var tooltipImage = _a.tooltipImage, label = _a.label, value = _a.value, onChange = _a.onChange, showTooltip = _a.showTooltip, disabled = _a.disabled, explainer = _a.explainer;
        return (react_1.default.createElement(kaleidoscope_1.Tooltip, { className: styles.previewTooltips, position: kaleidoscope_1.TooltipPosition.Right, content: react_1.default.createElement(kaleidoscope_1.Stack, { gap: "xs", padding: "xxs" },
                react_1.default.createElement("img", { src: tooltipImage, width: "180px", className: styles.tooltipImage }),
                react_1.default.createElement(kaleidoscope_1.Text, null, "Buyer will see this")), autoStack: true, wrapTarget: true, offset: 30, show: showTooltip },
            react_1.default.createElement(kaleidoscope_1.Toggle, { className: styles.paymentOptionToggle, disabled: disabled, label: label, onChange: onChange, value: value }),
            explainer && (react_1.default.createElement(kaleidoscope_1.Text, { size: "xs", className: disabled ? styles.disabled : "" }, explainer))));
    };
    return (react_1.default.createElement(kaleidoscope_1.Stack, null, gateways.length > 0 ? (react_1.default.createElement(kaleidoscope_1.Stack, { gap: "s", padding: "l" },
        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", justify: "spaceBetween", align: "center" },
            react_1.default.createElement(kaleidoscope_1.Text, { strong: true, size: "l" }, "Payments"),
            react_1.default.createElement(kaleidoscope_1.OptionMenu, { size: "large", button: react_1.default.createElement(kaleidoscope_1.IconButton, { icon: react_1.default.createElement(icons_1.OptionsHorizontal, null) }), autoStack: true },
                react_1.default.createElement(kaleidoscope_1.OptionMenuItem, { onClick: goToPaymentsLibrary }, "Payments Library"),
                react_1.default.createElement(kaleidoscope_1.OptionMenuItem, { onClick: function () {
                        discoverStore.openChat();
                    } },
                    react_1.default.createElement(kaleidoscope_1.Text, { size: "s", strong: true },
                        "Need help?",
                        " "),
                    "Talk to our team"))),
        react_1.default.createElement(kaleidoscope_1.Toggle, { className: styles.paymentOptionToggle, label: "Enable payments", onChange: function (newSelected) {
                return updatePaymentSettings({
                    enabled: newSelected,
                });
            }, value: paymentSettings.enabled, disabled: !canEditPayments }),
        paymentSettings.enabled && (react_1.default.createElement(GatewaySelector_1.GatewaySelector, { onChange: updateGateway, gateways: gateways, value: selectedGateway, disabled: !canEditPayments })),
        react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.currencyInfo },
            react_1.default.createElement("img", { src: currencyMetadata.flagImage, className: styles.currencyFlag }),
            react_1.default.createElement(kaleidoscope_1.Text, { className: styles.currencyInfoText }, page === null || page === void 0 ? void 0 :
                page.currency,
                " is the ",
                (page === null || page === void 0 ? void 0 : page.currency) == "AUD" ? "default" : "selected",
                " currency. Use the quote block to change it.")),
        (selectedGateway === null || selectedGateway === void 0 ? void 0 : selectedGateway.status) === "onboarding" && (react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.warningContainer, direction: "horizontal", gap: "xs" },
            react_1.default.createElement(icons_1.InformationCircle, { className: styles.onboardingIcon, size: icons_1.IconSize.Medium }),
            react_1.default.createElement(kaleidoscope_1.Stack, { direction: "vertical", gap: "xs" },
                react_1.default.createElement(kaleidoscope_1.Text, { size: "s", className: styles.onboarding },
                    react_1.default.createElement(kaleidoscope_1.Text, { className: styles.onboarding, size: "s", strong: true }, "Action required."),
                    " ",
                    "Complete QwilrPay onboarding to activate payments."),
                react_1.default.createElement(ConnectGatewayButton_1.ConnectGatewayButton, { gatewayId: selectedGateway.id, kind: "continue", size: kaleidoscope_1.ButtonSize.Small, type: kaleidoscope_1.ButtonType.Secondary })))),
        react_1.default.createElement(kaleidoscope_1.Separator, null),
        react_1.default.createElement(kaleidoscope_1.Stack, { gap: "s", paddingBottom: "m" },
            react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", justify: "spaceBetween", align: "center" },
                react_1.default.createElement(kaleidoscope_1.Text, { strong: true, size: "m" }, "Payment Options")),
            react_1.default.createElement(kaleidoscope_1.Stack, { gap: "s" },
                react_1.default.createElement(TooltippedToggle, { tooltipImage: paymentSettings.requireOnAccept
                        ? "Assets/Images/qwilrPay/tooltipGifs/required_payment.gif"
                        : "Assets/Images/qwilrPay/tooltipGifs/optional_payment.gif", onChange: function (newSelected) {
                        return updatePaymentSettings({
                            requireOnAccept: newSelected,
                        });
                    }, label: "Require payment on accept", value: paymentSettings.requireOnAccept, disabled: !canEditRequireOnAccept, explainer: paymentSettings.requireOnAccept
                        ? "Buyer is required to pay to accept the page."
                        : "Buyer sees payment option but can choose to pay now or later." }),
                !paymentSettings.requireOnAccept && (react_1.default.createElement(kaleidoscope_1.TextInput, { label: "Button text (optional)", onChange: function (value) {
                        updatePaymentSettings({ payLaterButtonText: value }, { debounce: true });
                    }, placeholder: "Accept now, pay later", value: paymentSettings.payLaterButtonText, disabled: !canEditRequireOnAccept })),
                react_1.default.createElement(TooltippedToggle, __assign({ tooltipImage: "Assets/Images/qwilrPay/tooltipGifs/part_payment.gif", onChange: function (newSelected) {
                        return updatePaymentSettings({
                            partialPaymentConfig: __assign(__assign({}, paymentSettings.partialPaymentConfig), { enabled: newSelected }),
                        });
                    }, label: "Enable part payment", value: paymentSettings.partialPaymentConfig.enabled }, (!paymentSettings.partialPaymentConfig.enabled ? { showTooltip: false } : {}), { disabled: !canEditPartPayments })),
                paymentSettings.partialPaymentConfig.enabled && (react_1.default.createElement(kaleidoscope_1.Stack, { gap: "xxs" },
                    react_1.default.createElement(kaleidoscope_1.Label, { long: true, htmlFor: "part-payment-amount", disabled: !canEditPartPayments }, "Choose percentage or fixed amount"),
                    react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", className: styles.partPaymentInputGroup, justify: "spaceBetween", align: "center" },
                        react_1.default.createElement(kaleidoscope_1.TextInput, { id: "part-payment-amount", label: "", labelHidden: true, type: kaleidoscope_1.TextInputType.Number, className: styles.paymentTypeInput, size: kaleidoscope_1.TextInputSize.Small, variant: kaleidoscope_1.TextInputVariant.Borderless, value: "".concat(paymentSettings.partialPaymentConfig.amount), onChange: function (value) {
                                return updatePaymentSettings({
                                    partialPaymentConfig: __assign(__assign({}, paymentSettings.partialPaymentConfig), { amount: Number(value) }),
                                });
                            }, disabled: !canEditPartPayments }),
                        react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.partPaymentTypeToggle, direction: "horizontal" },
                            react_1.default.createElement(kaleidoscope_1.Button, { className: styles.toggleButton, size: kaleidoscope_1.ButtonSize.Small, onClick: function () {
                                    return updatePaymentSettings({
                                        partialPaymentConfig: __assign(__assign({}, paymentSettings.partialPaymentConfig), { amount: paymentSettings.partialPaymentConfig.kind === "fixed"
                                                ? 0
                                                : paymentSettings.partialPaymentConfig.amount, kind: "percentage" }),
                                    });
                                }, type: paymentSettings.partialPaymentConfig.kind === "percentage"
                                    ? kaleidoscope_1.ButtonType.Secondary
                                    : kaleidoscope_1.ButtonType.Tertiary, buttonState: kaleidoscope_1.ButtonState.Idle, disabled: !canEditPartPayments }, "%"),
                            react_1.default.createElement(kaleidoscope_1.Button, { className: styles.toggleButton, size: kaleidoscope_1.ButtonSize.Small, onClick: function () {
                                    return updatePaymentSettings({
                                        partialPaymentConfig: __assign(__assign({}, paymentSettings.partialPaymentConfig), { amount: paymentSettings.partialPaymentConfig.kind === "percentage"
                                                ? 0
                                                : paymentSettings.partialPaymentConfig.amount, kind: "fixed" }),
                                    });
                                }, type: paymentSettings.partialPaymentConfig.kind !== "percentage"
                                    ? kaleidoscope_1.ButtonType.Secondary
                                    : kaleidoscope_1.ButtonType.Tertiary, buttonState: kaleidoscope_1.ButtonState.Idle, disabled: !canEditPartPayments }, "$"))))))),
        react_1.default.createElement(kaleidoscope_1.Separator, null),
        react_1.default.createElement(kaleidoscope_1.Stack, { gap: "s", style: { width: "100%" }, align: "center" },
            react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", justify: "spaceBetween", align: "center", style: { width: "100%" } },
                react_1.default.createElement(kaleidoscope_1.Button, { type: kaleidoscope_1.ButtonType.Secondary, onClick: function () { return setAdvancedOptionsExpaned(!advancedOptionsExpaned); }, "aria-expanded": advancedOptionsExpaned, "aria-controls": "advanced-payment-options", width: "full", className: styles.advancedOptionsButton },
                    react_1.default.createElement(kaleidoscope_1.Text, { strong: true, size: "m" }, "Advanced Options"),
                    advancedOptionsExpaned ? react_1.default.createElement(icons_1.ChevronDown, null) : react_1.default.createElement(icons_1.ChevronRight, null))),
            react_1.default.createElement(kaleidoscope_1.Stack, { gap: "s" },
                react_1.default.createElement(kaleidoscope_1.Expandable, { expanded: advancedOptionsExpaned }, function (_a) {
                    var expandableElementRef = _a.expandableElementRef;
                    return (react_1.default.createElement("div", { id: "advanced-payment-options", ref: expandableElementRef },
                        react_1.default.createElement(kaleidoscope_1.Stack, { gap: "s" },
                            react_1.default.createElement(TooltippedToggle, { tooltipImage: "Assets/Images/qwilrPay/tooltipGifs/discoverable_payments.gif", onChange: function (newSelected) {
                                    return updatePaymentSettings({
                                        discoverable: newSelected,
                                    });
                                }, label: "Show buyers a payment alert on pages", value: paymentSettings.discoverable, disabled: !canEditDiscoverability }),
                            react_1.default.createElement(kaleidoscope_1.Text, null, "Business details"),
                            react_1.default.createElement(kaleidoscope_1.Text, { size: "s" }, "Enter your business name and any optional extra information to appear on your charge record."),
                            react_1.default.createElement(kaleidoscope_1.TextInput, { label: "Business name", labelHidden: true, onChange: function (value) {
                                    updatePaymentSettings({ customBusinessName: value }, { debounce: true });
                                }, placeholder: "Business name", value: paymentSettings.customBusinessName }),
                            react_1.default.createElement(kaleidoscope_1.TextInput, { multiline: true, label: "Extra information", labelHidden: true, onChange: function (value) {
                                    updatePaymentSettings({ extraInformation: value }, { debounce: true });
                                }, placeholder: "Extra information", value: paymentSettings.extraInformation }),
                            react_1.default.createElement(kaleidoscope_1.Separator, null))));
                }))),
        react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.previewWrapper, padding: "s", justify: "spaceBetween", direction: "horizontal" },
            react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.previewTextWrapper, direction: "horizontal", gap: "xs", align: "center", justify: "start" },
                react_1.default.createElement(icons_1.Preview, { className: styles.previewIcon }),
                react_1.default.createElement(kaleidoscope_1.Label, { size: "s" }, "Preview Payment Mode")),
            react_1.default.createElement(kaleidoscope_1.Button, { type: kaleidoscope_1.ButtonType.Secondary, size: kaleidoscope_1.ButtonSize.Small, onClick: showPreviewCallback }, "Preview")))) : (react_1.default.createElement(QwilrPayIntegrationPrompt_1.QwilrPayIntegrationPrompt, { setIsOnboardingModalOpen: setIsOnboardingModalOpen }))));
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var embedScript = function (e, t, n) {
    function a() {
        var e = t.getElementsByTagName("script")[0], n = t.createElement("script");
        (n.type = "text/javascript"),
            (n.async = !0),
            (n.src = "https://beacon-v2.helpscout.net"),
            e.parentNode.insertBefore(n, e);
    }
    if (((e.Beacon = n = function (t, n, a) {
        e.Beacon.readyQueue.push({ method: t, options: n, data: a });
    }),
        (n.readyQueue = []),
        "complete" === t.readyState))
        return a();
    e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, !1);
};
var subscriptionToBeaconId = {
    Starter: "376db168-b959-4e1e-8ee2-d1de98ff6986",
    Trial: "db5016aa-9ca6-46d8-80e7-46531128c99e",
    Pro: "a218b6b7-19d6-40b6-b37d-d59f8df328b5",
    Business: "218e4e55-5bd6-49fe-8fa4-4eebde91fe37",
    Enterprise: "cb319561-8ac0-4eab-b5e3-2076b24e00c4",
    EnterpriseTrial: "20da2940-bc62-46db-a029-20800b826ab0",
};
var beaconPlanIds = {
    Starter: 32826,
    Pro: 32827,
    Business: 36215,
    Enterprise: 32828,
    Trial: 32829,
    EnterpriseTrial: 176983,
};
var getSubscriptionIdAndBeaconId = function (plan) {
    if (plan.displayName === "Trial" && plan.internalName.includes("enterprise")) {
        return {
            beaconPlanId: beaconPlanIds.EnterpriseTrial,
            beaconId: subscriptionToBeaconId.EnterpriseTrial,
        };
    }
    return {
        beaconPlanId: beaconPlanIds[plan.displayName.replace(/\+/g, "")],
        beaconId: subscriptionToBeaconId[plan.displayName.replace(/\+/g, "")],
    };
};
function default_1(user, account, getFullName, $log, rolesService, telemetry) {
    embedScript(window, document, window.Beacon || lodash_1.noop);
    var _a = getSubscriptionIdAndBeaconId(account.plan), beaconId = _a.beaconId, beaconPlanId = _a.beaconPlanId;
    if (beaconId == null) {
        $log.error("Could not find a Beacon id for plan: ".concat(account.plan.displayName));
        return;
    }
    var fieldIds = {
        subscription: 6623,
        signedUpDate: 8409,
        userType: 20292,
    };
    var userTypeIds = {
        Admin: 101307,
        Creator: 101308,
    };
    if (beaconPlanId == null) {
        $log.error("Could not start Beacon with plan name: ".concat(account.plan.displayName));
        return;
    }
    var eggplant800 = "#160460";
    Beacon("config", { color: eggplant800, display: { style: "manual" } });
    Beacon("init", beaconId);
    var signedUpDate = new Date(account.createdAt).toISOString().split("T")[0];
    var userFullName = getFullName(user);
    var isAdmin = rolesService.isAdmin(user);
    Beacon("identify", {
        name: userFullName,
        email: user.email,
        Plan: account.plan.displayName,
        SignedUpDate: signedUpDate,
    });
    var onBeaconTrack = function (eventName, params) {
        if (params === void 0) { params = {}; }
        telemetry.trackOnly(eventName, params);
    };
    Beacon("on", "open", function () { return onBeaconTrack("Helpscout Beacon Opened", { event_origin: "qwilr" }); });
    Beacon("on", "close", function () { return onBeaconTrack("Helpscout Beacon Closed"); });
    Beacon("on", "chat-started", function () { return onBeaconTrack("Helpscout Beacon Chat Started"); });
    Beacon("on", "email-sent", function () { return onBeaconTrack("Helpscout Beacon Email Sent"); });
    return Beacon("prefill", {
        name: userFullName,
        email: user.email,
        fields: [
            {
                id: fieldIds.subscription,
                value: beaconPlanId,
            },
            {
                id: fieldIds.userType,
                value: isAdmin ? userTypeIds.Admin : userTypeIds.Creator,
            },
        ],
    });
}
exports.default = default_1;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onAddImageWidget = void 0;
var WidgetActions_1 = require("Project/QwilrEditor/Behaviours/WidgetActions");
var ContentSidebarViewStore_1 = require("Project/ContentSidebar/ContentSidebarViewStore");
var TelemetryEnums_1 = require("@CommonFrontend/Telemetry/TelemetryEnums");
var ContentSidebarLibraryViewStore_1 = require("Project/ContentSidebar/Library/ContentSidebarLibraryViewStore");
var onAddImageWidget = function (_a) {
    var editor = _a.editor, di = _a.di;
    var widgetAdderStore = di.get("widgetAdderStore");
    var block = di.get("blockStore");
    (0, WidgetActions_1.addImageWidget)(editor, {});
    if (!widgetAdderStore.isInTemplate && !block.isSavedBlock) {
        var contentSidebarViewStore = di.get("contentSidebarViewStore");
        var contentSidebarLibraryViewStore = di.get("contentSidebarLibraryViewStore");
        if (contentSidebarViewStore.isOpen && contentSidebarLibraryViewStore.currentTab === ContentSidebarLibraryViewStore_1.ContentSidebarTab.Image) {
            contentSidebarLibraryViewStore.showPrompt(ContentSidebarLibraryViewStore_1.PromptType.ClickOrDragToAddImage);
        }
        contentSidebarViewStore.open(TelemetryEnums_1.SidebarOpenOrigin.ImageWidgetAdder, {
            view: ContentSidebarViewStore_1.SidebarView.Library,
            tab: ContentSidebarLibraryViewStore_1.ContentSidebarTab.Image,
        });
    }
};
exports.onAddImageWidget = onAddImageWidget;

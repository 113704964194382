"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TokenReplacementStore_1 = require("Project/Actions/Tokens/Stores/TokenReplacementStore/TokenReplacementStore");
var TokenViewStore_1 = require("Project/Actions/Tokens/Stores/TokenViewStore");
var BlockSaver_1 = require("Blocks/BlockSaver");
var Emitter = require("events").EventEmitter;
var ProjectStore = require("./Stores/ProjectStore").default;
var ProjectViewStore = require("./Stores/ProjectViewStore").default;
var OverlayStack = require("OverlayStack/OverlayStack.ts").default;
var ActiveUserStore = require("./Stores/ActiveUsersStore/ActiveUsersStore").default;
var ActiveCommentThreadStore = require("./Comments/ActiveCommentThreadStore/ActiveCommentThreadStore").default;
var FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;
var TokenApiService = require("Project/Actions/Tokens/Stores/TokenApiService").TokenApiService;
var TokenStore = require("Project/Actions/Tokens/Stores/TokenStore").default;
var TokenSearchService = require("Project/Actions/Tokens/Search/TokenSearchService").default;
module.exports = function (project, userList, currentUserId, rootDataStore, isDummyProject, angularSyncServices) {
    var maker = function (constructor, extraArgs) {
        if (extraArgs === void 0) { extraArgs = {}; }
        return function (make) { return make(constructor, extraArgs); };
    };
    var invoker = function (func, extraArgs, context) {
        if (extraArgs === void 0) { extraArgs = {}; }
        if (context === void 0) { context = null; }
        return function (invoke) { return invoke(func, context, extraArgs); };
    };
    var projectEventsEmitter = new Emitter();
    projectEventsEmitter.setMaxListeners(30);
    var injectables = {
        project: project,
        activeUserStore: new ActiveUserStore(userList, currentUserId),
        moduleList: function (make) {
            var moduleListCtrl = make(require("./Modules/Modules"));
            return moduleListCtrl.getList();
        },
        LineItemEditor: function () {
            return require("./QwilrEditor/QwilrEditorComponent").default;
        },
        Editor: function (usingFeature, project) {
            if (!usingFeature(FeatureFlag.OptOutNewEditor)) {
                return require("./QwilrEditor/QwilrEditor").default;
            }
            else {
                return require("./QwilrEditorV0/QwilrEditor");
            }
        },
        EditorModel: function () {
            return require("./QwilrEditorV0/QwilrEditorModel");
        },
        BlockMenuController: function () {
            return require("./BlockNew/Menu/Menu");
        },
        BlockController: function () {
            return require("./BlockNew/Block").default;
        },
        blockWiring: function () {
            return require("./BlockNew/BlockWiring");
        },
        blockTypes: require("./BlockNew/BlockTypes").default,
        blockList: maker(require("./BlockNew/BlockList/BlockList").default),
        blockCreator: maker(require("./BlockNew/BlockCreator/BlockCreator").BlockCreator),
        blockBackwardsCompatibility: function () {
            return require("./BlockNew/BackwardsCompatibility");
        },
        blockBuilder: invoker(require("./BlockBuilder")),
        savedBlockOpener: maker(require("./BlockNew/SavedBlocks/SavedBlockOpener").default),
        projectNavigation: maker(require("./Service/Navigation").default),
        currenciesUiDelegate: maker(require("./Service/CurrenciesUiDelegate").default),
        payNowDisabler: maker(require("./Service/PayNowDisabler").default),
        currencySetter: maker(require("./Service/CurrencySetter").default),
        projectTotal: maker(require("QuoteV2/ProjectTotal").default),
        quoteDataStore: maker(require("QuoteV2/QuoteDataStore").QuoteDataStore),
        projectCurrencies: maker(require("QuoteV2/ProjectCurrencies").default),
        quoteBlocksList: maker(require("./Modules/QuoteV2/QuoteBlocksList").QuoteBlocksList),
        variableEditor: maker(require("./Modules/QuoteV2/VariableEditor/VariableEditor")),
        userActivities: {
            timeOfLastAction: Date.now(),
        },
        collaboration: maker(require("./Collaboration/Collaboration").default),
        projectEvents: projectEventsEmitter,
        isBlankProject: require("./Utils/IsBlankProject"),
        markAcceptEnabled: require("./Utils/MarkAcceptEnabled"),
        hasBlockType: require("./Utils/HasBlockType"),
        projectScrollTo: invoker(require("./ProjectScrollTo")),
        StyleButton: function () {
            return require("./BlockNew/Menu/Settings/StyleButton/StyleButtonAngular").default;
        },
        BlockControls: function () {
            return require("./BlockNew/Menu/BlockControls/BlockControlsAngular").default;
        },
        styleModalService: invoker(require("Common/ModalAngular/StyleModals/StyleModalService").default),
        overlayStackReact: maker(OverlayStack),
        tokenReplacementStore: function (projectStore, project, apiBaseUrl, telemetry, blockFetcher, collaboration) {
            var tokenApiService = new TokenApiService(apiBaseUrl);
            var blockSaver = new BlockSaver_1.BlockSaver(apiBaseUrl, collaboration, projectStore);
            return new TokenReplacementStore_1.TokenReplacementStore(projectStore, project, tokenApiService, telemetry, blockFetcher, blockSaver);
        },
        tokenViewStore: function (tokenStore, projectStore, modal, notifyUser) {
            return new TokenViewStore_1.TokenViewStore(tokenStore, projectStore, modal, notifyUser);
        },
        tokenSearchService: function (tokenStore, projectStore, usingFeature) {
            return new TokenSearchService(tokenStore, projectStore, usingFeature);
        },
        tokenStore: function (projectStore, apiBaseUrl, tokensTelemetry) {
            var tokenApiService = new TokenApiService(apiBaseUrl);
            return new TokenStore(tokenApiService, tokensTelemetry, projectStore);
        },
        tokensTelemetry: maker(require("Tokens/Telemetry").TokensTelemetry),
        projectStore: function (project, projectApiService, pageResourceApiService, user, projectTelemetry, genericHost) {
            if (isDummyProject) {
                return {
                    isEditable: true,
                    isBlockLocked: function () {
                        return false;
                    },
                    getBlockIndex: function () {
                        return 0;
                    },
                };
            }
            return new ProjectStore(project, projectApiService, pageResourceApiService, user, projectTelemetry, rootDataStore, genericHost, angularSyncServices);
        },
        projectViewStore: function (project, overlayStackReact) {
            return new ProjectViewStore(overlayStackReact);
        },
        pdfPreviewOverlay: function (di, projectStore, projectPreviewer, projectLinkRetriever) {
            return di.make(require("PdfPreviewOverlay/PdfPreviewOverlay").PdfPreviewOverlay, {
                previewLink: projectPreviewer.getPdfPreviewLink(projectStore),
                pdfUrl: projectLinkRetriever.getPdfUrlForProject(projectStore),
                oneTimeTokenUrl: projectLinkRetriever.getOneTimeTokenUrl(projectStore._id, projectStore.links[0].secret),
            });
        },
        projectKeyboardShortcuts: maker(require("./Service/KeyboardShortcuts").default),
        activeCommentThreadStore: function () {
            return new ActiveCommentThreadStore();
        },
        insertBlock: require("./Service/InsertBlock/InsertBlock").default,
        dragStore: maker(require("./Stores/DragStore/DragStore").DragStore),
        dropLocationStore: maker(require("./Stores/DropLocationStore/DropLocationStore").DropLocationStore),
        contentSidebarViewStore: maker(require("./ContentSidebar/ContentSidebarViewStore").default),
        contentSidebarLibraryViewStore: maker(require("./ContentSidebar/Library/ContentSidebarLibraryViewStore").default),
        sidebarExploreViewStore: maker(require("./ContentSidebar/Explore/SidebarExploreViewStore").default),
        savedBlockDragScroll: maker(require("./ContentSidebar/SavedBlockDragScroll").SavedBlockDragScroll),
        savedBlockDragDrop: maker(require("./ContentSidebar/SavedBlockDragDrop").SavedBlockDragDrop),
        dropzonesStore: maker(require("./BlockNew/SavedBlockDropzone/DropzonesStore").DropzonesStore),
        sidebarViewStore: maker(require("./Stores/SidebarViewStore/SidebarViewStore").SidebarViewStore),
        editorState: maker(require("Common/Telemetry/EditorState").EditorState),
        clickToAddStore: maker(require("./QwilrEditor/ClickToAdd/ClickToAddStore").ClickToAddStore),
        contentSidebarTelemetry: maker(require("./ContentSidebar/ContentSidebarTelemetry").ContentSidebarTelemetry),
        roiStepsOrchestrator: maker(require("./QwilrEditor/Widgets/ROICalculatorWidget/ROISteps/ROIStepsOrchestrator").default),
        mountReactComponent: invoker(require("MountReactComponent/MountReactComponent").mountReactComponent),
        projectDimensionStore: maker(require("./Stores/ProjectDimensionStore/ProjectDimensionStore").ProjectDimensionStore),
        syntheticSelectionStore: maker(require("./SyntheticSelection").SyntheticSelectionStore),
        selectionBoxStore: maker(require("./SelectionBoxStore").SelectionBoxStore),
        editorBlockIdStore: maker(require("./EditorBlockIdStore").EditorBlockIdStore),
    };
    return injectables;
};

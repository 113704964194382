"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var SidebarExplore_1 = require("./SidebarExplore");
var SidebarExploreViewStore = (function () {
    function SidebarExploreViewStore() {
        var _this = this;
        this.setTab = function (tab) {
            _this._currentTab = tab;
        };
        this._currentTab = SidebarExplore_1.SidebarExploreTab.All;
    }
    Object.defineProperty(SidebarExploreViewStore.prototype, "currentTab", {
        get: function () {
            return this._currentTab;
        },
        enumerable: false,
        configurable: true
    });
    var _a;
    __decorate([
        mobx_1.observable,
        __metadata("design:type", typeof (_a = typeof SidebarExplore_1.SidebarExploreTab !== "undefined" && SidebarExplore_1.SidebarExploreTab) === "function" ? _a : Object)
    ], SidebarExploreViewStore.prototype, "_currentTab", void 0);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Object)
    ], SidebarExploreViewStore.prototype, "setTab", void 0);
    return SidebarExploreViewStore;
}());
exports.default = SidebarExploreViewStore;

(function () {
  module.exports = function (createUi, make, currency, rate, rateList, apiUpdater, telemetry, $timeout, notifyUser) {
    var units;
    units = ["Hour", "Day", "Week", "Month", "Quarter", "Year"];
    return (this.ui = createUi(require("Project/Modules/Quote/RatesPanel/Rate/Rate.html"), {
      units: units,
      isLast: function () {
        return _.findIndex(rateList, rate) === rateList.length - 1;
      },
      rate: rate,
      currencySymbol: currency().symbol,
      onInputChange: (function (_this) {
        return function () {
          return apiUpdater.debouncedApiUpdate();
        };
      })(this),
      removeRate: (function (_this) {
        return function () {
          if (rateList.length === 1) {
            notifyUser.warn("Oops, you need at least one rate.");
            return;
          }
          _.remove(rateList, rate);
          apiUpdater.debouncedApiUpdate();
          return telemetry.trackOnly("Quote Block Rate Deleted", {
            quote_block_rate_number: rateList.length,
          });
        };
      })(this),
    }));
  };
}.call(this));

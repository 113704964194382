"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectedGateways = void 0;
var react_1 = __importStar(require("react"));
var usePaymentGateways_1 = require("Library/PaymentsDashboard/PaymentSettings/usePaymentGateways");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var styles = __importStar(require("./ConnectedGateways.css"));
var DisconnectGateway_1 = require("./DisconnectGateway");
var GeneralSettings_1 = require("Library/PaymentsDashboard/PaymentSettings/GeneralSettings");
var PaymentMethodsList_1 = require("QwilrPay/PaymentMethodsList");
var ConnectedGateways = function () {
    var _a = (0, usePaymentGateways_1.usePaymentGateway)(), gateways = _a.gateways, deleter = _a.deleter, updater = _a.updater;
    return (react_1.default.createElement(kaleidoscope_1.Stack, { gap: "s", direction: "vertical" }, gateways.map(function (gateway) { return (react_1.default.createElement(Gateway, { key: gateway.id, id: gateway.id, countryCode: gateway.countryCode, defaultGateway: gateway.defaultGateway, name: gateway.name, platform: gateway.platform, settings: gateway.settings, status: gateway.status, onChange: function (updates) { return updater(gateway.id, updates); }, onDelete: function () { return deleter(gateway.id); } })); })));
};
exports.ConnectedGateways = ConnectedGateways;
var onInsideClick = function (event, callback) {
    event.stopPropagation();
    if (callback) {
        return callback();
    }
};
var Gateway = function (props) {
    var _a = __read((0, react_1.useState)(props.defaultGateway), 2), expanded = _a[0], setExpanded = _a[1];
    var _b = __read((0, react_1.useState)(false), 2), showDisconnectModal = _b[0], setShowDisconnectModal = _b[1];
    return (react_1.default.createElement(kaleidoscope_1.Card, { className: styles.gatewayWrapper },
        react_1.default.createElement(kaleidoscope_1.ButtonCard, { "aria-expanded": expanded, onClick: function () { return setExpanded(!expanded); }, "aria-controls": "payment-gateway", className: styles.gatewayItem },
            react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", justify: "spaceBetween", align: "center", padding: "m", paddingLeft: "l" },
                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", gap: "s" },
                    react_1.default.createElement(icons_1.QwilrPay, { className: styles.gatewayIcon }),
                    react_1.default.createElement(kaleidoscope_1.Text, { strong: true, size: "m" }, props.name),
                    props.defaultGateway && (react_1.default.createElement(kaleidoscope_1.Text, { strong: true, size: "xs", className: styles.defaultGateway }, "Default"))),
                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", gap: "s" },
                    props.status == "ready" ? (react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal" },
                        react_1.default.createElement(icons_1.Tick, { className: styles.connectedIcon }),
                        react_1.default.createElement(kaleidoscope_1.Text, { className: styles.connected }, "Connected"))) : (react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal" },
                        react_1.default.createElement(icons_1.Warning, { className: styles.onboardingIcon }),
                        react_1.default.createElement(kaleidoscope_1.Text, { className: styles.onboarding }, "Onboarding"))),
                    expanded ? react_1.default.createElement(icons_1.ChevronDown, null) : react_1.default.createElement(icons_1.ChevronRight, null),
                    react_1.default.createElement(kaleidoscope_1.OptionMenu, { size: "small", button: react_1.default.createElement(kaleidoscope_1.IconButton, { icon: react_1.default.createElement(icons_1.OptionsVertical, null), "aria-label": "options", onClick: function (e) {
                                onInsideClick(e);
                            } }), autoStack: true },
                        react_1.default.createElement(kaleidoscope_1.OptionMenuItem, { onClick: function (e) { return onInsideClick(e, function () { return setShowDisconnectModal(true); }); } }, "Disconnect"))))),
        react_1.default.createElement(kaleidoscope_1.Expandable, { expanded: expanded, timeout: kaleidoscope_1.AnimationDuration.Long }, function (_a) {
            var expandableElementRef = _a.expandableElementRef;
            return (react_1.default.createElement(kaleidoscope_1.Stack, { ref: expandableElementRef, zIndex: 99 },
                react_1.default.createElement(kaleidoscope_1.Stack, { gap: "s", padding: "s", paddingBottom: "l" },
                    react_1.default.createElement(GeneralSettings_1.GeneralSettings, { data: props.settings, onUpdate: function (settings) { return props.onChange({ settings: settings }); } }),
                    react_1.default.createElement(PaymentMethodsList_1.PaymentMethodsList, { gatewayId: props.id }))));
        }),
        react_1.default.createElement(DisconnectGateway_1.DisconnectGateway, { deleteGateway: props.onDelete, showModal: showDisconnectModal, closeModal: function () { return setShowDisconnectModal(false); } })));
};

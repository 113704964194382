(function () {
  module.exports = function (blockList, $log, project) {
    return function () {
      if (blockList.getLength() === 0) {
        $log.error("Got a zero length block list array for project: " + project._id);
        return true;
      }

      return false;
    };
  };
}.call(this));

/*
  Defines the Video Block UI.
  @param block [Object] - the data model for this block.
 */

(function () {
  var blockConfigUtil;

  blockConfigUtil = require("@CommonFrontendBackend/StyleServiceV2/Config/blockConfigUtil");

  module.exports = function (
    block,
    make,
    createUi,
    project,
    viewState,
    Editor,
    $sce,
    $http,
    $timeout,
    telemetry,
    videoEmbedUrlGenerator,
    iframeGenerator,
    stringManipulater,
    notifyUser,
    projectStore,
  ) {
    var base, headerText, validUrl;
    if ((base = block.settings).size == null) {
      base.size = "standard";
    }
    viewState.displayMode = block.url.length;
    validUrl = block.url.length;
    headerText = make(Editor, {
      model: block.contentAreas.header,
      options: {},
      configuration: "standard",
    });
    return (this.ui = createUi(require("./Video.html"), {
      model: block,
      hasEditAccess: projectStore.isEditable,
      viewState: viewState,
      headerText: headerText.ui,
      url: block.url || "",
      embedUrl: block.url,
      containsProviderUrl: false,
      isValidEmbedUrl: false,
      videoSizeOptions: [
        {
          label: "Small",
          value: "small",
        },
        {
          label: "Medium",
          value: "medium",
        },
        {
          label: "Large",
          value: "standard",
        },
      ],
      selectedSizeOption: block.settings.size,
      selectSizeOption: (function (_this) {
        return function (sizeValue) {
          _this.ui.selectedSizeOption = sizeValue;
          return (block.settings.size = sizeValue);
        };
      })(this),
      showEditControls: (function (_this) {
        return function () {
          var badLink;
          badLink =
            _this.ui.containsProviderUrl === false ||
            _this.ui.isValidEmbedUrl === false ||
            (_this.ui.viewState.displayMode && _this.ui.model.embedUrl.length === 0);
          return !_this.ui.viewState.displayMode || badLink;
        };
      })(this),
      displayClass: (function (_this) {
        return function () {
          if (_this.ui.viewState.displayMode) {
            return "display-mode";
          } else {
            return "edit-mode";
          }
        };
      })(this),
      gotoDisplayMode: (function (_this) {
        return function () {
          _this.ui.viewState.displayMode = true;
          return _this.ui.updateEmbedUrl(block.url).then(function () {
            return _this.ui.refreshIframe(_this.ui.model.embedUrl);
          });
        };
      })(this),
      getExplainer: function () {
        return (
          "Paste in a link to a " +
          stringManipulater.arrayToReadableString(videoEmbedUrlGenerator.videoProviderNames, "or") +
          " video."
        );
      },
      refreshIframe: (function (_this) {
        return function (embedUrl) {
          var iframeHTML;
          if (embedUrl.length === 0) {
            return;
          }
          iframeHTML = iframeGenerator.getVideoBlockIframeHTML(embedUrl);
          $("iframe", _this.ui.iframeVideoPlayer).replaceWith(iframeHTML);
        };
      })(this),
      urlChange: (function (_this) {
        return function (rawUrl) {
          return (_this.ui.containsProviderUrl = videoEmbedUrlGenerator.containsProviderUrl(rawUrl));
        };
      })(this),
      updateEmbedUrl: (function (_this) {
        return function (rawUrl, suppressErrorMsgs) {
          return videoEmbedUrlGenerator
            .getEmbedUrl(rawUrl, _this.ui.model.settings)
            .then(function (embedUrl) {
              if (_this.ui.model.embedUrl !== embedUrl) {
                telemetry.trackOnly("Video Block Video Added", {});
              }
              _this.ui.model.embedUrl = embedUrl;
              return (_this.ui.isValidEmbedUrl = true);
            })
            ["catch"](function (err) {
              _this.ui.isValidEmbedUrl = false;
              if (!suppressErrorMsgs && err != null) {
                notifyUser.error(err.message);
              }
              return err;
            });
        };
      })(this),
      $init: (function (_this) {
        return function (scope) {
          return $timeout(function () {
            scope.ui.urlChange(scope.ui.model.url);
            return scope.ui.updateEmbedUrl(scope.ui.model.url, true).then(function () {
              return scope.ui.refreshIframe(scope.ui.model.embedUrl);
            });
          });
        };
      })(this),
    }));
  };
}.call(this));

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClickToAddStore = void 0;
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var WidgetManager_1 = require("../WidgetManager");
var InvalidReason_1 = require("./InvalidReason");
var lodash_1 = require("lodash");
var Types_1 = require("Project/ContentSidebar/Types");
var ClickToAddStore = (function () {
    function ClickToAddStore(notifyUser) {
        var _this = this;
        this.notifyUser = notifyUser;
        this.updateLastKnownSelection = function (lastKnownEntry) {
            _this.lastKnownEntry = lastKnownEntry;
        };
        this.clickToAdd = function (_a) {
            var mediaData = _a.mediaData, mediaSource = _a.mediaSource, telemetryData = _a.telemetryData;
            var mediaType = mediaData.type === Types_1.AssetType.Video ? "video" : "image";
            if ((0, lodash_1.isNil)(_this.lastKnownEntry)) {
                _this.notifyForInvalidClickToAdd(InvalidReason_1.InvalidReason.EditorNoLongerExists, mediaType);
                return;
            }
            if (_this.lastKnownEntry.type === "backgroundBucket") {
                _this.lastKnownEntry.onSelectMedia(mediaData, mediaSource);
                return;
            }
            var _b = _this.lastKnownEntry, location = _b.location, widgetManager = _b.widgetManager;
            widgetManager.triggerEvent(new WidgetManager_1.WidgetEvent(mediaType, {
                action: "clickToAdd",
                location: location,
                mediaData: mediaData,
                mediaSource: mediaSource,
                telemetryData: telemetryData,
            }));
        };
        this.deregisterEditor = function (editor) {
            if ((0, lodash_1.isNil)(_this.lastKnownEntry)) {
                return;
            }
            if (_this.lastKnownEntry.type === "editor" && _this.lastKnownEntry.widgetManager.editor === editor) {
                _this.clearCurrentEntry();
            }
        };
        this.deregisterBackgroundBucket = function (onSelectMedia) {
            if ((0, lodash_1.isNil)(_this.lastKnownEntry)) {
                return;
            }
            if (_this.lastKnownEntry.type === "backgroundBucket" && _this.lastKnownEntry.onSelectMedia === onSelectMedia) {
                _this.clearCurrentEntry();
            }
        };
        this.notifyForInvalidClickToAdd = function (invalidReason, assetType) {
            if (assetType === void 0) { assetType = "image"; }
            _this.notifyUser.showNotification("Select a location in the editor to add this ".concat(assetType, "."), kaleidoscope_1.NotificationType.Info);
            console.error("Click to add failed because of reason: ", invalidReason);
        };
        this.clearCurrentEntry = function () {
            _this.lastKnownEntry = null;
        };
    }
    return ClickToAddStore;
}());
exports.ClickToAddStore = ClickToAddStore;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Settings_1 = require("./Settings");
var AIFeatures = (function () {
    function AIFeatures(createUi, mountReactComponent, rootDataStore) {
        this.ui = createUi(require("./AIFeatures.html"), {
            $init: function ($scope) {
                $scope.ui.settings = (0, Settings_1.mountAiFeaturesSettings)(mountReactComponent, {
                    accountDataStore: rootDataStore.getAccountDataStore(),
                });
            },
        });
    }
    return AIFeatures;
}());
exports.default = AIFeatures;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackgroundStyleButton = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var BackgroundStylePanel_1 = require("Project/BlockNew/Menu/Settings/StyleButton/BackgroundStyleButton/BackgroundStylePanel");
var inversify_react_1 = require("inversify-react");
var Configuration_1 = require("Styles/Configuration/Configuration");
var BackgroundAssetBucket_1 = require("./BackgroundAssetBucket");
var lodash_1 = require("lodash");
var DragStore_1 = require("Project/Stores/DragStore/DragStore");
var assertUnreachable_1 = require("@CommonFrontendBackend/Utils/assertUnreachable");
var mobx_1 = require("mobx");
var TelemetryEnums_1 = require("@CommonFrontend/Telemetry/TelemetryEnums");
var ContentSidebarViewStore_1 = require("Project/ContentSidebar/ContentSidebarViewStore");
var SidebarExplore_1 = require("Project/ContentSidebar/Explore/SidebarExplore");
var ContentSidebarLibraryViewStore_1 = require("Project/ContentSidebar/Library/ContentSidebarLibraryViewStore");
var CurrentBackgroundIcon_1 = require("./CurrentBackgroundIcon");
var bucketStateReducer = function (state, action) {
    switch (action.type) {
        case "open-background-style":
            return __assign(__assign({}, state), { isOpen: true, mode: "manual", panel: "backgroundStyle" });
        case "open-bucket":
            return __assign(__assign({}, state), { isOpen: true, panel: action.panel, mode: action.mode });
        case "close":
            return __assign(__assign({}, state), { isOpen: false });
        case "close-complete":
            return __assign(__assign({}, state), { mode: "auto" });
        default:
            return (0, assertUnreachable_1.assertUnreachable)(action, "Unhandled action ".concat(action));
    }
};
exports.BackgroundStyleButton = (0, react_1.forwardRef)(function (_a, ref) {
    var allowMediaBackgrounds = _a.allowMediaBackgrounds;
    var blockStore = (0, inversify_react_1.useInjection)("blockStore");
    var dragStore = (0, inversify_react_1.useOptionalInjection)("dragStore");
    var isSidebarOpened = (0, inversify_react_1.useInjection)("isSidebarOpened");
    var projectViewStore = (0, inversify_react_1.useInjection)("projectViewStore");
    var contentSidebarViewStore = (0, inversify_react_1.useInjection)("contentSidebarViewStore");
    var popoverId = "block-background-popover-".concat(blockStore._id);
    var _b = __read((0, react_1.useReducer)(bucketStateReducer, {
        isOpen: false,
        mode: "auto",
        panel: "backgroundStyle",
    }), 2), state = _b[0], dispatch = _b[1];
    (0, react_1.useImperativeHandle)(ref, function () {
        return {
            openBackgroundStyle: function () { return dispatch({ type: "open-background-style" }); },
        };
    }, [dispatch]);
    var isBucketVisible = state.isOpen && ["imageBucket", "videoBucket"].includes(state.panel);
    var openSidebar = (0, react_1.useCallback)(function (tab) {
        if (blockStore.isSavedBlock)
            return;
        if (tab === "video") {
            contentSidebarViewStore.open(TelemetryEnums_1.SidebarOpenOrigin.BlockStyleMenu, {
                view: ContentSidebarViewStore_1.SidebarView.Explore,
                tab: SidebarExplore_1.SidebarExploreTab.Videos,
            });
        }
        if (tab === "image") {
            if (contentSidebarViewStore.savedView === ContentSidebarViewStore_1.SidebarView.Library) {
                contentSidebarViewStore.open(TelemetryEnums_1.SidebarOpenOrigin.BlockStyleMenu, {
                    view: ContentSidebarViewStore_1.SidebarView.Library,
                    tab: ContentSidebarLibraryViewStore_1.ContentSidebarTab.Image,
                });
            }
            else {
                contentSidebarViewStore.open(TelemetryEnums_1.SidebarOpenOrigin.BlockStyleMenu, {
                    view: ContentSidebarViewStore_1.SidebarView.Explore,
                    tab: SidebarExplore_1.SidebarExploreTab.Images,
                });
            }
        }
    }, [contentSidebarViewStore, blockStore.isSavedBlock]);
    (0, react_1.useEffect)(function () {
        return (0, mobx_1.autorun)(function () {
            if (projectViewStore.promptForBackgroundBlockId === blockStore._id) {
                dispatch({ type: "open-bucket", panel: "imageBucket", mode: "manual" });
                openSidebar("image");
                projectViewStore.clearPromptForBackgroundBlockId();
            }
        });
    }, [blockStore, projectViewStore, openSidebar]);
    (0, react_1.useEffect)(function () {
        if (!state.isOpen && state.mode === "manual") {
            var timer_1 = setTimeout(function () {
                dispatch({ type: "close-complete" });
            }, kaleidoscope_1.AnimationDuration.Medium);
            return function () {
                clearTimeout(timer_1);
            };
        }
        return;
    }, [state.isOpen, state.mode]);
    var handleChangePopover = function (isOpen) {
        if (!isOpen && isBucketVisible) {
            return;
        }
        if (isOpen) {
            dispatch({ type: "open-background-style" });
        }
        else {
            dispatch({ type: "close" });
        }
    };
    (0, react_1.useEffect)(function () {
        if (isBucketVisible) {
            var mousedownListener_1 = function (event) {
                if (event.target instanceof HTMLElement &&
                    !event.target.closest("[data-content-sidebar],#popover-root,.overlay-stack")) {
                    dispatch({ type: "close" });
                }
            };
            window.addEventListener("mousedown", mousedownListener_1);
            return function () {
                window.removeEventListener("mousedown", mousedownListener_1);
            };
        }
        return;
    }, [isBucketVisible]);
    var dragPresenceCounter = (0, react_1.useRef)(0);
    (0, react_1.useEffect)(function () {
        if (!allowMediaBackgrounds)
            return;
        if (state.mode !== "auto")
            return;
        var isEventWithinCurrentBlock = function (event) {
            return event.target instanceof HTMLElement &&
                !!event.target.closest("[data-id=\"".concat(blockStore._id, "\"] .block-container, #").concat(popoverId));
        };
        var dragEnterHandler = function (event) {
            var _a, _b;
            if (isEventWithinCurrentBlock(event)) {
                dragPresenceCounter.current += 1;
                if (dragPresenceCounter.current === 1) {
                    var isDraggingImage = !!(dragStore === null || dragStore === void 0 ? void 0 : dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.SidebarImage)) ||
                        (((_a = event.dataTransfer) === null || _a === void 0 ? void 0 : _a.items.length) === 1 && ((_b = event.dataTransfer.items[0]) === null || _b === void 0 ? void 0 : _b.type.startsWith("image/")));
                    var isDraggingVideo = dragStore === null || dragStore === void 0 ? void 0 : dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.SidebarVideo);
                    if (isDraggingImage || isDraggingVideo) {
                        dispatch({
                            type: "open-bucket",
                            panel: isDraggingImage ? "imageBucket" : "videoBucket",
                            mode: "auto",
                        });
                    }
                }
            }
        };
        var dragLeaveHandler = function (event) {
            if (isEventWithinCurrentBlock(event)) {
                dragPresenceCounter.current -= 1;
                (0, lodash_1.defer)(function () {
                    if (dragPresenceCounter.current === 0) {
                        dispatch({ type: "close" });
                    }
                });
            }
        };
        var resetHandler = function () {
            dragPresenceCounter.current = 0;
            dispatch({ type: "close" });
        };
        window.addEventListener("dragenter", dragEnterHandler);
        window.addEventListener("dragleave", dragLeaveHandler);
        window.addEventListener("dragend", resetHandler);
        window.addEventListener("drop", resetHandler);
        return function () {
            window.removeEventListener("dragenter", dragEnterHandler);
            window.removeEventListener("dragleave", dragLeaveHandler);
            window.removeEventListener("dragend", resetHandler);
            window.removeEventListener("drop", resetHandler);
        };
    }, [blockStore._id, popoverId, dragStore, state.mode, allowMediaBackgrounds]);
    var handleClose = (0, react_1.useCallback)(function () { return dispatch({ type: "close" }); }, [dispatch]);
    return (react_1.default.createElement(kaleidoscope_1.Layer, { offset: blockStore.isSavedBlock ? Configuration_1.overlayStackZIndex - 1 : Configuration_1.blockMenuZIndex - 1 }, function () { return (react_1.default.createElement(kaleidoscope_1.Popover, { id: popoverId, button: function (buttonProps) { return (react_1.default.createElement(kaleidoscope_1.IconButton, __assign({}, buttonProps, { isRound: true, isIconOnly: true, size: kaleidoscope_1.ButtonSize.Small, icon: react_1.default.createElement(CurrentBackgroundIcon_1.CurrentBackgroundIcon, { size: "small" }), tooltip: {
                content: "Background",
                position: kaleidoscope_1.TooltipPosition.Top,
            } }))); }, caret: state.panel === "backgroundStyle", padding: "none", isOpen: state.isOpen, size: state.panel === "backgroundStyle" ? kaleidoscope_1.PopoverSize.Medium : kaleidoscope_1.PopoverSize.Auto, onChange: handleChangePopover, autoStack: true, outerMargin: {
            left: isSidebarOpened() && !blockStore.isSavedBlock ? 360 + 16 : 16,
            top: 16,
            bottom: 16,
            right: 16,
        }, offset: state.panel === "backgroundStyle" ? undefined : 18 },
        state.panel === "backgroundStyle" && (react_1.default.createElement(BackgroundStylePanel_1.BackgroundStylePanel, { allowMediaBackgrounds: allowMediaBackgrounds, onSelectBackgroundAsset: function (type) {
                dispatch({
                    type: "open-bucket",
                    panel: {
                        image: "imageBucket",
                        video: "videoBucket",
                    }[type],
                    mode: "manual",
                });
                openSidebar(type);
            } })),
        (state.panel === "imageBucket" || state.panel === "videoBucket") && (react_1.default.createElement(BackgroundAssetBucket_1.BackgroundAssetBucket, { size: state.mode === "auto" ? "mini" : "standard", onClose: handleClose })))); }));
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FeatureFlag_1 = require("@CommonFrontendBackend/FeatureFlag");
function default_1(make, StyleButton, BlockControls, usingFeature) {
    var mediaButton = make(require("./Controls/MediaButton/MediaButton"));
    var styleButton = make(StyleButton);
    var blockControls = make(BlockControls);
    var items = [
        {
            name: "Background",
            description: "Choose an image or video for this splash.",
            ctrl: make(require("./Controls/Background/Background")),
        },
    ];
    if (!(0, FeatureFlag_1.isUsingTCDC)(usingFeature)) {
        items.push({
            name: "Size",
            description: "Set how large your splash.",
            ctrl: make(require("./Controls/Size/Size")),
        });
    }
    var superpowers = [
        {
            name: "Style library",
            ctrl: styleButton,
            selectable: false,
            select: styleButton.onClick,
            deselect: styleButton.onClose,
            overflow: true,
            doNotWrapControl: true,
        },
        {
            name: "Block Controls",
            ctrl: blockControls,
            doNotWrapControl: true,
            visibleOnViewOnlyBlocks: true,
        },
        {
            name: "Swap splash",
            ctrl: mediaButton,
            selectable: false,
            select: mediaButton.onClick,
        },
    ];
    return {
        settings: {
            useItems: ["foldable", "navigationMenu"],
            items: items,
            superpowers: superpowers,
        },
    };
}
exports.default = default_1;

import "Application/Project/QwilrEditor/DragPreviewImageHost.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/DragPreviewImageHost.css.ts.vanilla.css\",\"source\":\"Ll8xcnVhYmNyMCB7CiAgcGFkZGluZzogMCAhaW1wb3J0YW50OwogIG1hcmdpbjogMCAhaW1wb3J0YW50OwogIGJveC1zaGFkb3c6IG5vbmUgIWltcG9ydGFudDsKfQouXzFydWFiY3IyIHsKICBwb3NpdGlvbjogZml4ZWQ7CiAgd2lkdGg6IHZhcigtLV8xcnVhYmNyMSk7CiAgdG9wOiAwOwogIG91dGxpbmU6IDFweCBzb2xpZCB0cmFuc3BhcmVudDsKICBsZWZ0OiAyMDB2dzsKfQpfOjotd2Via2l0LWZ1bGwtcGFnZS1tZWRpYSwgXzpmdXR1cmUsIDpyb290IC5fMXJ1YWJjcjI6OmJlZm9yZSB7CiAgY29udGVudDogIiI7CiAgcG9zaXRpb246IGFic29sdXRlOwogIHdpZHRoOiAxcHg7CiAgaGVpZ2h0OiAxcHg7CiAgbGVmdDogY2FsYygtMTAwdncgLSAxcHgpOwogIHRvcDogMDsKICBiYWNrZ3JvdW5kLWNvbG9yOiB0cmFuc3BhcmVudDsKfQouXzFydWFiY3IyID4gKjpmaXJzdC1jaGlsZCB7CiAgcGFkZGluZy10b3A6IDAgIWltcG9ydGFudDsKICBtYXJnaW4tdG9wOiAwICFpbXBvcnRhbnQ7Cn0KLl8xcnVhYmNyMiAuaW1hZ2Utd2lkZ2V0X19naG9zdCB7CiAgZGlzcGxheTogbm9uZTsKfQ==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "Application/Project/QwilrEditor/Placeholder/EditorPlaceholder.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/Placeholder/EditorPlaceholder.css.ts.vanilla.css\",\"source\":\"Ll83MDZ5dTcyIHsKICBwb3NpdGlvbjogYWJzb2x1dGU7CiAgaW5zZXQ6IDA7CiAgb3BhY2l0eTogMC4zMzMzMzMzMzMzMzMzMzMzOwogIHdoaXRlLXNwYWNlOiBub3dyYXA7CiAgb3ZlcmZsb3c6IGhpZGRlbjsKICB0ZXh0LW92ZXJmbG93OiBlbGxpcHNpczsKICBwb2ludGVyLWV2ZW50czogbm9uZTsKICB1c2VyLXNlbGVjdDogbm9uZTsKICBjb2xvcjogdmFyKC0tXzcwNnl1NzApOwogIHBhZGRpbmctbGVmdDogdmFyKC0tXzcwNnl1NzEpOwp9\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VVy27bMBC8+ysWBgJYjRmI8ktRkGN/oLeiKAxKpG0mMqmQlGOnyL8XoihbpJyiaeGLuZzdWc4+dLfGgr/lbxTDrxFAJTU3XIoMFCuJ4Qf2MALYMb7dmQxwHN88jN5Hd51TkmU520jFrHMhhWHCZDAeP3ixSK5lWRsbq2Qbk0FcHb3AC2d45dTsOiIAo4jQG6n2Wfu3JIZ9nyALj7xMZllGNoapv0/kzJ06bi8zL5Eez9wSfHS76N9uuEEuEw+0tCDKdVWSUwZclFwwlJeyeO7nRWojG78flBiCNCtZ0TwCKS62P9tn1kpLlUEluTBMXcD2SIqmfOhNCvZz6oI0+iEhKXscs5LtmTBjPxRlG1KX5hLKwZA5VexxbNjRfPUcEVqv4uWpXuEM0la6LlYDtvITSrnYolbftC82Sj21C1IWk0ZUuG1wfoVpx+fOheuJiiircP8uPdfxYru/YiNn2/UeGSgMwIVmxqU6ORA16TFEcAu+iUQRfAGEg6d8fmxyeUR6R6h8zSC2v8SpLcsmQ582ngaGIopsFFI8b5WsBUXOL5DQHh29Rbg4tFbEdp9er3Ferp6eMK4jd8mIbuqrDRGUKHpB6Gg6oPzPgL0ShEq3MinKFFKE8lqHkDxUJY26uqia5IVKIOi285AKKZhXQjbsRrXNJ8lsAck8hmS+8ku+uY7Hi3tI8BySxdzHbz/ALwEvZpAsEh/OA3javb0V5JvVw24jJcuLnttoMCPplRmZN7Ye27P7VtihQOzAhNGdRufyoIGX1/UOFV9vy7B7wxEY1GP/uaV69pNDnW0KaM+PEy6gJPnUKp+mgNOmULMIcHwz7U+OX4vqX0Imfwypg49zfzMYWYV6KauXap/cX6o+7CUKWD6/lix5W0NpjNy7Q7vdu6+42+4h+Uej1z5WVqTg5uQCdjy9bE0gswoB9RCAcBw05SEAvWSwDCCvQwgOe/s0xNhBev8NxwCx02AJAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var ringDataAttribute = 'data-selection-ring';
export var interactiveElementSelector = '[data-interactive-zone]';
export var textElementSelector = '[data-element-type="textElement"]';
export var childSelector = '[data-slate-node="element"]';
export var selectionRingWidth = 2;
export var outsideRingOffset = 8;
export var customRingColor = 'var(--_1nizbzd0)';
export var wrapper = _7a468({defaultClassName:'_1nizbzd1',variantClassNames:{extraSpaceBeforeFirstWidgetInBlock:{true:'_1nizbzd2'},extraSpaceAfterLastWidgetInBlock:{true:'_1nizbzd3'},widgetWidth:{'full-width':'_1nizbzd4','fit-widget':'_1nizbzd5'},isInline:{true:'_1nizbzd6',false:'_1nizbzd7'}},defaultVariants:{},compoundVariants:[]});
export var ringToWidgetOffset = 'var(--_1nizbzd9)';
export var ringToHitboxInset = 'var(--_1nizbzda)';
export var customRingBorderRadius = 'var(--_1nizbzdb)';
export var ring = _7a468({defaultClassName:'_1nizbzdd',variantClassNames:{mouseOverWidget:{true:'_1nizbzde'},mouseOverSelectableRegion:{true:'_1nizbzdf'},selected:{true:'_1nizbzdg'},mode:{hugging:'_1nizbzdh',outside:'_1nizbzdi'},nodeType:{'void':'_1nizbzdj',textElement:'_1nizbzdk',layoutElement:'_1nizbzdl'},isInline:{true:'_1nizbzdm',false:'_1nizbzdn'}},defaultVariants:{},compoundVariants:[[{mouseOverSelectableRegion:true,nodeType:'textElement'},'_1nizbzdo'],[{selected:true,nodeType:'textElement'},'_1nizbzdp']]});
export var dragHandle = _7a468({defaultClassName:'_1nizbzds',variantClassNames:{alignment:{widget:'_1nizbzdt','selection-ring':'_1nizbzdu'},mode:{hugging:'_1nizbzdv',outside:'_1nizbzdw'},nodeType:{'void':'_1nizbzdx',textElement:'_1nizbzdy',layoutElement:'_1nizbzdz'}},defaultVariants:{},compoundVariants:[]});
"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentMethodsList = void 0;
var usePaymentMethods_1 = require("Library/PaymentsDashboard/PaymentSettings/usePaymentMethods");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var styles = __importStar(require("../Library/PaymentsDashboard/PaymentSettings/PaymentsSettings.css"));
var react_1 = __importStar(require("react"));
var dynamic_1 = require("@vanilla-extract/dynamic");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var tokens_1 = require("@qwilr/kaleidoscope/tokens");
var PaymentMethodsList = function (_a) {
    var _b;
    var gatewayId = _a.gatewayId;
    var _c = (0, usePaymentMethods_1.usePaymentMethods)({ gatewayId: gatewayId }), error = _c.error, isLoading = _c.isLoading, paymentMethods = _c.paymentMethods;
    var _d = __read((0, react_1.useState)(false), 2), expanded = _d[0], setExpanded = _d[1];
    return (react_1.default.createElement(kaleidoscope_1.Stack, null,
        react_1.default.createElement(kaleidoscope_1.ButtonCard, { "aria-expanded": expanded, onClick: function () { return setExpanded(!expanded); }, "aria-controls": "payment-gateway-settings", className: styles.expandableGatewaySection },
            react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", justify: "spaceBetween", padding: "m", className: styles.fullWidth },
                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: "s", align: "center", className: styles.fullWidth },
                    react_1.default.createElement("div", { className: styles.paymentMethodsIconStyle },
                        react_1.default.createElement(icons_1.WidgetQuoteSummary, { color: "inherit", style: (0, dynamic_1.assignInlineVars)((_b = {},
                                _b[kaleidoscope_1.iconVars.color] = tokens_1.palette.green600,
                                _b)) })),
                    react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", justify: "spaceBetween", className: styles.fullWidth },
                        react_1.default.createElement(kaleidoscope_1.Stack, null,
                            react_1.default.createElement(kaleidoscope_1.Text, { size: "m", strong: true }, "Payment Methods"),
                            react_1.default.createElement(kaleidoscope_1.Text, { secondary: true, size: "xs" }, "Configured payment methods. Managed by Stripe")),
                        expanded ? react_1.default.createElement(icons_1.ChevronDown, null) : react_1.default.createElement(icons_1.ChevronRight, null))))),
        react_1.default.createElement(kaleidoscope_1.Expandable, { element: kaleidoscope_1.Stack, expanded: expanded, timeout: kaleidoscope_1.AnimationDuration.Long }, function (_a) {
            var expandableElementRef = _a.expandableElementRef;
            return (react_1.default.createElement(kaleidoscope_1.Stack, { padding: "l", direction: "vertical", justify: "center", ref: expandableElementRef },
                error && (react_1.default.createElement(kaleidoscope_1.Alert, { heading: "Stripe Error", alertType: kaleidoscope_1.AlertType.Error }, "We were unable to fetch your configured payment methods from Stripe. Go to the Stripe Dashboard to manage your payment methods")),
                !isLoading && paymentMethods && (react_1.default.createElement(kaleidoscope_1.Stack, { paddingLeft: "s", paddingRight: "s", gap: "m" },
                    react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsRow({ hasDivider: true }), justify: "spaceBetween", direction: "horizontal", align: "center" },
                        react_1.default.createElement(kaleidoscope_1.Text, { size: "s" }, "Credit Card"),
                        react_1.default.createElement(kaleidoscope_1.Label, { size: "s", disabled: !paymentMethods["card"] }, paymentMethods["card"] ? "Enabled" : "Disabled")),
                    react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsRow({ hasDivider: true }), justify: "spaceBetween", direction: "horizontal", align: "center" },
                        react_1.default.createElement(kaleidoscope_1.Text, { size: "s" }, "Cartes Bancaires"),
                        react_1.default.createElement(kaleidoscope_1.Label, { size: "s", disabled: !paymentMethods["cartes_bancaires"] }, paymentMethods["cartes_bancaires"] ? "Enabled" : "Disabled")),
                    react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsRow({ hasDivider: true }), justify: "spaceBetween", direction: "horizontal", align: "center" },
                        react_1.default.createElement(kaleidoscope_1.Text, { size: "s" }, "JCB"),
                        react_1.default.createElement(kaleidoscope_1.Label, { size: "s", disabled: !paymentMethods["jcb"] }, paymentMethods["jcb"] ? "Enabled" : "Disabled")),
                    react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsRow({ hasDivider: true }), justify: "spaceBetween", direction: "horizontal", align: "center" },
                        react_1.default.createElement(kaleidoscope_1.Text, { size: "s" }, "Apple Pay"),
                        react_1.default.createElement(kaleidoscope_1.Label, { size: "s", disabled: !paymentMethods["apple_pay"] }, paymentMethods["apple_pay"] ? "Enabled" : "Disabled")),
                    react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsRow({ hasDivider: true }), justify: "spaceBetween", direction: "horizontal", align: "center" },
                        react_1.default.createElement(kaleidoscope_1.Text, { size: "s" }, "Google Pay"),
                        react_1.default.createElement(kaleidoscope_1.Label, { size: "s", disabled: !paymentMethods["google_pay"] }, paymentMethods["google_pay"] ? "Enabled" : "Disabled")),
                    react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsRow({ hasDivider: true }), justify: "spaceBetween", direction: "horizontal", align: "center" },
                        react_1.default.createElement(kaleidoscope_1.Text, { size: "s" }, "Link"),
                        react_1.default.createElement(kaleidoscope_1.Label, { size: "s", disabled: !paymentMethods["link"] }, paymentMethods["link"] ? "Enabled" : "Disabled")),
                    react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsRow({ hasDivider: true }), justify: "spaceBetween", direction: "horizontal", align: "center" },
                        react_1.default.createElement(kaleidoscope_1.Text, { size: "s" }, "SEPA Direct Debit"),
                        react_1.default.createElement(kaleidoscope_1.Label, { size: "s", disabled: !paymentMethods["sepa_debit"] }, paymentMethods["sepa_debit"] ? "Enabled" : "Disabled")),
                    react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsRow({ hasDivider: true }), justify: "spaceBetween", direction: "horizontal", align: "center" },
                        react_1.default.createElement(kaleidoscope_1.Text, { size: "s" }, "ACH Direct Debit"),
                        react_1.default.createElement(kaleidoscope_1.Label, { size: "s", disabled: !paymentMethods["us_bank_account"] }, paymentMethods["us_bank_account"] ? "Enabled" : "Disabled")),
                    react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.settingsRow({ hasDivider: true }), justify: "spaceBetween", direction: "horizontal", align: "center" },
                        react_1.default.createElement(kaleidoscope_1.Text, { size: "s" }, "BECS Direct Debit (AU)"),
                        react_1.default.createElement(kaleidoscope_1.Label, { size: "s", disabled: !paymentMethods["au_becs_debit"] }, paymentMethods["au_becs_debit"] ? "Enabled" : "Disabled")))),
                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: "m", align: "center", justify: "spaceBetween", className: styles.manageInStripe },
                    react_1.default.createElement(kaleidoscope_1.Text, null, "If you want to manage your payment methods, you can edit them in the Stripe dashboard."),
                    react_1.default.createElement(kaleidoscope_1.Button, { type: kaleidoscope_1.ButtonType.Secondary, href: function () { return "https://dashboard.stripe.com/settings/payment_methods"; } }, "Edit in Stripe"))));
        })));
};
exports.PaymentMethodsList = PaymentMethodsList;

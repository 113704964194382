"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutomationsGrid = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var styles = __importStar(require("./AutomationsGrid.css"));
var AutomationUtils_1 = require("Library/AutomationLibrary/AutomationUtils");
var inversify_react_1 = require("inversify-react");
var NotificationBanner_1 = require("Library/AutomationLibrary/NotificationBanner");
var FILTER_BY_INTEGRATION_ALL_OPTION = {
    displayName: "All",
    internalName: "all",
    icon: "listView",
    count: 0,
};
function AutomationsGrid(_a) {
    var automations = _a.automations, renderAutomation = _a.renderAutomation, automationsMetadata = _a.automationsMetadata;
    if (automations == undefined) {
        return (react_1.default.createElement("div", { className: styles.gridContainer },
            react_1.default.createElement(kaleidoscope_1.Spinner, { className: styles.spinner, primary: true })));
    }
    var getAutomationCount = function (automations) {
        var e_1, _a;
        var _b;
        var automationCount = {};
        try {
            for (var automations_1 = __values(automations), automations_1_1 = automations_1.next(); !automations_1_1.done; automations_1_1 = automations_1.next()) {
                var automation = automations_1_1.value;
                if ("integration" in automation && typeof automation.integration === "string") {
                    automationCount[automation.integration] = ((_b = automationCount[automation.integration]) !== null && _b !== void 0 ? _b : 0) + 1;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (automations_1_1 && !automations_1_1.done && (_a = automations_1.return)) _a.call(automations_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return automationCount;
    };
    var automationCount = getAutomationCount(automations);
    var filterByIntegrationOptions = __spreadArray([
        __assign(__assign({}, FILTER_BY_INTEGRATION_ALL_OPTION), { count: automations.length })
    ], __read(automationsMetadata
        .map(function (a) {
        var _a;
        return ({
            displayName: a.displayName,
            internalName: a.integrationName,
            icon: a.integrationName,
            count: (_a = automationCount[a.integrationName]) !== null && _a !== void 0 ? _a : 0,
        });
    })
        .filter(function (a) { return a.count > 0; })), false).sort(function (a, b) { return a.internalName.toLowerCase().localeCompare(b.internalName.toLowerCase()); });
    var accountDataStore = (0, inversify_react_1.useInjection)("rootDataStore").getAccountDataStore();
    var _b = __read((0, react_1.useState)(FILTER_BY_INTEGRATION_ALL_OPTION), 2), filterByIntegration = _b[0], setFilterByIntegration = _b[1];
    var enabledIntegrationsList = (0, AutomationUtils_1.getEnabledIntegrations)(automationsMetadata);
    var isIntegrationEnabled = enabledIntegrationsList.includes(filterByIntegration.internalName);
    var showUpgradeNotificationBanner = !isIntegrationEnabled && (0, AutomationUtils_1.isPlanUpgradeRequired)(accountDataStore, filterByIntegration.internalName);
    var filteredAutomations = automations
        .map(function (a) {
        if (filterByIntegration.internalName === "all") {
            return __assign(__assign({}, a), { visible: true });
        }
        if ("integration" in a && a.integration === filterByIntegration.internalName) {
            return __assign(__assign({}, a), { visible: true });
        }
        return __assign(__assign({}, a), { visible: false });
    })
        .sort(function (a, b) { return (b.visible ? 1 : 0) - (a.visible ? 1 : 0); });
    return (react_1.default.createElement("div", { className: styles.gridContainer },
        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: "xxl" },
            react_1.default.createElement("div", { className: styles.leftPanel },
                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "vertical", gap: "l" },
                    react_1.default.createElement("div", { className: styles.filterByIntegration }, filterByIntegrationOptions.map(function (o) {
                        return (react_1.default.createElement(kaleidoscope_1.MenuItem, { onClick: function () {
                                setFilterByIntegration(o);
                            }, active: filterByIntegration.displayName === o.displayName },
                            react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", justify: "spaceBetween" },
                                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: "xs" },
                                    (0, AutomationUtils_1.toIcon)(o.icon),
                                    react_1.default.createElement(kaleidoscope_1.Text, null, o.displayName)),
                                react_1.default.createElement(kaleidoscope_1.Stack, { align: "center", justify: "center" },
                                    react_1.default.createElement(kaleidoscope_1.Text, { strong: true, lineHeight: "relaxed", size: "s", className: styles.automationCount }, o.count)))));
                    })),
                    react_1.default.createElement(kaleidoscope_1.Separator, null),
                    react_1.default.createElement("div", null,
                        react_1.default.createElement(kaleidoscope_1.Text, null, "Didn't find the app you're looking for?"),
                        " ",
                        react_1.default.createElement(kaleidoscope_1.Link, { className: styles.letUsKnow, href: "mailto:help@qwilr.com" }, "Let us know")))),
            react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.rightPanel, direction: "vertical", gap: "l" },
                showUpgradeNotificationBanner && (react_1.default.createElement(NotificationBanner_1.NotificationBanner, { notificationReason: NotificationBanner_1.NotificationReason.EnterpriseOnly, notificationOrigin: NotificationBanner_1.NotificationOrigin.RecommendedAutomations })),
                react_1.default.createElement("div", { className: styles.grid }, filteredAutomations.map(function (a, i) { return (react_1.default.createElement("div", { className: styles.automationCard({ status: a.visible ? "show" : "hide" }) }, renderAutomation(a, i))); }))))));
}
exports.AutomationsGrid = AutomationsGrid;
